import classNames from 'classnames';
import { Icon, IconType } from './icon';

const variantStyles = {
  todo: {
    selected: 'text-violet-800 bg-violet-100',
    unselected: 'hover:text-violet-800 hover:bg-violet-100',
  },
  inProgress: {
    selected: 'text-orange-800 bg-orange-100',
    unselected: 'hover:text-orange-800 hover:bg-orange-100',
  },
  ordered: {
    selected: 'text-sky-800 bg-sky-100',
    unselected: 'hover:text-sky-800 hover:bg-sky-100',
  },
  done: {
    selected: 'text-green-800 bg-green-100',
    unselected: 'hover:text-green-800 hover:bg-green-100',
  },
} as const;

export type ChipVariant = keyof typeof variantStyles;

export type ChipProps = {
  label: string;
  leftIcon?: IconType;
  onClick?: () => void;
  rightIcon?: IconType;
  selected?: boolean;
  variant: ChipVariant;
};

export const Chip = ({ label, variant, leftIcon, rightIcon, onClick, selected }: ChipProps): JSX.Element => {
  const variantStyle = variantStyles[variant];

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions -- fixed but not seen by eslint because of dynamic role
    <span
      className={classNames(
        onClick == null || selected
          ? classNames(variantStyle.selected, 'border-transparent')
          : classNames(variantStyle.unselected, 'border border-gray-300', 'hover:border-transparent'),
        'inline-flex h-7 items-center whitespace-nowrap rounded-full border px-3 py-0.5 text-xs font-medium',
        { 'cursor-pointer': onClick != null },
      )}
      onClick={onClick}
      role={onClick != null ? 'button' : undefined}
      tabIndex={-1}
    >
      {leftIcon ? (
        <div className="pr-2">
          <Icon icon={leftIcon} />
        </div>
      ) : null}
      {label}
      {rightIcon ? (
        <div className="pl-2">
          <Icon icon={rightIcon} />
        </div>
      ) : null}
    </span>
  );
};
