import { Icon, IconProps, IconType, Select } from '@features/ui/components';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import _debounce from 'lodash/debounce';
import { forwardRef, ReactNode, useEffect, useMemo } from 'react';
import { SelectProps, ValueType } from './Select';

export type InputAddOn = string | InputIconProps;
export type InputIconProps = Pick<IconProps, 'icon' | 'onClick' | 'className'>;

export type InputProps = {
  /**
   * Only text is displayed when a string is set.
   * An icon is shown when its props are provided.
   */
  addOnLeft?: InputAddOn;
  /**
   * @see addOnLeft
   */
  addOnRight?: InputAddOn;
  /**
   * Set the debounce time for this input.
   * Has no effect without onDebounce function.
   */
  debounceTime?: number;
  disabled?: boolean;
  error?: boolean;
  iconLeft?: IconType | InputIconProps;
  iconRight?: IconType | InputIconProps;
  placeholder?: string;
  readonly?: boolean;
  selectLeft?: {
    buttonLabel: ReactNode;
  } & Pick<SelectProps<ValueType>, 'value' | 'onChange' | 'options'>;
  defaultValue?: string;
  value?: string;
  /**
   * Called only after `debounceTime` ms when there is no more user input
   */
  onDebounce?(value: string): void;
} & Pick<
  JSX.IntrinsicElements['input'],
  | 'autoFocus'
  | 'name'
  | 'required'
  | 'onChange'
  | 'onBlur'
  | 'pattern'
  | 'className'
  | 'onFocus'
  | 'type'
  | 'min'
  | 'max'
  | 'maxLength'
  | 'onKeyDown'
>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      addOnLeft,
      addOnRight,
      debounceTime,
      disabled,
      error,
      iconLeft,
      iconRight,
      onDebounce,
      selectLeft,
      min,
      max,
      ...props
    },
    ref,
  ) => {
    const style = styleDefs[error ? 'error' : 'default'];

    const iconLeftProps = getIconProps(iconLeft);
    const iconRightProps = getIconProps(iconRight);

    const { defaultValue } = props;
    const { value } = props;

    const getAddOn = (addOn: InputAddOn) => (typeof addOn === 'string' ? addOn : <Icon {...addOn} />);

    // Not a useCallback as `debounce` can be falsy
    const debounce = useMemo(
      () => (debounceTime || 0) > 0 && onDebounce && _debounce(onDebounce, debounceTime),
      [debounceTime, onDebounce],
    );

    useEffect(
      () => () => {
        // Cancels debounce on each change
        if (debounce) {
          debounce.cancel();
        }
      },
      [debounce],
    );

    return (
      <div className={classNames('flex', props.className)}>
        {addOnLeft && <span className={classNames(style.addOn, 'rounded-l-md border-r-0')}>{getAddOn(addOnLeft)}</span>}
        {selectLeft && (
          <Select
            options={selectLeft.options}
            value={selectLeft.value}
            onChange={selectLeft.onChange}
            customButton={
              <div className={classNames(style.addOn, 'h-full w-full rounded-l-md border-r-0')}>
                {selectLeft.buttonLabel} <Icon icon="chevronDown" className="h-3 w-3" />
              </div>
            }
            disabled={disabled}
          />
        )}
        <div className="relative flex w-full">
          {iconLeftProps && (
            <div
              className={classNames('pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3', style.icon)}
            >
              {}
              <Icon {...iconLeftProps} />
            </div>
          )}
          <input
            id={props.name}
            type={type}
            name={props.name}
            className={classNames(
              'block w-full placeholder:text-gray-400 focus:ring-0 sm:text-sm',
              'focus:outline-primary focus:border-transparent focus:outline focus:outline-2 focus:outline-offset-0',
              'disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500',
              {
                'hover:border-primary': !disabled,
                'pl-10': iconLeft,
                'pr-10': iconRight || error,
                'rounded-l-md': !addOnLeft && !selectLeft,
                'rounded-r-md': !addOnRight,
              },
              style.input,
            )}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
            aria-invalid="true"
            aria-describedby="email-error"
            max={max}
            min={min}
            onChange={event => {
              const eventOverride = cloneDeep(event);

              if (props.onChange) {
                props.onChange(eventOverride);
              }

              if (debounce) {
                debounce(eventOverride.target.value);
              }
            }}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            pattern={props.pattern}
            readOnly={props.readonly}
            required={props.required}
            value={value}
            maxLength={props.maxLength}
            ref={ref}
            onKeyDown={props.onKeyDown}
          />
          {error && !iconRight && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-red-400">
              <Icon icon="exclamationCircle" />
            </div>
          )}
          {iconRightProps && (
            <div
              className={classNames(
                'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
                disabled ? style.iconDisabled : style.icon,
              )}
            >
              {}
              <Icon {...iconRightProps} />
            </div>
          )}
        </div>

        {addOnRight && (
          <span className={classNames(style.addOn, 'rounded-r-md border-l-0')}>
            {}
            {typeof addOnRight === 'string' ? addOnRight : <Icon {...addOnRight} />}
          </span>
        )}
      </div>
    );
  },
);

type StyleDef = {
  addOn: string;
  icon: string;
  iconDisabled: string;
  input: string;
};

const styleDefs: Record<'default' | 'error', StyleDef> = {
  default: {
    addOn: 'inline-flex items-center border border-gray-200 bg-gray-50 text-gray-400 px-3 sm:text-sm',
    icon: 'text-gray-400',
    iconDisabled: '',
    input: 'text-gray-800 border-gray-200 border-1',
  },
  error: {
    addOn: 'inline-flex items-center border border-red-400 bg-red-400 px-3 text-gray-800 sm:text-sm',
    icon: 'text-red-400',
    iconDisabled: '',
    input: 'text-red-400 border-red-400 outline-red-400 border-1',
  },
};

/**
 * @return the final icon props from the input icon props
 */
function getIconProps(icon: InputProps['iconLeft']) {
  return icon && (typeof icon === 'string' ? { icon } : icon);
}
