/* eslint-disable react-hooks/rules-of-hooks */
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { ContactDto } from '@ch-apptitude-icc/lablink/shared/entities';
import { APIDetailResponse, ApiFactory, UseMutationOptions } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient, useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

class FrontDummyEntity implements Entity<never> {
  id!: never;
}

const api = clients.contact();

export class ContactApi extends ApiFactory<FrontDummyEntity, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }

  public useContact(
    options?: UseMutationOptions<APIDetailResponse<void>>,
  ): UseMutationResult<AxiosResponse<void>, AxiosError, ContactDto> {
    return useMutation({
      mutationFn: dto => this.api.sendContactEmail(dto),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: () => options?.onSuccess && options.onSuccess(undefined, async () => {}),
      retry: false,
    });
  }
}
