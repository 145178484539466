export enum OrderBilledTo {
  INSURANCE = 'INSURANCE',
  OTHER_BILLING = 'OTHER_BILLING',
  PATIENT = 'PATIENT',

  /**
   * Billed to the doctor who issued the order
   */
  DOCTOR = 'DOCTOR',
}
