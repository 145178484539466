import { maxDate, registerDecorator, ValidationOptions } from 'class-validator';
import { isFunction } from 'lodash';
import { ValidatorType } from './validators';

// eslint-disable-next-line @typescript-eslint/naming-convention
const _MaxDate =
  // eslint-disable-next-line @typescript-eslint/ban-types
  (date: Date | (() => Date), validationOptions?: ValidationOptions) => (obj: Object, propName: string) =>
    registerDecorator({
      name: 'MaxDate',
      target: obj.constructor,
      propertyName: propName,
      constraints: [],
      options: {
        message: () => `${propName}:MaxDate;${(isFunction(date) ? date() : date).toISOString()}`,
        ...validationOptions,
      },
      validator: {
        validate(value: Date | string): boolean {
          const validateAgainst = isFunction(date) ? date() : date;
          const validateValue: Date = typeof value === 'string' ? new Date(value) : value;

          return maxDate(validateValue, validateAgainst);
        },
      },
    });

export const MaxDate = _MaxDate as ValidatorType<typeof _MaxDate>;

Object.defineProperty(MaxDate, 'validatorName', { value: 'MaxDate' });
