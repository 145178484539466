import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

interface SkeletonProps {
  width?: string;
  height?: string;
  className?: string;
}

export const Skeleton = ({ width, height, className }: SkeletonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'animate-pulse rounded-full bg-gray-300 text-white/0',
        width ?? 'w-full',
        height ?? 'h-auto',
        className,
      )}
    >
      {t('component.button.loading')}
    </div>
  );
};
