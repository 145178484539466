import { Gender } from '@ch-apptitude-icc/common/shared/entities';
import { CreateOrderStepPatient } from '@features/orders/types/CreateOrderStepPatient';
import { LineCard, Icon } from '@features/ui/components';
import { useFormatters } from '@services/formatters';
import { useTranslation } from 'next-i18next';

type PatientLineCardProps = {
  className?: string;
  onRemove?: () => void;
  patient: CreateOrderStepPatient['patient'];
};

export function PatientLineCard({ patient, onRemove, className }: PatientLineCardProps): JSX.Element {
  const { t } = useTranslation('common');
  const { fDate } = useFormatters();

  if (patient?.anonymous) {
    return <LineCard main={t('pages.patients.newPatientForm.anonymous')} onRemove={onRemove} className={className} />;
  }

  return (
    <LineCard
      left={
        <Icon
          // eslint-disable-next-line no-nested-ternary
          icon={patient?.gender === Gender.FEMALE ? 'venus' : patient?.gender === Gender.MALE ? 'mars' : 'marsAndVenus'}
        />
      }
      main={
        <>
          {/* eslint-disable-next-line no-nested-ternary */}
          {patient?.gender === Gender.FEMALE
            ? t('common.title.madam')
            : patient?.gender === Gender.MALE
            ? t('common.title.sir')
            : ''}{' '}
          <span className="uppercase">{patient?.lastname ?? ''}</span> {patient?.firstname ?? ''}
        </>
      }
      right={patient?.birthDate && fDate(new Date(patient.birthDate))}
      onRemove={onRemove}
      className={className}
    />
  );
}
