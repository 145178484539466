import { APIClient, ClientOptions, APIClientInternals } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from '@ch-apptitude-icc/common/shared/type-utils';
import { Reference } from '@ch-apptitude-icc/lablink/shared/entities';

export type ReportUrl = { url: string };

export interface ReferencesClient<EntityType extends Entity<unknown>>
  extends APIClient<EntityType, 'findOne' | 'findAll'> {
  /**
   * Given a reference ID, return a link to the backend on which an automatic report redirection can be issued.
   *
   * @return the URL at which the user can get the report
   */
  getReportRedirectUrl(referenceId: number): string;
}

class ReferencesClientImpl<EntityType extends Entity<unknown>>
  extends APIClientInternals.BaseClient<EntityType>
  implements ReferencesClient<EntityType>
{
  constructor({ entityType, resourcePath, options }: APIClientInternals.APIClientSchemaBasedExtensions<EntityType>) {
    super(resourcePath, entityType, options);
  }

  getReportRedirectUrl(referenceId: number): string {
    return `${this.options?.baseURL ?? ''}/${this.resourcePath}/${referenceId}/report.pdf`;
  }
}

const baseReferencesClient = APIClientInternals.readOnlyClient(Reference, 'references');

export const referencesClient =
  <RealEntityType extends Entity<unknown> = Reference>(overrideEntity?: Type<RealEntityType>) =>
  (optionsIn?: ClientOptions<RealEntityType>): ReferencesClient<RealEntityType> =>
    new ReferencesClientImpl<RealEntityType>(baseReferencesClient(overrideEntity)(optionsIn));
