export interface NumberFormatterOptions
  extends Pick<
    Intl.NumberFormatOptions,
    | 'maximumFractionDigits'
    | 'maximumSignificantDigits'
    | 'minimumFractionDigits'
    | 'minimumIntegerDigits'
    | 'minimumSignificantDigits'
  > {
  /**
   * Set the number of digits of the number.
   * It overrides max/min FractionDigits.
   */
  fractionDigits?: number;
}

export interface NumberFormatter {
  /**
   * Format the given number to a specified format.
   */
  (number: number, options?: NumberFormatterOptions): string;

  /**
   * The locale used to format the number
   */
  locale: string;
}

export interface NumberCurrencyFormatter extends Pick<NumberFormatter, 'locale'> {
  /**
   * Format the given number to a specified currency format.
   */
  (number: number): string;
}

export function createNumberFormatter(locale: string): NumberFormatter {
  const fn: NumberFormatter = (number, options) => numberFormat(fn.locale, number, options);
  fn.locale = locale;

  return fn;
}

export function createNumberCurrencyFormatter(locale: string): NumberCurrencyFormatter {
  const fn: NumberCurrencyFormatter = number => numberFormat(fn.locale, number, { fractionDigits: 2 });
  fn.locale = locale;

  return fn;
}

function numberFormat(
  locale: string,
  number: number,
  { fractionDigits, maximumFractionDigits, minimumFractionDigits, ...options }: NumberFormatterOptions = {},
) {
  if (fractionDigits !== undefined && fractionDigits >= 0) {
    // eslint-disable-next-line no-multi-assign,no-param-reassign
    maximumFractionDigits = minimumFractionDigits = fractionDigits;
  }

  return number.toLocaleString(locale, { maximumFractionDigits, minimumFractionDigits, ...options });
}
