export const users = {
  /**
   * Custom validation errors returned as part of validation in the user controller
   */
  validation: {
    /**
     * Returned when the current password is wrong in a password change request
     */
    incorrectPassword: 'IncorrectPassword',
  },
} as const;
