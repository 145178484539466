import { ValueTransformer } from 'typeorm';

/**
 * A datetime transformer that sees the date as a _string_ and removes any time information from it
 */
export const truncateTimeTransformer: ValueTransformer = {
  from(value?: string | Date | null): string | null {
    if (typeof value === 'string') {
      return value.split('T')[0];
    }

    if (value != null) {
      // Cancel the TypeORM timezone offsetting
      value.setMinutes(value.getMinutes() - value.getTimezoneOffset());
      return value.toISOString().split('T')[0];
    }

    return null;
  },

  to(value?: string | null): null | string {
    if (value?.startsWith('%') && value?.endsWith('%')) {
      return value;
    }

    return value ? value.split('T')[0] : null;
  },
};
