import {
  AnalysisDisplayTab,
  AnalysisInterface,
  AnalysisType,
  WebsiteFields,
} from '@ch-apptitude-icc/common/shared/entities';
import {
  IsBoolean,
  IsEnum,
  IsIn,
  IsInt,
  IsOptional,
  IsPositive,
  Max,
  MaxLength,
} from '@ch-apptitude-icc/common/shared/validation';
import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { CatalogueAnalysis } from './catalogue-analysis';
import { AnalysisSector } from './enums/analysis-sector.enum';
import { Pricing } from './pricing';
import { SamplingMaterial } from './sampling-material';

export class Analysis implements AnalysisInterface, WebsiteFields {
  @PrimaryGeneratedColumn({ name: 'id', type: 'int' })
  @IsInt()
  @IsPositive()
  @Max(2 ** 30)
  id!: number;

  /** Disabled analyses are not listed by default */
  @Column({ default: false, name: 'disabled', nullable: false })
  @IsBoolean()
  @IsOptional()
  disabled?: boolean;

  @Column('varchar', { length: 10, name: 'key' })
  @MaxLength(10)
  key!: string;

  @Column({ type: 'smallint' })
  @IsEnum(AnalysisType)
  analysisType!: AnalysisType;

  @Column('varchar', { length: 100, name: 'name' })
  @MaxLength(100)
  name!: string;

  @Column('varchar', { length: 100, name: 'sector' })
  @MaxLength(100)
  sector!: AnalysisSector;

  @Column('varchar', { length: 100, name: 'department' })
  @MaxLength(100)
  department!: string;

  readonly samplingMaterial?: SamplingMaterial | null;

  samplingMaterialId?: number | null;

  /**
   * @deprecated
   */
  @Column('int', { name: 'lineOrder', nullable: true })
  lineOrder?: number;

  /**
   * All the available pricings for this analysis, if the analysis was returned as a response to get the entire list
   * of analyses.
   */
  pricings?: Pricing[];

  /**
   * The pricing, if the analysis was returned as part of a catalogue with pricings
   */
  pricing?: Pricing | null;

  /**
   * The name of the analysis displayed to users in the LabLink
   */
  @Column('varchar', { name: 'publicName', nullable: true, length: 80 }) publicName!: string | null;

  /**
   * The sub-group for this analysis in the lablink, stored directly as text
   */
  @Column('varchar', { name: 'publicSubCategory', nullable: true, length: 80 }) publicSubCategory!: string | null;

  /**
   * The lablink tab in which this analysis should be displayed, if the lablink display is tabbed
   */
  @IsEnum(AnalysisDisplayTab)
  @IsIn([AnalysisDisplayTab.TOXICOLOGY, AnalysisDisplayTab.MICROBIOLOGY, AnalysisDisplayTab.MEDICAL])
  @Column({ type: 'enum', name: 'displayTab', nullable: true, enum: AnalysisDisplayTab })
  displayTab?: AnalysisDisplayTab | null;

  @Column('boolean', { name: 'displayInVadeMecum', default: false }) displayInVadeMecum!: boolean;

  catalogues?: CatalogueAnalysis[];
}
