import { Dropdown, Icon } from '@features/ui/components';
import { Skeleton } from '@features/ui/components/Skeleton';
import { useApi } from '@services/api';
import { getRoute } from '@services/routes';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const HeaderUser = (): JSX.Element => {
  const { authApi } = useApi();
  const { t } = useTranslation();
  const router = useRouter();

  const logout = authApi.useLogout();
  const me = authApi.useGetMe({
    onError: (error, next) => {
      if (error.response?.status === 401) {
        next();
      }
    },
  });

  return (
    <div className="mt-5 flex h-full cursor-pointer items-center justify-center md:mt-0 md:justify-start">
      <Dropdown
        items={[
          {
            label: t('header.user.settings'),
            onClick() {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              router.push(getRoute.settings().pathname);
            },
          },
          {
            className: 'text-red-600',
            label: t('header.user.logout'),
            onClick() {
              logout.mutate(undefined);
            },
          },
        ]}
        label={
          <div className="flex flex-row items-center gap-2">
            {me.data ? <span className="hover:text-cyan-700">{me.data?.name}</span> : <Skeleton width="w-32" />}

            <div className="bg-primary flex h-8 w-8 items-center justify-center rounded-full hover:bg-cyan-700">
              <Icon icon="userDoctor" />
            </div>
          </div>
        }
      />
    </div>
  );
};
