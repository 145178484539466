import { DotPath } from '@ch-apptitude-icc/common/shared/type-utils';
import { DeepPartial } from 'typeorm';
import { IsDefined } from '../validators';

export type RequirementValidator<T> = {
  [key in keyof T]?: RequirementValidator<T[key]> | boolean;
};

export function requireFieldsAsValidationError<T extends object>(
  requirements: RequirementValidator<T>,
  object: DeepPartial<T>,
): string[] {
  return requireFields(requirements, object).map(field => `${field}:${IsDefined.validatorName}`);
}

export function requireFields<T extends object>(
  requirements: RequirementValidator<T>,
  object: DeepPartial<T>,
): Array<DotPath<T>> {
  const paths: Array<DotPath<T>> = [];
  for (const [key, req] of Object.entries(requirements) as Array<[DotPath<T>, RequirementValidator<T[keyof T]>]>) {
    if (req === false) {
      // Nothing to do, not a required field
      continue;
    }

    if (object && key in object) {
      const objValue = object[key as keyof DeepPartial<T>];

      if (req === true) {
        if (!objValue) {
          // TODO ID-392: other than falsy (callback as a param of this function as it could be reused for other validation)?
          // Probably present but falsy
          paths.push(key);
        }
      } else {
        paths.push(
          ...requireFields<RequirementValidator<T[keyof T]>>(req, objValue).map(path => `${key}.${path}` as DotPath<T>),
        );
      }
    } else {
      // not present
      paths.push(key);
    }
  }

  return paths;
}
