import { booleanTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from 'class-transformer';
import { ViewColumn, Column } from 'typeorm';
import { OrderStatus, ReportStatus } from './enums';
import { OrderPositiveResult } from './enums/order-positive-result';

/**
 * Data of the patient in an order or reference
 */
export class OrderOrReferencePatientFields {
  /**
   * The ID of the patient.
   *
   * Will always be present when the row is a reference, but will be null for an order without corresponding reference
   * in which the `patient.existingPatientId` field is left null.
   */
  @ViewColumn()
  id!: number | null;

  /**
   * The first name of the patient.
   *
   * If an order is defined and if the order defines this field, then the value comes from the order. Otherwise, it
   * comes from the patient. Since a patient may not always be present (see remark for `id` field), this field may be
   * null.
   */
  @ViewColumn()
  firstName!: string | null;

  /**
   * The last name of the patient.
   *
   * If an order is defined and if the order defines this field, then the value comes from the order. Otherwise, it
   * comes from the patient. Since a patient may not always be present (see remark for `id` field), this field may be
   * null.
   */
  @ViewColumn()
  lastName!: string | null;

  /**
   * The birthdate of the patient.
   *
   * If an order is defined and if the order defines this field, then the value comes from the order. Otherwise, it
   * comes from the patient. Since a patient may not always be present (see remark for `id` field), this field may be
   * null.
   */
  @ViewColumn()
  birthDate!: Date | null;
}

/**
 * This class represents either an order (order passed on the site), a reference (a link to a file in AnalyseMedicale),
 * or both (in the case where an order has been sent via LabLink and received in AnalyseMedicale.
 *
 * This class should not be used as an entity directly. It is implemented via two views in the backend
 */
export class OrderOrReference {
  /**
   * The ID of the row. Format: `ref-<referenceId>` if no order exists, `<orderId>` otherwise
   */
  @ViewColumn()
  id!: string;

  /**
   * The ID of the order. Can be null if there is no corresponding order.
   */
  @ViewColumn()
  orderId!: number | null;

  /**
   * The personal reference the user set on their order (VRef). Can be null if there is no corresponding order.
   */
  @ViewColumn()
  orderUserReference?: string | null;

  /**
   * The ID of the reference in the database. The reference is the database entity responsibly for linking the order to
   * the ICC file.
   *
   * Can be null if the order has not been received by the lab.
   */
  @ViewColumn()
  referenceId!: number | null;

  /**
   * The ID of the file ("dossier"). Can be null if the order has not been received by the lab
   */
  @ViewColumn()
  iccFileId!: number | null;

  /**
   * The status of the order, if an order is present.
   *
   * If no order is present, the order status will be `OrderStatus.DONE`, except if the `reportStatus` is set to
   * `ReportStatus.PRELIMINARY_REPORT`, in which case it will be `OrderStatus.IN_PROGRESS`.
   */
  @ViewColumn()
  status!: OrderStatus;

  /**
   * Wether or not the order was marked as urgent.
   *
   * Will be false if there is no corresponding order.
   */
  @ViewColumn({ transformer: booleanTransformer })
  urgent!: boolean;

  /**
   * The status of the report/reference.
   *
   * Can be null if the order has not been received by the lab.
   */
  @ViewColumn()
  reportStatus!: ReportStatus | null;

  /**
   * The doctor that made this request. This should be equal to the current logged in doctor.
   */
  @ViewColumn()
  doctorId!: number;

  @Column(() => OrderOrReferencePatientFields)
  patient!: OrderOrReferencePatientFields;

  /**
   * The date at which the order or reference was created.
   *
   * If an order is present, this is equal to `Order.orderDate`. If not, it is equal to `Reference.receptionDate`.
   */
  @ViewColumn()
  @Type(() => Date)
  creationDate!: Date | null;

  /**
   * The date at which the report was last changed.
   *
   * Available only if a reference is present. It may still be null, as some references have a null reportDate.
   */
  @ViewColumn()
  @Type(() => Date)
  reportDate!: Date | null;

  /**
   * The date at which the sample was taken.
   *
   * If an order is present and `Order.sampleDate` is defined, it has that value. Otherwise, the value is `Reference.sampleDate`
   */
  @ViewColumn()
  @Type(() => Date)
  sampleDate!: Date | null;

  /**
   * The ordering department that was filled upon completion, if any.
   */
  @ViewColumn()
  orderingDepartment!: string | null;

  /**
   * If present, indicates the file name of the report
   */
  @ViewColumn()
  reportFilePath!: string | null;

  @ViewColumn()
  searchColumn!: string;

  @ViewColumn({ transformer: booleanTransformer })
  unread!: boolean;

  /**
   * The order has a positive result for an analysis
   */
  @ViewColumn()
  positiveResult!: OrderPositiveResult | null;

  @ViewColumn()
  materialId!: string | null;
}
