import { MaterialInterface } from '@ch-apptitude-icc/common/shared/entities';
import { IsOptional, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Column } from 'typeorm';

export class Material implements MaterialInterface {
  @Column('varchar', { length: 12, name: 'pkMateriel', primary: true })
  @MaxLength(12)
  id!: string;

  @Column('varchar', { length: 50, name: 'Materiel' })
  @MaxLength(50)
  @IsOptional()
  name!: string;
}
