import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

type ToggleVariant = 'primary';

type ToggleProps = {
  accessibilityLabel?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  value?: boolean;
  variant?: ToggleVariant;
};

export const Toggle = ({
  variant = 'primary',
  value,
  accessibilityLabel,
  disabled,
  onChange,
}: ToggleProps): JSX.Element => {
  const [enabled, setEnabled] = useState(value ?? false);

  const onChangeWrapper = useCallback(
    (check: boolean) => {
      if (onChange) {
        onChange(check);
      }
      setEnabled(check);
    },
    [onChange],
  );

  const isEnabled = value ?? enabled;

  const colorVariant = colorVariants[variant];

  return (
    <Switch
      disabled={disabled}
      checked={isEnabled}
      onChange={onChangeWrapper}
      className={classNames(
        isEnabled ? colorVariant.base : 'bg-gray-300',
        { [colorVariant.disabled]: disabled },
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
      )}
    >
      <span className="sr-only">{accessibilityLabel}</span>
      <span
        aria-hidden="true"
        className={classNames(
          isEnabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  );
};

type ColorVariant = {
  base: string;
  disabled: string;
};

const colorVariants: Record<ToggleVariant, ColorVariant> = {
  primary: {
    base: 'bg-primary',
    disabled: 'opacity-40',
  },
};
