import {
  IsEnum,
  IsNumber,
  IsString,
  IsOptional,
  IsArray,
  Allow,
  ValidateNested,
  IsIn,
  MaxLength,
  IsBoolean,
} from '@ch-apptitude-icc/common/shared/validation';
import { Expose, Type } from 'class-transformer';
import { Column } from 'typeorm';
import { AnalysisDisplayTab, AnalysisInterface, WebsiteFields } from '../analysis.interface';
import { AnalysisType } from '../enums';

class SyncAnalysisPricing {
  @IsString()
  @IsOptional()
  @Expose()
  code?: string;

  @IsNumber()
  @IsOptional()
  @Expose()
  points?: number | null;
}

export class SyncWebsiteFieldsDto implements WebsiteFields {
  @MaxLength(80)
  @IsOptional()
  @Expose()
  publicName!: string | null;

  @MaxLength(80)
  @IsOptional()
  @Expose()
  publicSubCategory!: string | null;

  @IsEnum(AnalysisDisplayTab)
  @IsOptional()
  @IsIn([AnalysisDisplayTab.TOXICOLOGY, AnalysisDisplayTab.MICROBIOLOGY, AnalysisDisplayTab.MEDICAL])
  @Expose()
  displayTab?: AnalysisDisplayTab | null;

  @Column('boolean', { name: 'displayInVadeMecum', default: false })
  @IsBoolean()
  @Expose()
  displayInVadeMecum!: boolean;
}

export class SyncAnalysisDto implements AnalysisInterface {
  @IsNumber()
  @Expose()
  id!: number;

  @IsString()
  @Expose()
  key!: string | null;

  @IsString()
  @Expose()
  name!: string | null;

  @IsString()
  @Expose()
  departmentCode!: string | null;

  @IsEnum(AnalysisType)
  @Expose()
  analysisType!: AnalysisType;

  @IsArray()
  @Expose()
  @Type(() => SyncAnalysisPricing)
  pricings!: SyncAnalysisPricing[];

  @Allow()
  @Expose()
  samplingMaterialId?: number | null;

  @Allow()
  @Expose()
  @ValidateNested()
  @Type(() => SyncWebsiteFieldsDto)
  website?: SyncWebsiteFieldsDto;
}
