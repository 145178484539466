import { AnalysisInterface, SamplingMaterialInterface } from '@ch-apptitude-icc/common/shared/entities';
import { groupBy } from 'lodash';

export function computeMaterialsListForOrder<
  MaterialType extends SamplingMaterialInterface = SamplingMaterialInterface,
>(
  analysesMap: Map<number, AnalysisInterface>,
  samplingMaterialsMap: Map<number, MaterialType>,
  requestedAnalysesIds: number[],
): MaterialsListForOrder<MaterialType> {
  const requestedMaterials = requestedAnalysesIds
    .map(oa => analysesMap.get(oa))
    .filter(a => a?.samplingMaterialId && samplingMaterialsMap.has(a.samplingMaterialId))
    .map(a => samplingMaterialsMap.get(a!.samplingMaterialId!)!)
    .flatMap(material =>
      // Resolve composite materials
      material.subMaterials && material.subMaterials.length
        ? material.subMaterials.filter(id => samplingMaterialsMap.has(id)).map(id => samplingMaterialsMap.get(id)!)
        : [material],
    );

  return Object.values(groupBy(requestedMaterials, mat => mat.name)).map<MaterialsListItem<MaterialType>>(
    ([head, ...rest]) => {
      // Compute the display value of the material
      if (head.maxAnalysesPerUnit) {
        // We have a maximum number of analyses per unit of material
        const materialsNeeded = Math.ceil((1 + rest.length) / head.maxAnalysesPerUnit);
        const unitsOfMaterialNeeded = materialsNeeded * (head.materialUnitQuantityIncrement || 1);

        return {
          material: head,
          amount: `${unitsOfMaterialNeeded} × ${head.materialUnitName ?? ''}`.trim(),
          numberUnits: materialsNeeded,
          numberLabels: materialsNeeded * (head.materialLabelsPerUnit ?? 1),
        };
      }

      if (!head.materialUnitName) {
        // We don't have a name for the material unit, so we at least display a small 'x' to indicate it
        return {
          material: head,
          amount: `${head.materialUnitQuantityIncrement ?? '1'} ×`.trim(),
          numberUnits: 1,
          numberLabels: head.materialLabelsPerUnit ?? 1,
        };
      }

      // We do have a name. If we have no quantity, we assume that the materialUnitName is all we need.
      return {
        material: head,
        amount: head.materialUnitQuantityIncrement
          ? `${head.materialUnitQuantityIncrement} ${head.materialUnitName}`.trim()
          : head.materialUnitName,
        numberUnits: 1,
        numberLabels: head.materialLabelsPerUnit ?? 1,
      };
    },
  );
}

type MaterialsListItem<MaterialType extends SamplingMaterialInterface> = {
  material: MaterialType;
  amount: string;
  numberUnits: number;
  numberLabels: number;
};

export type MaterialsListForOrder<MaterialType extends SamplingMaterialInterface> = Array<
  MaterialsListItem<MaterialType>
>;
