import { Gender } from '@ch-apptitude-icc/common/shared/entities';
import { Icon } from '@features/ui/components';
import { SearchBar } from '@features/ui/components/SearchBar';
import { PatientFront, useApi } from '@services/api';
import { useFormatters } from '@services/formatters';
import { DateTimeFormatter } from '@services/formatters/datetime';
import { useState } from 'react';

type PatientSearchBarProps = {
  onChange: (patient?: PatientFront) => void;
  value?: PatientFront;
  autoFocus?: boolean;
};

export const PatientSearchBar = ({ value, onChange, autoFocus = false }: PatientSearchBarProps): JSX.Element => {
  const { fDate } = useFormatters();
  const [query, setQuery] = useState('');

  const { patientApi } = useApi();
  const { data, isLoading } = patientApi.useGetList(
    {
      filtered: query ? { searchColumn: { $like: `%${query}%` } } : undefined,
    },
    {},
    false,
  );

  return (
    <SearchBar
      options={data?.results}
      keyAccessor="id"
      onChange={onChange}
      value={value}
      onQueryChange={setQuery}
      query={query}
      isLoading={isLoading}
      line={SearchBarLine(fDate)}
      autoFocus={autoFocus}
    />
  );
};

const SearchBarLine =
  (fDate: DateTimeFormatter) =>
  ({ gender, lastname, firstname, socialSecurityNumber, birthDate }: PatientFront): JSX.Element =>
    (
      <div className="ui-active:bg-gray-100 flex items-center py-2 px-3">
        <Icon
          // eslint-disable-next-line no-nested-ternary
          icon={gender === Gender.FEMALE ? 'venus' : gender === Gender.MALE ? 'mars' : 'marsAndVenus'}
          className="text-primary h-5 w-3 pr-2"
        />
        <div className="text-brand-black flex-1 text-sm font-medium">
          <span className="uppercase">{lastname}</span> {firstname}
        </div>
        <div className="text-sm text-gray-500">
          <span className="pr-5">{socialSecurityNumber}</span> {birthDate && fDate(new Date(birthDate))}
        </div>
      </div>
    );
