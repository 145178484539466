import { countriesOption } from '@utils/country/countriesOption';
import { useTranslation } from 'next-i18next';
import { Select, SelectProps } from './Select';

export const CountrySelect = <Multiple extends boolean | undefined = undefined>(
  props: Omit<SelectProps<string, Multiple>, 'options'>,
): JSX.Element => {
  const { i18n } = useTranslation('common');

  return <Select options={countriesOption(i18n.language)} {...props} />;
};
