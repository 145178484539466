import { ButtonGroup, ButtonGroupButton, ButtonGroupProps } from '@features/ui/components/ButtonGroup';
import classNames from 'classnames';
import { HTMLProps, Key, ReactNode, useState, cloneElement, useMemo, useEffect, useCallback } from 'react';

export type ButtonTab<T extends Key> = {
  button: string | Omit<ButtonGroupButton<T>, 'btnId' | 'disabled'>;
  component: ReactNode;
  // Seems more logical to have at this level
  disabled?: boolean;
  /**
   * The unique id of this tab. The uniqueness is not tested.
   */
  tabId: T;
};

export type ButtonTabsProps<T extends Key> = {
  btnGroup?: Pick<ButtonGroupProps<T>, 'breakpointWidth'>;
  /**
   * The tab container.
   * @default a simple div
   */
  container?: Parameters<typeof cloneElement>[0];
  onChange?(tabId?: T): void;
  /**
   * Use false to unselect
   */
  selected?: T | false;
  selectedDefault?: T;
  tabs: ButtonTab<T>[];
  /**
   * @default false
   */
  vertical?: boolean;
} & Pick<HTMLProps<HTMLDivElement>, 'className'>;

export const ButtonTabs = <T extends Key>({
  btnGroup,
  container,
  onChange,
  selected,
  selectedDefault,
  tabs,
  vertical,
  ...props
}: ButtonTabsProps<T>): JSX.Element => {
  const [selectedId, setSelectedId] = useState<T | undefined | false>(selected ?? selectedDefault);

  useEffect(() => {
    // Controlled
    if (selected !== undefined) {
      setSelectedId(selected);
    }
  }, [selected]);

  const Container = useCallback(
    ({ children }: { children: ReactNode }) => cloneElement(container || <div />, {}, children),
    [container],
  );

  const buttons = useMemo(
    () =>
      tabs.map(tab => ({
        btnId: tab.tabId,
        ...(typeof tab.button === 'string' ? { label: tab.button } : tab.button),
        disabled: tab.disabled,
      })),
    [tabs],
  );
  const selectedTab = tabs.find(({ tabId }) => tabId === selectedId);

  return (
    <div
      {...props}
      className={classNames('flex w-full', {
        'flex-col': !vertical,
      })}
    >
      <div className="flex">
        <ButtonGroup
          className={classNames({
            'flex-col': vertical,
          })}
          buttons={buttons}
          onChange={id => {
            if (onChange) {
              onChange(id);
            }

            if (selected === undefined) {
              // Uncontrolled
              setSelectedId(id);
            }
          }}
          selected={selectedId}
          {...btnGroup}
        />
      </div>

      {selectedTab && <Container>{selectedTab.component}</Container>}
    </div>
  );
};
