import { AnalysisDisplayTab } from '@ch-apptitude-icc/common/shared/entities';
import { FeatureFlags, isFeatureEnabled } from '@ch-apptitude-icc/common/shared/feature-flags';
import { ArrayNotEmpty } from '@ch-apptitude-icc/common/shared/validation';
import {
  Catalogue,
  errors,
  OrderedAnalysis,
  OrderStatus,
  OrderTemplate,
} from '@ch-apptitude-icc/lablink/shared/entities';
import { convertErrorsToFormik, FormikEntityWrapper } from '@common/frontend/components/ui-forms/FormikEntityWrapper';
import {
  getAnalysisForGroup,
  getTemplatesAnalyses,
  groupAnalysesByGroup,
  groupAnalysesByTemplate,
  OrderAnalysisGrouped,
} from '@features/analyses/utils';
import { OrderCard } from '@features/orders/components';
import { ButtonAnalysisGroups } from '@features/orders/components/detail/analysis/ButtonAnalysisGroups';
import { CatalogueSelect } from '@features/orders/components/detail/analysis/CatalogueSelect';
import { OrderAnalysesCreateTemplate } from '@features/orders/components/detail/analysis/OrderAnalysesCreateTemplate';
import {
  OrderAnalysesGrid,
  OrderAnalysesGridGroupActions,
} from '@features/orders/components/detail/analysis/OrderAnalysesGrid';
import {
  OrderAnalysesSelection,
  OrderAnalysesSelectionProps,
  SelectedAnalysesList,
} from '@features/orders/components/detail/analysis/OrderAnalysesSelection';
import { OrderAnalysisChipProps } from '@features/orders/components/detail/analysis/OrderAnalysisChip';
import { useAvailablePricingCategories } from '@features/orders/services/useAvailablePricingCategories';
import { useMaterialsList } from '@features/orders/services/useMaterialsList';
import {
  selectExistingOrder,
  selectFromStep,
  selectIsReadonly,
  selectStatus,
} from '@features/orders/store/OrderState/selectors';
import {
  setOrderData,
  setOrderEditingStep,
  setOrderStepData,
  setOrderStepStatus,
} from '@features/orders/store/OrderState/slice';
import { createOrderFromOrder } from '@features/orders/types/createOrderFromOrder';
import { CreateOrderStepAnalysis } from '@features/orders/types/CreateOrderStepAnalysis';
import { MaxAnalysisPerMaterial } from '@features/orders/types/OrderFront';
import { Button, Input, Toast } from '@features/ui/components';
import { ErrorMessage } from '@features/ui/components/form/ErrorMessage';
import { Spinner } from '@features/ui/components/Spinner';
import { showToast } from '@features/ui/services/showToast';
import { createSelector } from '@reduxjs/toolkit';
import { envConfig } from '@root/envConfig';
import { AnalysisFront, useApi } from '@services/api';
import { useMediaQuery } from '@services/hooks/window/query';
import { useAppDispatch, useAppSelector } from '@services/store/hooks';
import { getTWBreakpoint } from '@utils/tailwind';
import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import Fuse from 'fuse.js';
import { groupBy as lodashGroupBy, uniq } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

class CreateOrderFrontAnalysisData {
  @ArrayNotEmpty({ errorName: errors.orders.validation.analysisListEmpty })
  requestedAnalysis!: Array<Pick<OrderedAnalysis, 'analysisId' | 'pricingCategoryId'>>;
}

function SaveButton() {
  // ID-1545: used when analyses are added to an ordered order
  const { submitForm } = useFormikContext();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return <Button label={t('pages.order.analysis.save')} leftIcon="floppyDisk" onClick={submitForm} />;
}

export const OrderAnalysis = (): JSX.Element => {
  const { t } = useTranslation();
  const { orderApi } = useApi();
  const dispatch = useAppDispatch();

  const { requestedAnalysis = [] } = useAppSelector(selectFromStep.analysis.selectData);

  // ID-1545
  const status = useAppSelector(selectStatus);
  const updateOrder = orderApi.useUpdateOne(undefined, {
    onSuccess: data => {
      dispatch(
        setOrderData({
          ...createOrderFromOrder(data),
          existing: { id: +data.id, requestDate: data.requestDate ?? undefined, order: data },
        }),
      );
    },
  });

  const canAddAnalysisWhenOrdered = status === OrderStatus.ORDERED;
  const currentOrder = useAppSelector(createSelector(selectExistingOrder, existing => existing?.order ?? false));

  const serverErrors = useAppSelector(selectFromStep.analysis.selectErrors);
  const serverErrorsAsFormik = convertErrorsToFormik(serverErrors, t);

  return (
    <FormikEntityWrapper<CreateOrderFrontAnalysisData>
      type={CreateOrderFrontAnalysisData}
      initialValues={{ requestedAnalysis }}
      initialErrors={serverErrorsAsFormik}
      initialTouched={serverErrorsAsFormik}
      onSubmit={v => {
        dispatch(setOrderStepData(new CreateOrderStepAnalysis(v.requestedAnalysis)));
        dispatch(setOrderStepStatus({ key: 'analysis', status: 'done' }));

        if (canAddAnalysisWhenOrdered && currentOrder) {
          // ID-1545
          updateOrder.mutate({
            id: currentOrder.id,
            status: currentOrder.status,
            requestedAnalysis: v.requestedAnalysis.map(({ analysisId, pricingCategoryId }) => ({
              analysisId,
              pricingCategoryId,
            })),
          });
        } else {
          dispatch(setOrderEditingStep('order'));
        }
      }}
      enableReinitialize
    >
      <Form id="analysis">
        <OrderCard
          icon="flask"
          editable={canAddAnalysisWhenOrdered}
          stepKey="analysis"
          submitButton={canAddAnalysisWhenOrdered ? <SaveButton /> : undefined}
          title={t('common.analysis')}
        >
          <OrderAnalysisContent />
        </OrderCard>
      </Form>
    </FormikEntityWrapper>
  );
};

function OrderAnalysisContent(): JSX.Element {
  const { cataloguesApi, orderTemplateApi, analysisApi } = useApi();
  const { values, setFieldValue } = useFormikContext<CreateOrderFrontAnalysisData>();
  const { requestedAnalysis } = values;

  const isEditing = useAppSelector(selectFromStep.analysis.selectIsEditing);
  const isReadonly = useAppSelector(selectIsReadonly);
  const autofocus = useMediaQuery('md');

  // ID-1545
  const status = useAppSelector(selectStatus);
  // ID-1596
  const requestedMaterials = useMaterialsList();

  const canAddAnalysisWhenOrdered = status === OrderStatus.ORDERED;
  const existingRequestAnalyses = useAppSelector(
    createSelector(selectExistingOrder, existing => existing?.order.requestedAnalysis ?? []),
  );
  const existingRequestAnalysesIds = existingRequestAnalyses.map(({ analysisId }) => analysisId);
  const canEditSelectedAnalysis = useMemo<OrderAnalysesSelectionProps['isAnalysisEditable']>(
    () => (canAddAnalysisWhenOrdered ? ({ id }) => !existingRequestAnalysesIds.includes(id) : () => true),
    [canAddAnalysisWhenOrdered, existingRequestAnalysesIds],
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [selectedCatalogue, _setSelectedCatalogue] = useState<number | undefined>(undefined);

  const { t } = useTranslation('common');

  const [search, setSearch] = useState('');
  const catalogue = cataloguesApi.useGetOne(selectedCatalogue ?? -1, { enabled: !!selectedCatalogue });
  // Currently we want all analyses, so we can show disabled ones if they already were selected,
  // but hide them if they are to be chosen (filtered from `catalogue`)
  const allAnalysesMap = analysisApi.useGetMap({ filtered: { disabled: { $in: [true, false] } } });

  const orderTemplateList = orderTemplateApi.useGetList({
    pageSize: envConfig.constants.api.unboundedPageSize,
  });
  const availablePricingCategories = useAvailablePricingCategories();

  const [tabTouched, setTabTouched] = useState(false);
  const [displayedTab, setDisplayedTab] = useState<AnalysisDisplayTab | 'favorites' | 'all'>(
    AnalysisDisplayTab.MEDICAL,
  );

  const hasFavorites = orderTemplateList.data && orderTemplateList.data.total > 0;
  useEffect(() => {
    if (hasFavorites && !tabTouched) {
      setDisplayedTab('favorites');
    } else if (
      catalogue.data?.small &&
      (!tabTouched || (tabTouched && displayedTab !== 'all' && displayedTab !== 'favorites'))
    ) {
      setDisplayedTab('all');
    } else if (catalogue.data && !catalogue.data.small && (!tabTouched || (tabTouched && displayedTab === 'all'))) {
      setDisplayedTab(AnalysisDisplayTab.MEDICAL);
    }
  }, [tabTouched, hasFavorites, catalogue.data, displayedTab]);

  const analysesSelected = useMemo(
    () =>
      Object.entries(lodashGroupBy(requestedAnalysis, 'pricingCategoryId')).map(
        ([pricingId, selected]) =>
          ({
            pricing: pricingId ? availablePricingCategories[+pricingId] : undefined,
            analyses: (allAnalysesMap.data
              ? selected
                  .map(sel => sel.analysisId)
                  .filter(sel => allAnalysesMap.data.has(sel))
                  .map(sel => allAnalysesMap.data.get(sel)!)
              : []
            ).map(analysis => ({
              ...analysis,
              pricing: analysis.pricings?.find(pricing => pricing.pricingCategoryId === +pricingId) ?? {
                pricingCategoryId: +pricingId,
              },
              pricings: [],
            })),
          } satisfies SelectedAnalysesList),
      ),
    [allAnalysesMap.data, requestedAnalysis, availablePricingCategories],
  );

  const setSelectedCatalogue = useCallback(
    async (v: number | undefined) => {
      if (isFeatureEnabled(FeatureFlags.ALLOW_MULTIPLE_PRICINGS) || !v) {
        return _setSelectedCatalogue(v);
      }

      const currentPricing = requestedAnalysis.length ? requestedAnalysis[0].pricingCategoryId : undefined;

      if (!currentPricing) {
        return _setSelectedCatalogue(v);
      }

      // Re-fetch (or pre-fetch) the new catalogue
      const newCatalogue: Catalogue = await cataloguesApi.queryClient.fetchQuery({
        ...cataloguesApi.getOneQuery(v),
        staleTime: 60000,
      });

      if (newCatalogue.pricingCategory?.id === currentPricing) {
        return _setSelectedCatalogue(v);
      }

      if (
        // eslint-disable-next-line no-restricted-globals,no-alert
        confirm(t('pages.order.analysis.tempNoMultiplePricings'))
      ) {
        void setFieldValue('requestedAnalysis', []);
        return _setSelectedCatalogue(v);
      }

      return undefined;
    },
    [cataloguesApi, requestedAnalysis, setFieldValue, t],
  );

  const addAnalysis: OrderAnalysesGridGroupActions['onAdd'] = useCallback(
    (analyses: AnalysisFront[]) => {
      const ids = analyses.map(_ => ({ analysisId: _.id, pricingCategoryId: catalogue.data?.pricingCategory?.id }));
      const newAnalyses = uniq(requestedAnalysis.concat(ids));

      if (newAnalyses.length <= envConfig.constants.maxAnalyses) {
        void setFieldValue('requestedAnalysis', newAnalyses);
      } else {
        showToast({
          title: t('pages.order.analysis.tempAnalysisNumberLimitReached', { count: envConfig.constants.maxAnalyses }),
          variant: 'error',
        });
      }
    },
    [requestedAnalysis, setFieldValue, catalogue.data, t],
  );

  const analyses: AnalysisFront[] = useMemo(() => {
    const AllAnalyses = (catalogue.data?.analysis || [])
      // Hide disabled analyses
      .filter(({ analysis }) => analysis && !analysis?.disabled)
      .map(analysis => ({
        ...analysis.analysis!,
        pricing: analysis.analysis?.pricing ?? { pricingCategoryId: catalogue.data?.pricingCategory?.id },
        orderInCatalogue: analysis.lineOrder ?? null,
      }));

    if (!canAddAnalysisWhenOrdered) {
      return AllAnalyses;
    }

    // ID-1545: only analyses with same material
    const samplingMaterialIds = existingRequestAnalyses
      .map(({ samplingMaterialId }) => samplingMaterialId)
      .filter((v): v is number => v !== null && v !== undefined);

    return AllAnalyses.filter(({ samplingMaterialId }) => samplingMaterialIds.includes(samplingMaterialId as never));
  }, [catalogue.data, canAddAnalysisWhenOrdered, existingRequestAnalyses]);

  const analysisIds = requestedAnalysis.map(analysis => analysis.analysisId);
  const requestedMaterialAnalysis = analyses.filter(analysis => analysisIds.includes(analysis.id));
  const maxAnalysesPerMaterials: { [key: string]: MaxAnalysisPerMaterial } | undefined = useMemo(
    () =>
      requestedMaterials?.reduce((acc, requestedMaterial) => {
        const current = requestedMaterialAnalysis.filter(
          material => material.samplingMaterialId === requestedMaterial.material.id,
        ).length;
        const max = requestedMaterial?.material?.maxAnalysesPerUnit
          ? requestedMaterial.numberUnits * requestedMaterial.material.maxAnalysesPerUnit
          : 0;
        return {
          ...acc,
          [requestedMaterial.material.id]: {
            canAddAnalysis: current < max,
            current,
            max,
          },
        };
      }, {}),
    [requestedMaterials, requestedMaterialAnalysis],
  );

  const removeAnalysis: OrderAnalysesGridGroupActions['onRemove'] = useCallback(
    (analysesList: AnalysisFront[]) => {
      void setFieldValue(
        'requestedAnalysis',
        requestedAnalysis.filter(
          ({ analysisId, pricingCategoryId }) =>
            !analysesList.find(
              toRemove => toRemove.id === analysisId && toRemove.pricing?.pricingCategoryId === pricingCategoryId,
            ),
        ),
      );
    },
    [requestedAnalysis, setFieldValue],
  );

  const handleAnalysisClick: OrderAnalysisChipProps['onClick'] = useCallback(
    (analysis: AnalysisFront, selected: boolean) => {
      let maxAnalyseMaterial;
      if (maxAnalysesPerMaterials) {
        if (canAddAnalysisWhenOrdered && analysis?.samplingMaterialId) {
          maxAnalyseMaterial = maxAnalysesPerMaterials[analysis.samplingMaterialId];
        }

        if (selected) {
          removeAnalysis([analysis]);
        } else if (!maxAnalyseMaterial || (canAddAnalysisWhenOrdered && maxAnalyseMaterial.canAddAnalysis)) {
          addAnalysis([analysis]);
        }
      }
    },
    [addAnalysis, removeAnalysis, maxAnalysesPerMaterials, canAddAnalysisWhenOrdered],
  );

  const favorites: OrderTemplate[] = useMemo(
    () =>
      orderTemplateList.data?.results.map(template => ({
        ...template,
        analysis: template.analysis?.map(analysis => ({
          ...analysis,
          pricing: analysis.pricing ?? { pricingCategoryId: catalogue.data?.pricingCategory?.id },
        })),
      })) ?? [],
    [orderTemplateList.data, catalogue.data],
  );

  const fuse = useMemo(() => new Fuse<AnalysisFront>(analyses, { keys: ['name'], threshold: 0.45 }), [analyses]);
  const realSearch = search?.toLowerCase().trim();

  const analysisGridBuilder = useCallback(
    (
      analysisList: AnalysisFront[],
      groupBy: (list: AnalysisFront[]) => OrderAnalysisGrouped,
      canSelectAll?: boolean,
    ) => (
      <OrderAnalysesGrid
        analyses={analysisList}
        groupActions={{
          onAdd: addAnalysis,
          onRemove: removeAnalysis,
        }}
        chip={{
          isInteractive: canEditSelectedAnalysis,
          onClick: handleAnalysisClick,
          selected(analysis: AnalysisFront): boolean {
            return !!requestedAnalysis.find(
              ana => ana.analysisId === analysis.id && ana.pricingCategoryId === analysis.pricing?.pricingCategoryId,
            );
          },
        }}
        groupBy={groupBy}
        canSelectAll={canSelectAll}
      />
    ),
    [addAnalysis, canEditSelectedAnalysis, requestedAnalysis, handleAnalysisClick, removeAnalysis],
  );

  const analysisGrid = useMemo(() => {
    if (realSearch.length > 0) {
      return analysisGridBuilder(
        fuse.search(realSearch).map(option => option.item),
        groupAnalysesByGroup,
      );
    }

    if (displayedTab === 'favorites') {
      if (favorites.length === 0) {
        return <>{t('pages.order.analysis.emptyFavoritesList')}</>;
      }

      return analysisGridBuilder(
        getTemplatesAnalyses(favorites ?? [], analyses),
        groupAnalysesByTemplate(favorites ?? []),
        true,
      );
    }

    if (displayedTab === 'all') {
      return analysisGridBuilder(analyses, groupAnalysesByGroup);
    }

    return analysisGridBuilder(getAnalysisForGroup(displayedTab)(analyses), groupAnalysesByGroup);
  }, [t, analyses, analysisGridBuilder, displayedTab, favorites, realSearch, fuse]);

  return (
    <div className="flex flex-col gap-8">
      <OrderAnalysesSelection
        analyses={analysesSelected}
        isAnalysisEditable={canEditSelectedAnalysis}
        chip={{ onClick: handleAnalysisClick }}
        editable={isEditing}
      />

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      {isEditing && <CatalogueSelect selected={selectedCatalogue} setSelected={setSelectedCatalogue} />}

      {isEditing &&
        selectedCatalogue &&
        (catalogue.isLoading || orderTemplateList.isLoading ? (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <ErrorMessage name="requestedAnalysis" />

            {canAddAnalysisWhenOrdered && (
              <Toast title={t('pages.order.analysis.infoAddAnalysisToExisting')} variant="info" />
            )}
            {canAddAnalysisWhenOrdered &&
              maxAnalysesPerMaterials &&
              Object.keys(maxAnalysesPerMaterials)
                .filter((key: string) => !maxAnalysesPerMaterials[key].canAddAnalysis)
                .map((key: string) => (
                  <Toast
                    key={key}
                    title={t('pages.order.analysis.maxAnalysisNumberReached', {
                      materialName: requestedMaterials?.find(rm => rm.material.id === +key)?.material.name,
                    })}
                    variant="warning"
                  />
                ))}

            <div className="flex flex-wrap gap-2">
              {realSearch.length === 0 && (
                <div className="flex-basis inline-flex flex-grow justify-center sm:flex-grow-0">
                  <ButtonAnalysisGroups
                    breakpointWidth={getTWBreakpoint('lg', { asPx: true })}
                    onChange={value => {
                      setDisplayedTab(value);
                      setTabTouched(true);
                    }}
                    selected={displayedTab}
                    small={catalogue.data?.small ?? false}
                  />
                </div>
              )}

              <div className="flex flex-grow justify-end">
                <Input
                  autoFocus={autofocus}
                  className={classNames('flex-grow transition-all', { 'sm:flex-grow-0': realSearch.length === 0 })}
                  debounceTime={250}
                  iconLeft={catalogue.isLoading ? 'loading' : 'magnifyingGlass'}
                  placeholder={t('common.actions.searchPlaceHolder')}
                  onDebounce={value => setSearch(value)}
                  type="text"
                  defaultValue={search}
                />
              </div>
            </div>

            {analysisGrid}
          </>
        ))}

      {!isEditing && !isReadonly && (
        <div>
          <OrderAnalysesCreateTemplate selectedAnalyses={requestedAnalysis} />
        </div>
      )}
    </div>
  );
}
