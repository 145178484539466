import { useTranslation } from 'next-i18next';

type NoResultsProps = {
  items: string[];
};
export const NoResults = ({ items }: NoResultsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <span className="text-brand-black block py-5 text-lg">{t('component.table.noResultsForSearch')}</span>
      <span className="block pb-3 text-sm text-gray-500">{t('component.table.canSearchBy')}</span>
      <div>
        <ul className="list-item text-sm text-gray-500">
          {items.map(item => (
            <li key={item}>- {item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
