import { PricingCategory } from '@ch-apptitude-icc/lablink/shared/entities';
import { envConfig } from '@root/envConfig';
import { useApi } from '@services/api';
import { useMemo } from 'react';

export const useAvailablePricingCategories = (): Record<number, PricingCategory> => {
  const { cataloguesApi } = useApi();
  const allCatalogues = cataloguesApi.useGetList({ pageSize: envConfig.constants.api.unboundedPageSize });

  return useMemo(
    () =>
      Object.fromEntries(
        allCatalogues.data?.results
          .filter(c => !!c.pricingCategory)
          .map(c => [c.pricingCategory!.id, c.pricingCategory!]) ?? [],
      ),
    [allCatalogues.data],
  );
};
