import { OrderStatus, ReportStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { useMaterialsList } from '@features/orders/services/useMaterialsList';
import {
  selectIsNew,
  selectOrderId,
  selectRequestDate,
  selectStatus,
} from '@features/orders/store/OrderState/selectors';
import { statusToChipsVariant } from '@features/orders/utils';
import { Button, ContentHeader, Dropdown, Icon } from '@features/ui/components';
import { useModal } from '@features/ui/modal';
import { ConfirmModalButton } from '@features/ui/modal/components/ConfirmModal';
import { useApi } from '@services/api';
import { useFormatters } from '@services/formatters';
import { getRoute } from '@services/routes';
import { useAppSelector } from '@services/store/hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

export const OrderContentHeader = (): JSX.Element => {
  const requestDate = useAppSelector(selectRequestDate);
  const status = useAppSelector(selectStatus);
  const isNew = useAppSelector(selectIsNew);
  const orderId = useAppSelector(selectOrderId);

  const modal = useModal();
  const { fDate, fTime } = useFormatters();
  const router = useRouter();
  const { t } = useTranslation('common');

  const { orderApi } = useApi();

  const useDelete = orderApi.useDeleteOne({ onSuccess: () => void router.push(getRoute.home()) });

  const requestedMaterials = useMaterialsList();
  const numberOfLabels = useMemo(() => {
    if (!requestedMaterials) {
      return 1;
    }
    return requestedMaterials.map(mat => mat.numberLabels).reduce((a, b) => a + b, 0);
  }, [requestedMaterials]);

  const confirmThenDelete = useCallback(
    (idToDelete: string) => {
      modal.openModal('confirm', {
        message: t('pages.order.deleteModal.message'),
        title: t('pages.order.deleteModal.title'),
        onClick: btn => {
          if (btn === ConfirmModalButton.BUTTON_YES) {
            useDelete.mutate(idToDelete);
          }
        },
      });
    },
    [modal, useDelete, t],
  );

  const btnDelete = orderId && (
    <Button loading={useDelete.isLoading} icon="trash" onClick={() => confirmThenDelete(orderId.toFixed())} />
  );
  const btnLabel = orderId && (
    <Button
      leftIcon="rectangleBarcode"
      url={orderApi.getBarcodePdfUrl(+orderId, numberOfLabels)}
      newTab
      label={t('pages.orders.stickers')}
    />
  );
  const btnSheet = orderId && (
    <Button
      leftIcon="fileMagnifyingGlass"
      url={orderApi.getOrderSheetPdfUrl(+orderId)}
      newTab
      label={t('pages.orders.orderSheet')}
    />
  );

  const rightContentHeader = (
    <div className="flex flex-col">
      <div className="flex flex-row justify-center gap-3">
        {status === OrderStatus.TO_ORDER && (
          <>
            {isNew ? (
              <Button
                label={t('common.cancel')}
                variant="transparent-outline"
                onClick={() => router.back()}
                leftIcon="arrowTurnDownLeft"
              />
            ) : (
              <>
                {btnDelete}
                {btnLabel}
                {btnSheet}
              </>
            )}

            {/* <OrderMgmtSave /> */}
          </>
        )}

        {status === OrderStatus.ORDERED && (
          <>
            {btnDelete}
            {btnLabel}
            {btnSheet}
          </>
        )}

        {(status === OrderStatus.IN_PROGRESS || status === OrderStatus.DONE) && (
          <>
            {btnLabel}
            {btnSheet}
          </>
        )}
        {orderId && <OrderReportButton orderId={orderId} />}
      </div>
      {orderId && <OrderReportDate orderId={orderId} />}
    </div>
  );

  let subtitle: string | undefined;
  if (!isNew && requestDate) {
    const date = new Date(requestDate);
    subtitle = t('pages.order.contentHeader.existing.subtitle', { date: fDate(date), time: fTime(date) });
  }

  return (
    <ContentHeader
      title={isNew ? t('pages.order.contentHeader.title') : <ExistingTitle />}
      upperTitle={t('common.order_other')}
      subtitle={subtitle}
      backLink
      rightElement={rightContentHeader}
    />
  );
};

function OrderReportDate({ orderId }: { orderId: number }): JSX.Element {
  const { t } = useTranslation('common');

  const { fDate, fTime } = useFormatters();
  const { referencesApi } = useApi();
  const { data } = referencesApi.useGetList({
    filtered: { orderId: { $value: orderId } },
    sorted: { reportDate: 'DESC' },
  });
  const reportDate = data?.results[0]?.reportDate;

  return reportDate ? (
    <div className="p-2 text-end text-xs text-white">
      {t('pages.order.reportTransmitted')} {fDate(new Date(reportDate))} {t('common.date.at')}{' '}
      {fTime(new Date(reportDate))}
    </div>
  ) : (
    <div />
  );
}

function OrderReportButton({ orderId }: { orderId: number }): JSX.Element {
  const { t } = useTranslation('common');
  const { fDate } = useFormatters();
  const { referencesApi, materialApi } = useApi();
  const { data } = referencesApi.useGetList({ filtered: { orderId: { $value: orderId } } });
  const results = data?.results;
  const reports = results?.filter(item => !!item.file);
  const materials = materialApi.useGetMap().data;

  if (!data || !results || !reports?.length) {
    return <div />;
  }

  if (results.length > 1) {
    return (
      <Dropdown
        label={<Button label={t('pages.orders.report.report')} leftIcon="fileReport" rightIcon="chevronDown" />}
        items={reports.map(item => {
          let reportType = 'pages.orders.report.subReport';
          if (item.reportStatus === ReportStatus.PRELIMINARY_REPORT) {
            reportType = 'pages.orders.report.subPreliminaryReport';
          }
          if (item.reportStatus === ReportStatus.CORRECTED_REPORT) {
            reportType = 'pages.orders.report.subCorrectedReport';
          }

          const date = typeof item.reportDate === 'string' ? new Date(item.reportDate) : item.reportDate;
          const material = item.materialId ? materials?.get(item.materialId)?.name || item.materialId : undefined;

          return {
            label: t(reportType, {
              id: material ? `${item.id} (${material})` : item.id,
              date: date ? fDate(date) : 'N/A',
            }),
            url: referencesApi.getReportUrl(item.id),
            key: item.id,
            leftIcon: 'fileReport',
            newTab: true,
            unreadNotification: !item.read,
          };
        })}
      />
    );
  }
  const reportReferenceId = reports[0].id;

  return (
    <Button
      label={t('pages.orders.report.report')}
      leftIcon="fileReport"
      newTab
      url={referencesApi.getReportUrl(reportReferenceId)}
      unreadNotification={!reports[0]?.read}
    />
  );
}

function ExistingTitle() {
  const { t } = useTranslation();
  const { orderApi } = useApi();

  const id = useAppSelector(selectOrderId);
  const order = orderApi.useGetOne(id?.toString() as string, { enabled: !!id });

  return (
    <span className="flex flex-row items-center gap-4">
      {t('pages.order.contentHeader.existing.title', { id })}

      {order.data && statusToChipsVariant[order.data.status](t)}

      {order.data?.urgent && (
        <span className="text-lg" title={t('pages.order.contentHeader.existing.urgent')}>
          <Icon className="text-amber-500" icon="lightEmergency" />
        </span>
      )}
    </span>
  );
}
