import { AnalysisDisplayTab } from '@ch-apptitude-icc/common/shared/entities';
import { AnalysisSector, OrderTemplate } from '@ch-apptitude-icc/lablink/shared/entities';
import { IconType } from '@features/ui/components';
import { AnalysisFront } from '@services/api';

// This place is the stuff of nightmares

export interface OrderAnalysisGrouped {
  [group: string]: { analysis: AnalysisFront[]; id?: number };
}

export enum AnalysisGroups {
  // '_' to always be first
  MEDICAL = 'medical',
  MICROBIOLOGY = 'microbiology',
  TOXICOLOGICAL = 'toxicological',
}

export type AnalysisSectorGroup = {
  readonly icon: IconType;

  readonly i18nKey: string;

  readonly sectors: AnalysisSector[];
};

export const AnalysisSectorGroups: Record<AnalysisDisplayTab, AnalysisSectorGroup> = {
  [AnalysisDisplayTab.MEDICAL]: {
    icon: 'briefcaseMedical',
    i18nKey: 'entities.analysis.sectors.grouped.medical',
    sectors: [AnalysisSector.CHEMICAL, AnalysisSector.HEMATOLOGY],
  },
  [AnalysisDisplayTab.MICROBIOLOGY]: {
    icon: 'virus',
    i18nKey: 'entities.analysis.sectors.grouped.microbiology',
    sectors: [AnalysisSector.MICROBIOLOGY],
  },
  [AnalysisDisplayTab.TOXICOLOGY]: {
    icon: 'tablets',
    i18nKey: 'entities.analysis.sectors.grouped.toxicological',
    sectors: [AnalysisSector.BIOMONITORING, AnalysisSector.TOXICOLOGY],
  },
};

export const getAnalysisForGroup =
  (tab: AnalysisDisplayTab) =>
  (a: AnalysisFront[]): AnalysisFront[] =>
    a.filter(b => b.displayTab === tab);

export const getTemplatesAnalyses = (templates: OrderTemplate[], analyses: AnalysisFront[]): AnalysisFront[] => {
  const ids = templates.flatMap(template => (template.analysis || []).flatMap(({ id }) => id));
  return analyses.filter(analysis => ids.includes(analysis.id));
};

export const groupAnalysesByGroup = (analyses: AnalysisFront[]): OrderAnalysisGrouped =>
  analyses.reduce<OrderAnalysisGrouped>((grouped, current) => {
    // Group analysis by the `lablinkGroup`
    const group = current.publicSubCategory || '';
    let list = grouped[group]?.analysis;
    if (!list) {
      list = [];
      // eslint-disable-next-line no-param-reassign
      grouped[group] = { analysis: list, id: current.id };
    }

    list.push(current);
    return grouped;
  }, {});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const groupAnalysesByTemplate = (templates: OrderTemplate[]) => (analyses: AnalysisFront[]) => {
  const ids = analyses.map(({ id }) => id);
  return templates.reduce<OrderAnalysisGrouped>((grouped, current) => {
    const selectAnalyses = (current.analysis || []).filter(({ id }) => ids.includes(id));
    if (selectAnalyses.length === 0) {
      // To not add an empty group
      return grouped;
    }

    return {
      ...grouped,
      [current.templateName || '']: { analysis: selectAnalyses, id: current.id },
    };
  }, {});
};
