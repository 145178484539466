import { Button, ButtonProps } from '@features/ui/components/Button';
import { useWindowResize } from '@services/hooks/window';
import classNames from 'classnames';
import { HTMLProps, Key, useEffect, useState } from 'react';

export type ButtonGroupButton<T extends Key> = Omit<ButtonProps, 'newTab' | 'scroll' | 'type' | 'url' | 'variant'> & {
  /**
   * The unique id of this button in the Group. The uniqueness is not tested.
   */
  btnId: T;
  container?: (button: JSX.Element) => JSX.Element;
};

export type ButtonGroupProps<T extends Key> = {
  /**
   * Breakpoint for the window's width.
   * When the window's width is smaller than this value,
   *  it removes the label and only keep a left (priority) or right icon,
   *  for the buttons that have these values.
   *
   *  @note the best solution would be to get the parent container size
   *    and calculate if there enough space for the texts.
   *    If not, remove the text and keep icon (left icon -> central icon + no text)
   *    for unselected buttons, and still not enough, only icons.
   *    The calculation of 'Is there enough space for text' is trickier
   *    and a compromise was chosen: the developer set this value at use.
   */
  breakpointWidth?: number;
  buttons: ButtonGroupButton<T>[];
  /**
   * When a button is clicked
   * @param btnId the button that is now selected
   */
  onChange?(btnId: T): void;
  /**
   * Use false to unselect
   */
  selected?: T | false;
  selectedDefault?: T;
} & Pick<HTMLProps<HTMLDivElement>, 'className'>;

export const ButtonGroup = <T extends Key>(props: ButtonGroupProps<T>): JSX.Element => {
  const [selected, setSelected] = useState<T | undefined | false>(props.selected || props.selectedDefault);

  const minBreakpoint = props.breakpointWidth || 0;
  // window width
  const { width: wWidth } = useWindowResize([{ width: minBreakpoint }], {
    disable: props.breakpointWidth === undefined,
  });

  useEffect(() => {
    // Controlled
    if (props.selected !== undefined) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  return (
    <div className={classNames('flex flex-row flex-wrap gap-1 rounded-lg bg-gray-100 p-[2px]', props.className)}>
      {props.buttons.map(({ btnId, onClick, container, ...copyBtnProps }) => {
        if (
          selected !== btnId &&
          wWidth < minBreakpoint &&
          copyBtnProps.label &&
          // Only one of them
          !copyBtnProps.leftIcon !== !copyBtnProps.rightIcon
        ) {
          // When not selected + breakpoint -> Removes the label and only keep an icon

          /* eslint-disable no-param-reassign */
          copyBtnProps.icon = copyBtnProps.leftIcon || copyBtnProps.rightIcon;

          delete copyBtnProps.label;
          delete copyBtnProps.leftIcon;
          delete copyBtnProps.rightIcon;
          /* eslint-enable no-param-reassign */
        }

        const button = (
          // @ts-expect-error due to the XOR
          <Button
            key={btnId}
            className="font-medium text-gray-800" // Can be replaced by spreading
            variant={selected === btnId ? 'primary' : 'transparent'}
            onClick={event => {
              if (onClick) {
                onClick(event);
              }

              if (!event.isPropagationStopped()) {
                if (props.onChange) {
                  props.onChange(btnId);
                }

                if (props.selected === undefined) {
                  // Uncontrolled
                  setSelected(btnId);
                }
              }
            }}
            {...copyBtnProps}
          />
        );

        return container ? container(button) : button;
      })}
    </div>
  );
};
