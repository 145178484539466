import classNames from 'classnames';
import { useCallback } from 'react';
import { Icon, IconType } from './icon';

type ToastVariant = 'success' | 'error' | 'warning' | 'info';

export type ToastProps = {
  message?: string;
  onClose?: () => void;
  title: string;
  variant?: ToastVariant;
};

type ToastifyProps = {
  closeToast?: () => void;
};

export const Toast = ({
  title,
  message,
  variant = 'info',
  onClose,
  // Currently unused, it'as actually used in showToast
  closeToast,
}: ToastProps & ToastifyProps): JSX.Element => {
  const style = styles[variant];

  const onCloseWrapper = useCallback(() => {
    if (onClose) {
      onClose();
    }
    if (closeToast) {
      closeToast();
    }
  }, [closeToast, onClose]);

  return (
    <div className={classNames('z-toast border-l-4', style.container)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <div className="flex h-5 w-5 flex-row items-center justify-center">
            <Icon icon={style.icon} className={style.iconColor} />
          </div>
        </div>

        <div className="ml-3">
          <h3 className={classNames('text-sm font-medium', style.title)}>{title}</h3>
          <div className={classNames('mt-2 text-sm', style.message)}>{message}</div>
        </div>
        {(onClose || closeToast) && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <Icon icon="xmark" className={classNames(style.iconColor, 'inline-flex')} onClick={onCloseWrapper} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type StyleDef = {
  [variant in ToastVariant]: {
    container: string;
    icon: IconType;
    iconColor: string;
    message: string;
    title: string;
  };
};

const styles: StyleDef = {
  error: {
    container: 'border-red-400 bg-red-50 p-4',
    icon: 'xmarkCircle',
    iconColor: 'text-red-700',
    message: 'text-red-700',
    title: 'text-red-800',
  },
  info: {
    container: 'border-blue-400 bg-blue-50 p-4',
    icon: 'circleInfo',
    iconColor: 'text-blue-700',
    message: 'text-blue-700',
    title: 'text-blue-800',
  },
  success: {
    container: 'border-green-400 bg-green-50 p-4',
    icon: 'checkCircle',
    iconColor: 'text-green-700',
    message: 'text-green-700',
    title: 'text-green-800',
  },
  warning: {
    container: 'border-yellow-400 bg-yellow-50 p-4',
    icon: 'exclamationCircle',
    iconColor: 'text-yellow-700',
    message: 'text-yellow-700',
    title: 'text-yellow-800',
  },
};
