import { Input, InputProps } from '@features/ui/components';
import { FocusEvent, useMemo } from 'react';

/* SSN => socialSecurityNumber */

// TODO: manage `defaultValue`, `onDebounce`
export type SSNInputProps = Omit<InputProps, 'addOnLeft' | 'defaultValue' | 'onDebounce' | 'pattern' | 'type'>;

// This pattern detects that the input "looks right" before we add dots in it. This is because we want to avoid
// messing up a user trying to edit characters in its field
// eslint-disable-next-line security/detect-unsafe-regex -- checked manually, this regex cannot explode
const SSNPartialPattern = /^[0-9]{4}\.?(([0-9]{1,3})|([0-9]{4}\.?([0-9]{1,2})?))?$/;
const SSNFullPattern = /^[0-9]{4}\.[0-9]{4}\.[0-9]{2}$/;
const addOnLeft = '756';
const sep = '.';

export const SSNInput = ({ onChange, onFocus, value, ...props }: SSNInputProps): JSX.Element => {
  const ssnEvent = { onChange, onFocus };

  for (const [key, onEvent] of Object.entries(ssnEvent)) {
    // @ts-expect-error Type will not be detected correctly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ssnEvent[key] = useMemo(() => {
      if (!onEvent) {
        return undefined;
      }

      return (event: FocusEvent<HTMLInputElement>) => {
        let { value: targetValue } = event.target;

        if (SSNFullPattern.test(targetValue)) {
          targetValue = addOnLeft + sep + targetValue;
        }

        // Modify the event to get the good ssn
        // eslint-disable-next-line no-param-reassign
        event.target.value = targetValue;
        onEvent(event);
      };
    }, [onEvent]);
  }

  let targetValue = value;
  if (targetValue) {
    while (targetValue.startsWith(addOnLeft)) {
      // Put the good value in the input and removes the `separation` if present
      targetValue = targetValue.substring(addOnLeft.length + (targetValue[addOnLeft.length] === sep ? sep.length : 0));
    }

    if (SSNPartialPattern.test(targetValue)) {
      if (targetValue.length > 4 && targetValue[4] !== sep) {
        targetValue = `${targetValue.substring(0, 4)}${sep}${targetValue.substring(4)}`;
      }

      if (targetValue.length > 9 && targetValue[9] !== sep) {
        targetValue = `${targetValue.substring(0, 9)}${sep}${targetValue.substring(9)}`;
      }
    }
  }

  return (
    <Input
      {...props}
      {...ssnEvent}
      addOnLeft={addOnLeft + sep}
      type="text"
      value={targetValue}
      maxLength={'756.1234.1234.12'.length}
    />
  );
};
