import { useWindowResize, UseWindowResizeOptions } from '@services/hooks/window';
import { getTWBreakpoint, TWScreenBreakpoint } from '@utils/tailwind';
import { useMemo } from 'react';

/**
 * @return true if the screen size is greater thant the given breakpoint
 */
export function useMediaQuery(breakpoint: TWScreenBreakpoint, options?: UseWindowResizeOptions): boolean {
  const width = useMemo(() => getTWBreakpoint(breakpoint, { asPx: true }), [breakpoint]);

  return useWindowResize([{ width }], options).width >= width;
}
