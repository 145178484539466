export const ContactTypes = [
  'askForInfo',
  'analysisQuestions',
  'samplesQuestions',
  'orderIssue',
  'myAccount',
  'bug',
  'other',
] as const;

export type ContactType = typeof ContactTypes[number];

const ctMap: Record<ContactType, string> = {
  askForInfo: 'Demande de renseignements',
  analysisQuestions: 'Questions sur une analyse',
  samplesQuestions: 'Questions sur les envois de prélèvement',
  orderIssue: 'Problème sur une commande',
  myAccount: 'Mon compte Lablink',
  bug: 'Bug sur le site',
  other: 'Autre',
};

export const contactTypeToMessage = (ct: ContactType) => ctMap[ct];
