import {
  BaseFormikElementWrapper,
  FormElementWrapperProps,
} from '@common/frontend/components/ui-forms/FormikElementWrapper';
import { FormElementWrapper } from './FormElementWrapper';

/**
 * Wrapper for form elements, same as FormElementWrapper but for Formik.
 * @see FormElementWrapper
 */
export const FormikElementWrapper = <T,>({ children, ...props }: FormElementWrapperProps<T>): JSX.Element => (
  <BaseFormikElementWrapper {...props} FormElementWrapper={FormElementWrapper}>
    {children}
  </BaseFormikElementWrapper>
);
