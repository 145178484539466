import { OR } from '@ch-apptitude-icc/common/shared/type-utils';
import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

export enum FooterDisplay {
  NONE = 'NONE',
  BOTTOM = 'BOTTOM',
  RIGHT_SIDE = 'RIGHT_SIDE',
}

export type CardProps = OR<
  { children: ReactNode },
  { header: ReactNode; footer?: ReactNode; footerDisplay?: FooterDisplay }
> &
  // If we render clickable cards
  Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'id' | 'onClick'>;

// We use flex-ordering to force form tab-key navigation order:
// from inputs to the "Suivant" button
export const Card = ({
  className,
  children,
  header,
  footer,
  footerDisplay = FooterDisplay.BOTTOM,
  ...props
}: CardProps): JSX.Element => (
  <div
    className={classNames(className, 'relative flex flex-col bg-white text-gray-800 shadow sm:rounded-lg')}
    {...props}
  >
    {header && (
      <div className={classNames('p-4 sm:rounded-lg md:p-7', { 'pb-6 md:pb-6': children }, 'text-lg', {})}>
        {header}
      </div>
    )}

    {children && <div className="p-4 pt-0 md:p-7 md:pt-0">{children}</div>}

    {footer && footerDisplay !== FooterDisplay.NONE && (
      <div
        className={classNames('p-6 pb-0', {
          'lg:absolute lg:bottom-12 lg:right-0': footerDisplay === FooterDisplay.RIGHT_SIDE,
        })}
      >
        {footer}
      </div>
    )}
  </div>
);
