import { APIClient, ClientOptions, APIClientInternals } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from '@ch-apptitude-icc/common/shared/type-utils';
import { ContactDto } from '@ch-apptitude-icc/lablink/shared/entities';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export class DummyEntity implements Entity<never> {
  id!: never;
}

export interface ContactClient<EntityType extends Entity<unknown>> extends APIClient<EntityType, never> {
  sendContactEmail(request: ContactDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>>;
}

class ContactClientImpl<EntityType extends Entity<unknown>>
  extends APIClientInternals.BaseClient<EntityType>
  implements ContactClient<EntityType>
{
  constructor({ entityType, resourcePath, options }: APIClientInternals.APIClientSchemaBasedExtensions<EntityType>) {
    super(resourcePath, entityType, options);
  }

  sendContactEmail(request: ContactDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
    return axios.post<void>(this.resourcePath, request, { ...this.options, ...options });
  }
}

const baseContactClient = APIClientInternals.emptyClient(DummyEntity, 'contact');

export const contactClient =
  <RealEntityType extends Entity<never> = DummyEntity>(overrideEntity?: Type<RealEntityType>) =>
  (optionsIn?: ClientOptions<RealEntityType>): ContactClient<RealEntityType> =>
    new ContactClientImpl<RealEntityType>(baseContactClient(overrideEntity)(optionsIn));
