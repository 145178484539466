import { OmitType } from '@ch-apptitude-icc/common/shared/type-utils';
import { IsDateString, IsOptionalIf, MaxDate } from '@ch-apptitude-icc/common/shared/validation';
import { Order as OrderBack, OrderPatientFields } from '@ch-apptitude-icc/lablink/shared/entities';
import { DoctorFront } from '@features/orders/types/DoctorFront';
import { Transform, TransformationType, Type } from 'class-transformer';
import { isDateString } from 'class-validator';

export class PatientFieldsFront extends OrderPatientFields {
  @IsDateString()
  @IsOptionalIf<PatientFieldsFront>(obj => !!obj.anonymous) // Anonymous patient do no have this value
  @Transform(({ value }: { value: string | null }) =>
    // Transform a potential ISO date+time into a simple ISO date
    // TODO: move this to backend type when possible
    value && isDateString(value) && value.includes('T') ? value.split('T')[0] : value,
  )
  @MaxDate(() => new Date())
  override birthDate?: string | null;
}

export class OrderFront extends OmitType(OrderBack, ['id', 'doctor', 'patient']) {
  @Transform(({ value, type }) => {
    if (type === TransformationType.CLASS_TO_PLAIN) {
      return Number(value);
    }
    return String(value);
  })
  id!: string;

  @Type(() => DoctorFront)
  doctor!: DoctorFront;

  @Type(() => PatientFieldsFront)
  patient!: PatientFieldsFront;
}

export interface MaxAnalysisPerMaterial {
  current: number;
  max: number;
  canAddAnalysis: boolean;
}
