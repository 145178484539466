import classNames from 'classnames';

type TextAreaProps = {
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  readonly?: boolean;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
  value?: string;
} & Pick<JSX.IntrinsicElements['textarea'], 'name' | 'required' | 'onChange' | 'onBlur' | 'className' | 'rows'>;

const getStyle = (error?: boolean) => (error ? 'text-red-400 outline-red-400' : 'text-gray-800 outline-gray-200');

export const TextArea = ({ resize = 'none', ...props }: TextAreaProps): JSX.Element => (
  <div className="mt-1 w-full">
    <textarea
      name={props.name}
      id={props.name}
      rows={props.rows}
      className={classNames(
        'block w-full rounded-md shadow-sm focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 sm:text-sm',
        'focus:outline-primary border-0 outline outline-1 focus:outline-2 focus:outline-offset-0',
        getStyle(props.error),
        {
          'hover:outline-2': !props.disabled,
          resize: resize === 'both',
          'resize-none': resize === 'none',
          'resize-x': resize === 'horizontal',
          'resize-y': resize === 'vertical',
        },
      )}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      required={props.required}
      readOnly={props.readonly}
    />
  </div>
);
