export enum InsurerType {
  /**
   * LAMAL/KVG insurer
   */
  SICKNESS = 1,
  /**
   * Accidents/UVG insurer
   */
  ACCIDENT = 2,
  /**
   * Invalidity/IVG insurer
   */
  INVALIDITY = 3,
  /**
   * Military/MVG insurer
   */
  MILITARY = 4,
}
