import { PickType } from '@ch-apptitude-icc/common/shared/type-utils';
import { ValidateNested } from '@ch-apptitude-icc/common/shared/validation';
import { Type } from 'class-transformer';
import { Analysis } from '../../analysis';
import { OrderTemplate } from '../../order-template';

class AnalysisIdPartial extends PickType(Analysis, ['id']) {}

export class CreateOrderTemplateDto extends PickType(OrderTemplate, ['templateName']) {
  @ValidateNested({ each: true })
  @Type(() => AnalysisIdPartial)
  analysis!: AnalysisIdPartial[];
}
