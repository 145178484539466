import { CountrySelect, Input, PhoneInput } from '@features/ui/components';
import { useTranslation } from 'next-i18next';
import { OrderFormikWrapper } from './OrderFormikWrapper';

export const CopyToDoctorForm = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:w-3/4">
      <OrderFormikWrapper name="secondDoctorData.firstname" required label={t('entities.patient.firstName')}>
        <Input type="text" />
      </OrderFormikWrapper>
      <OrderFormikWrapper name="secondDoctorData.lastname" required label={t('entities.patient.lastName')}>
        <Input type="text" />
      </OrderFormikWrapper>
      <OrderFormikWrapper name="secondDoctorData.emailAddress" label={t('entities.patient.email')}>
        <Input type="email" />
      </OrderFormikWrapper>
      <OrderFormikWrapper name="secondDoctorData.telephoneNumber" label={t('entities.patient.phone')}>
        <PhoneInput />
      </OrderFormikWrapper>
      <OrderFormikWrapper
        name="secondDoctorData.address.address1"
        required
        label={t('entities.patient.address.address1')}
      >
        <Input type="text" />
      </OrderFormikWrapper>
      <OrderFormikWrapper name="secondDoctorData.address.address2" label={t('entities.patient.address.address2')}>
        <Input type="text" />
      </OrderFormikWrapper>
      <OrderFormikWrapper
        label={`${t('entities.patient.address.zipCode')} / ${t('entities.patient.address.city')}`}
        names={['secondDoctorData.address.postcode', 'secondDoctorData.address.city']}
        required
      >
        <Input type="text" className="block w-1/3" />
        <Input type="text" className="block w-2/3" />
      </OrderFormikWrapper>
      <OrderFormikWrapper
        name="secondDoctorData.address.countryCode"
        required
        label={t('entities.patient.address.country')}
      >
        <CountrySelect />
      </OrderFormikWrapper>
    </div>
  );
};
