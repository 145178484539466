import { FindAllRequest } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { QueryClient, QueryKey } from '@tanstack/react-query';

export type QueryEntity = Entity<string | number>;
export type QueryKeys = unknown[];

export interface Keys<FrontEntity extends QueryEntity> {
  detail: (id: FrontEntity['id']) => QueryKeys;
  details: () => QueryKeys;
  list: (parameters?: FindAllRequest<FrontEntity>) => QueryKeys;
  lists: () => QueryKeys;
}

export type GenerateKeys<FrontEntity extends QueryEntity, CustomKeys> = () => Omit<
  CustomKeys,
  keyof Keys<FrontEntity>
> &
  Partial<Keys<FrontEntity>>;

const InvalidationModes = {
  invalidate:
    (rq: QueryClient) =>
    (key: QueryKey): Promise<void> =>
      rq.invalidateQueries(key),
  refetch:
    (rq: QueryClient) =>
    (key: QueryKey): Promise<void> =>
      rq.refetchQueries(key),
  reset:
    (rq: QueryClient) =>
    (key: QueryKey): Promise<void> =>
      rq.resetQueries(key),
} as const;

export type InvalidationMode = keyof typeof InvalidationModes;

export class ReactQueryKeys<FrontEntity extends QueryEntity, CustomKeys extends Keys<FrontEntity> = Keys<FrontEntity>> {
  public readonly keys: CustomKeys;

  private readonly baseKeys: Keys<FrontEntity> = {
    detail: id => ['detail', String(id)],
    details: () => ['detail'],
    list: parameters => ['list', parameters],
    lists: () => ['list'],
  };

  constructor(
    protected queryClient: QueryClient,
    protected entityName: string,
    private generateKeys?: GenerateKeys<FrontEntity, CustomKeys>,
  ) {
    this.keys = this.baseKeys as CustomKeys;
    if (this.generateKeys) {
      this.keys = {
        ...this.keys,
        ...(this.generateKeys() as CustomKeys),
      };
    }
    // Each key have a prefix with the name of the instance
    for (const key of Object.keys(this.keys) as Array<keyof Keys<FrontEntity>>) {
      const keyFn = this.keys[key] as (...param: unknown[]) => QueryKeys;
      this.keys[key] = (...a: unknown[]) => [this.entityName, ...keyFn(...a)];
    }
  }

  public invalidQuery(key: keyof CustomKeys, args?: unknown[], mode: InvalidationMode = 'invalidate'): Promise<void> {
    const keys = (this.keys[key] as (...param: unknown[]) => QueryKeys)(...(args ?? []));
    return InvalidationModes[mode](this.queryClient)(keys);
  }
}
