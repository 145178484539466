import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { type Doctor as DoctorType } from './doctor';
import { Role } from './enums/role.enum';

export class User {
  @PrimaryGeneratedColumn({ name: 'id', type: 'int' })
  id!: number;

  @Column('varchar', { length: 100 })
  name!: string;

  @Column('varchar', { length: 150, unique: true })
  username!: string;

  @Column('varchar', { length: 100 })
  email!: string;

  doctor?: DoctorType;

  @Column({ name: 'doctorId', nullable: true })
  doctorId?: number | null;

  @Column({ array: true, default: '', enum: Role, type: 'set' })
  roles?: Role[];
}
