import { DotPath } from '@ch-apptitude-icc/common/shared/type-utils';
import { OrderFormikWrapper } from '@features/orders/components/detail/order/OrderFormikWrapper';
import { CreateOrderStepOrder } from '@features/orders/types/CreateOrderStepOrder';
import { Input, CountrySelect } from '@features/ui/components';
import { useTranslation } from 'next-i18next';

export const OrderPatientForm = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <>
      <OrderFormikWrapper label={t('entities.patient.address.address1')} name="patient.address.address1" required>
        <Input type="text" />
      </OrderFormikWrapper>

      <OrderFormikWrapper label={t('entities.patient.address.address2')} name="patient.address.address2">
        <Input type="text" />
      </OrderFormikWrapper>

      <OrderFormikWrapper
        label={`${t('entities.patient.address.zipCode')} / ${t('entities.patient.address.city')}`}
        names={['patient.address.postcode', 'patient.address.city'] satisfies Array<DotPath<CreateOrderStepOrder>>}
        required
      >
        <Input type="text" className="block w-1/3" />
        <Input type="text" className="block w-2/3" />
      </OrderFormikWrapper>

      <OrderFormikWrapper label={t('entities.patient.address.country')} name="patient.address.countryCode" required>
        <CountrySelect />
      </OrderFormikWrapper>
    </>
  );
};
