import { DotPath, XOR } from '@ch-apptitude-icc/common/shared/type-utils';
import { FastField, FieldInputProps } from 'formik';
import { FastFieldProps } from 'formik/dist/FastField';
import React, { ReactNode, useContext } from 'react';
import { FormContext } from './FormikEntityWrapper';

export type FormElementWrapperProps<T = string> = {
  children: ReactNode;
  className?: string;
  error?: string;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  childrenClassName?: string;
  label?: string;
  inline?: boolean;
} & XOR<{ name: T extends string ? T : T extends object ? DotPath<T> : string }, { names: string[] }>;

/**
 * Wrapper for form elements, same as FormElementWrapper but for Formik.
 *
 * This component should not be used directly but redeclared with the injection of the `FormElementWrapper` prop.
 *
 * @see FormElementWrapper
 */
export const BaseFormikElementWrapper = <T,>({
  FormElementWrapper,
  ...props
}: FormElementWrapperProps<T> & { FormElementWrapper: React.FC<FormElementWrapperProps> }): JSX.Element => {
  const { formIsDisabled } = useContext(FormContext);

  return (
    <FormElementWrapper {...props}>
      {React.Children.map(props.children, (child, idx) => {
        if (
          React.isValidElement<
            Partial<FieldInputProps<unknown>> & { error?: boolean; required?: boolean; disabled?: boolean }
          >(child)
        ) {
          // FastField will only rerender if the disabled prop is added/removed from it
          const disableState = props.disabled === false ? false : formIsDisabled;
          const disableStateProp = disableState ? { disabled: true } : {};

          return (
            <FastField name={props.name ?? props.names![idx]} {...disableStateProp}>
              {({ field, meta }: FastFieldProps<T>) =>
                React.cloneElement(child, {
                  ...field,
                  value: field.value ?? '',
                  error: meta.touched && !!meta.error,
                  required: props.required,
                  ...disableStateProp,
                })
              }
            </FastField>
          );
        }

        return null;
      })}
    </FormElementWrapper>
  );
};
