import { OrderStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { Chip, ChipProps } from '@features/ui/components';
import { TFunction } from 'next-i18next';

export const statusToChipsVariant: Record<
  OrderStatus,
  (t: TFunction, props?: Omit<ChipProps, 'label' | 'variant'>) => JSX.Element
> = {
  [OrderStatus.ORDERED]: (t, props) => <Chip label={t('pages.orders.chips.ordered')} variant="ordered" {...props} />,
  [OrderStatus.IN_PROGRESS]: (t, props) => (
    <Chip label={t('pages.orders.chips.inProgress')} variant="inProgress" {...props} />
  ),
  [OrderStatus.DONE]: (t, props) => <Chip label={t('pages.orders.chips.done')} variant="done" {...props} />,
  [OrderStatus.TO_ORDER]: (t, props) => <Chip label={t('pages.orders.chips.todo')} variant="todo" {...props} />,
};
