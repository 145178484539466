/* eslint-disable */
import * as classValidator from 'class-validator';
import { Type } from '../type';
import 'reflect-metadata';

export function applyIsOptionalDecorator(targetClass: Function, propertyKey: string) {
  if (!isClassValidatorAvailable()) {
    return;
  }
  const decoratorFactory = classValidator.IsOptional();
  decoratorFactory(targetClass.prototype, propertyKey);
}

export function inheritTypeormMetadata(
  parentClass: Type<any>,
  targetClass: Function,
  isPropertyInherited?: (key: string) => boolean,
) {
  let storage: import('typeorm/metadata-args/MetadataArgsStorage').MetadataArgsStorage;

  try {
    let typeorm = require('typeorm');
    storage = typeorm.getMetadataArgsStorage();
  } catch (e) {
    return;
  }

  const columns = storage
    .filterColumns(parentClass)
    .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName));

  storage.columns.push(...columns.map(metadata => ({ ...metadata, target: targetClass })));
  storage.embeddeds.push(
    ...storage
      .filterEmbeddeds(parentClass)
      .map(metadata => ({ ...metadata, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  storage.generations.push(
    ...columns
      .map(gen => storage.findGenerated(parentClass, gen.propertyName))
      .filter(metadata => !!metadata)
      .map(metadata => ({ ...metadata!, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  storage.joinColumns.push(
    ...columns
      .flatMap(gen => storage.filterJoinColumns(parentClass, gen.propertyName))
      .map(metadata => ({ ...metadata, target: targetClass })),
  );

  storage.joinTables.push(
    ...columns
      .map(gen => storage.findJoinTable(parentClass, gen.propertyName))
      .filter(metadata => !!metadata)
      .map(metadata => ({ ...metadata!, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  storage.relations.push(
    ...storage
      .filterRelations(parentClass)
      .map(metadata => ({ ...metadata!, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  storage.relationCounts.push(
    ...storage
      .filterRelationCounts(parentClass)
      .map(metadata => ({ ...metadata!, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  storage.relationIds.push(
    ...storage
      .filterRelationIds(parentClass)
      .map(metadata => ({ ...metadata!, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  if (storage.findDiscriminatorValue(parentClass)) {
    storage.discriminatorValues.push({ ...storage.findDiscriminatorValue(parentClass)!, target: targetClass });
  }

  if (storage.findInheritanceType(parentClass)) {
    storage.inheritances.push({ ...storage.findInheritanceType(parentClass)!, target: targetClass });
  }

  storage.tables.push(...storage.filterTables(parentClass).map(table => ({ ...table, target: targetClass })));

  storage.entityListeners.push(
    ...storage
      .filterListeners(targetClass)
      .map(metadata => ({ ...metadata!, target: targetClass }))
      .filter(metadata => !isPropertyInherited || isPropertyInherited(metadata.propertyName)),
  );

  storage.entitySubscribers.push(
    ...storage.filterSubscribers(targetClass).map(metadata => ({ ...metadata!, target: targetClass })),
  );

  storage.checks.push(...storage.filterChecks(targetClass).map(metadata => ({ ...metadata!, target: targetClass })));
  storage.indices.push(...storage.filterIndices(targetClass).map(metadata => ({ ...metadata!, target: targetClass })));
  storage.exclusions.push(
    ...storage.filterExclusions(targetClass).map(metadata => ({ ...metadata!, target: targetClass })),
  );

  // TODO(later): uniques, trees
}

export function inheritValidationMetadata(
  parentClass: Type<any>,
  targetClass: Function,
  isPropertyInherited?: (key: string) => boolean,
) {
  if (!isClassValidatorAvailable()) {
    return;
  }
  try {
    const metadataStorage: import('class-validator').MetadataStorage = (classValidator as any).getMetadataStorage
      ? (classValidator as any).getMetadataStorage()
      : classValidator.getFromContainer(classValidator.MetadataStorage);

    const getTargetValidationMetadatasArgs = [parentClass, null!, false, false];
    const targetMetadata: ReturnType<typeof metadataStorage.getTargetValidationMetadatas> = (
      metadataStorage.getTargetValidationMetadatas as Function
    )(...getTargetValidationMetadatasArgs);

    return targetMetadata
      .filter(({ propertyName }) => !isPropertyInherited || isPropertyInherited(propertyName))
      .map(value => {
        const originalType = Reflect.getMetadata('design:type', parentClass.prototype, value.propertyName);
        if (originalType) {
          Reflect.defineMetadata('design:type', originalType, targetClass.prototype, value.propertyName);
        }

        metadataStorage.addValidationMetadata({
          ...value,
          target: targetClass,
        });
        return value.propertyName;
      });
  } catch (err) {
    console.error(`Validation ("class-validator") metadata cannot be inherited for "${parentClass.name}" class.`);
    console.error(err);
    throw err;
  }
}

type TransformMetadataKey = '_excludeMetadatas' | '_exposeMetadatas' | '_typeMetadatas' | '_transformMetadatas';

const transformMetadataKeys: TransformMetadataKey[] = [
  '_excludeMetadatas',
  '_exposeMetadatas',
  '_transformMetadatas',
  '_typeMetadatas',
];

export function inheritTransformationMetadata(
  parentClass: Type<any>,
  targetClass: Function,
  isPropertyInherited?: (key: string) => boolean,
) {
  if (!isClassTransformerAvailable()) {
    return;
  }
  try {
    transformMetadataKeys.forEach(key =>
      inheritTransformerMetadata(key, parentClass, targetClass, isPropertyInherited),
    );
  } catch (err) {
    console.error(`Transformer ("class-transformer") metadata cannot be inherited for "${parentClass.name}" class.`);
    console.error(err);
  }
}

export function getMetadataStorage(): import('class-transformer/types/MetadataStorage').MetadataStorage {
  try {
    // The browser uses the ESM storage but the backend and next server use the CJS storage
    // Only way to detect is to check if the storage is undefined.
    const res = require('class-transformer/esm5/storage').defaultMetadataStorage;
    if (!res) {
      return require('class-transformer/cjs/storage').defaultMetadataStorage;
    }
    return res;
  } catch (e) {
    return require('class-transformer/cjs/storage').defaultMetadataStorage;
  }
}

function inheritTransformerMetadata(
  key: TransformMetadataKey,
  parentClass: Type<any>,
  targetClass: Function,
  isPropertyInherited?: (key: string) => boolean,
) {
  const metadataStorage = getMetadataStorage();

  while (parentClass && parentClass !== Object) {
    if (metadataStorage[key].has(parentClass)) {
      const metadataMap = metadataStorage[key] as Map<Function, Map<string, any>>;
      const parentMetadata = metadataMap.get(parentClass);

      const targetMetadataEntries: Iterable<[string, any]> = Array.from(parentMetadata!.entries())
        .filter(([key]) => !isPropertyInherited || isPropertyInherited(key))
        .map(([key, metadata]) => {
          if (Array.isArray(metadata)) {
            // "_transformMetadatas" is an array of elements
            const targetMetadata = metadata.map(item => ({
              ...item,
              target: targetClass,
            }));
            return [key, targetMetadata];
          }
          return [key, { ...metadata, target: targetClass }];
        });

      if (metadataMap.has(targetClass)) {
        const existingRules = metadataMap.get(targetClass)!.entries();
        metadataMap.set(targetClass, new Map([...existingRules, ...targetMetadataEntries]));
      } else {
        metadataMap.set(targetClass, new Map(targetMetadataEntries));
      }
    }
    parentClass = Object.getPrototypeOf(parentClass);
  }
}

function isClassValidatorAvailable() {
  return true;
}

function isClassTransformerAvailable() {
  return true;
}

export function inheritPropertyInitializers(
  target: Record<string, any>,
  sourceClass: Type<any>,
  isPropertyInherited = (key: string) => true,
) {
  try {
    const tempInstance = new sourceClass();
    const propertyNames = Object.getOwnPropertyNames(tempInstance);

    propertyNames
      .filter(
        propertyName =>
          typeof tempInstance[propertyName] !== 'undefined' && typeof target[propertyName] === 'undefined',
      )
      .filter(propertyName => isPropertyInherited(propertyName))
      .forEach(propertyName => {
        target[propertyName] = tempInstance[propertyName];
      });
  } catch {}
}
