import { Role } from '@ch-apptitude-icc/lablink/shared/entities';
import { Button, ContentHeader, HeaderSearchBar } from '@features/ui/components';
import { useApi } from '@services/api';
import { useMediaQuery } from '@services/hooks/window/query';
import { getRoute } from '@services/routes';
import { useTranslation } from 'next-i18next';

export const OrdersContentHeader = (): JSX.Element => {
  const { t } = useTranslation('common');
  const { authApi } = useApi();

  const autoFocus = useMediaQuery('md');
  const canCreateOrder = authApi.useHasRole(Role.DOCTOR);

  const className = 'max-sm:flex-grow max-sm:shrink-0 max-sm:basis-auto';

  const rightContentHeader = (
    <div className="flex flex-row gap-3 max-sm:flex-wrap-reverse">
      <HeaderSearchBar autoFocus={autoFocus} className={className} />

      {canCreateOrder.data && (
        <Button
          className={className}
          label={t<string>('pages.orders.contentHeader.newOrder')}
          variant="primary"
          leftIcon="inbox"
          url={getRoute.orderNew().pathname}
        />
      )}
    </div>
  );

  return (
    <ContentHeader
      title={t('pages.orders.contentHeader.title')}
      upperTitle={t('common.order_other')}
      rightElement={rightContentHeader}
    />
  );
};
