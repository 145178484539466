import { cloneElement, createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { ModalName, modalsList } from './modalsList';

type Params<T extends ModalName> = Parameters<typeof modalsList[T]>[0];

type ModalContextValue = {
  closeModal: () => void;
  openModal: <T extends ModalName>(modalName: T, params?: Params<T>) => void;
};

const ModalContext = createContext<ModalContextValue | null>(null);

export const ModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [modal, setModal] = useState<ModalName | null>(null);
  const [params, setParams] = useState<Params<ModalName>>(undefined);

  const openModal = useCallback(
    <T extends ModalName>(modalName: T, modalParams?: Params<T>) => {
      setModal(modalName);
      setParams(modalParams);
    },
    [setModal],
  );

  const closeModal = useCallback(() => {
    setModal(null);
    setParams(undefined);
  }, []);

  const contextValue = useMemo(() => ({ closeModal, openModal }), [openModal, closeModal]);

  return (
    // TODO ID-200: Modal leave transition is not working
    <ModalContext.Provider value={contextValue}>
      {children}
      {/* The cast is necessary otherwise TS complains even though typing is correct */}
      {modal &&
        // @ts-expect-error bad typings here, to rewrite
        cloneElement(modalsList[modal](params as NonNullable<Params<ModalName>>), { onClose: closeModal, open: true })}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
