import { Gender } from '@ch-apptitude-icc/common/shared/entities';
import { SelectElement } from '@features/ui/components/form/Select';
import { TFunction } from 'next-i18next';

export const genderOptions: (t: TFunction) => SelectElement<string>[] = t => [
  {
    label: t('entities.patient.gender.woman'),
    value: Gender.FEMALE,
  },
  {
    label: t('entities.patient.gender.man'),
    value: Gender.MALE,
  },
];
