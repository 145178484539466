import { Icon } from '@features/ui/components';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import { Fragment, ReactNode } from 'react';

export type ModalProps = {
  body?: (onClose?: () => void) => ReactNode;
  description?: string;
  noCloseButton?: boolean;
  onClose?: () => void;
  open?: boolean;
  title?: string;
};

/**
 * Note: For the modal to work as expected if you wrap it, you need to forward the onClose prop
 */
export const Modal = ({ open, onClose, title, description, body, noCloseButton }: ModalProps): JSX.Element => {
  const isOpen = open ?? true;
  const { t } = useTranslation();

  return (
    <Transition.Root show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="z-modal relative" open={isOpen} onClose={() => (onClose ? onClose() : null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/20 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="z-modal fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden border border-gray-300 bg-gray-50 text-left shadow-xl transition-all sm:w-auto">
                {!noCloseButton && (
                  <div className="absolute top-0 right-0 pt-7 pr-7">
                    <button
                      type="button"
                      className="hover:text-primary grid h-10 w-10 place-items-center rounded-lg border border-gray-200"
                      onClick={onClose}
                    >
                      <span className="sr-only">{t('common.close')}</span>
                      <Icon icon="xmark" className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                )}
                {(title || description) && (
                  <div className="py-9 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title className="text-brand-black text-center text-2xl">{title}</Dialog.Title>
                    <Dialog.Description className="mt-2 text-sm text-gray-800">{description}</Dialog.Description>
                  </div>
                )}
                {body && body(onClose)}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
