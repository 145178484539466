import { DotPath } from '@ch-apptitude-icc/common/shared/type-utils';
import { InsurerSelect, MAX_INSURANCE_LOAD_PARAMS } from '@features/insurers/components/input';
import { OrderFormikWrapper } from '@features/orders/components/detail/order/OrderFormikWrapper';
import { selectInsurerIsThirdPartyPayer } from '@features/orders/store/OrderState/selectors';
import { setOrderExtraInsurer } from '@features/orders/store/OrderState/slice';
import { OrderOrderData } from '@features/orders/store/OrderState/types';
import { CreateOrderStepOrder } from '@features/orders/types/CreateOrderStepOrder';
import { SSNInput } from '@features/patients/components/input';
import { PhoneInput, Input, CountrySelect, FormElementWrapper, Checkbox } from '@features/ui/components';
import { useApi } from '@services/api';
import { useAppDispatch, useAppSelector } from '@services/store/hooks';
import { useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

export const InsuranceForm = (): JSX.Element => {
  const { t } = useTranslation('common');

  const { insurerApi } = useApi();

  const dispatch = useAppDispatch();
  const thirdPartyPayer = useAppSelector(selectInsurerIsThirdPartyPayer);

  const insuranceList = insurerApi.useGetList(MAX_INSURANCE_LOAD_PARAMS);

  const { values, setFieldValue } = useFormikContext<CreateOrderStepOrder>();

  useEffect(() => {
    if (insuranceList.data) {
      const insurer = insuranceList.data.results.find(value => value.id === values.patient?.insurerId);

      dispatch(setOrderExtraInsurer({ insurer }));
    }
  }, [dispatch, values.patient?.insurerId, insuranceList.data]);

  return (
    <>
      <OrderFormikWrapper label={t('entities.patient.insurance')} name="patient.insurerId" required>
        <InsurerSelect apiParams={MAX_INSURANCE_LOAD_PARAMS} />
      </OrderFormikWrapper>

      {/* We only want it when false not undefined, it avoids a slight visual glitch */}
      {thirdPartyPayer === true && (
        <>
          <OrderFormikWrapper label={t('entities.patient.avsNo')} name="patient.socialSecurityNumber" required>
            <SSNInput />
          </OrderFormikWrapper>
          <OrderFormikWrapper
            label={t('entities.patient.email')}
            name="patient.emailAddress"
            required={!values.insuranceBillingNoEmail}
          >
            <Input type="text" />
          </OrderFormikWrapper>
          <OrderFormikWrapper
            label={t('entities.patient.phone')}
            name="patient.telephoneNumber"
            required={!values.insuranceBillingNoEmail}
          >
            <PhoneInput />
          </OrderFormikWrapper>
          <FormElementWrapper name="insuranceBillingNoEmail" inline className="col-span-2">
            <Checkbox
              checked={values.insuranceBillingNoEmail ?? false}
              onChange={ev => void setFieldValue('insuranceBillingNoEmail', ev.target.checked)}
            >
              {t('pages.order.order.insuranceNoEmail')}
            </Checkbox>
          </FormElementWrapper>
          <div />
        </>
      )}

      {(thirdPartyPayer === false || values.insuranceBillingNoEmail) && (
        <>
          {/* Take space inside grid to make a line return */}
          <div />
          <OrderFormikWrapper label={t('entities.patient.address.address1')} name="patient.address.address1" required>
            <Input type="text" />
          </OrderFormikWrapper>
          <OrderFormikWrapper label={t('entities.patient.address.address2')} name="patient.address.address2">
            <Input type="text" />
          </OrderFormikWrapper>
          <OrderFormikWrapper
            label={`${t('entities.patient.address.zipCode')} / ${t('entities.patient.address.city')}`}
            names={['patient.address.postcode', 'patient.address.city'] satisfies Array<DotPath<OrderOrderData>>}
            required
          >
            <Input type="text" className="block w-1/3" />
            <Input type="text" className="block w-2/3" />
          </OrderFormikWrapper>
          <OrderFormikWrapper label={t('entities.patient.address.country')} name="patient.address.countryCode" required>
            <CountrySelect />
          </OrderFormikWrapper>
        </>
      )}
    </>
  );
};
