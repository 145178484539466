import { ClientOptions, APIClientInternals } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from '@ch-apptitude-icc/common/shared/type-utils';
import { Order, OrderCreateDto, OrderUpdateDto } from '@ch-apptitude-icc/lablink/shared/entities';

export interface OrdersClientExtensions {
  /**
   * Returns the URL of a PNG image of the barcode of an order
   *
   * @param orderId the id of the order to access
   * @param options `barWidth` controls the width of the bars of the barcode, `height` is the height in pixels of the returned barcode
   */
  getBarcodeImageUrl(orderId: Order['id'], options?: { barWidth?: number; height?: number }): string;

  /**
   * Returns the URL to download the PDF containing the barcode stickers for a given order
   *
   * @param orderId the id of the order to access
   * @param numCodes the number of barcodes to generate (defaults to 1)
   */
  getBarcodePdfUrl(orderId: Order['id'], numCodes?: number): string;

  /**
   * Returns the URL to download the companion sheet PDF for a given order
   *
   * @param orderId the id of the order to access
   */
  getOrderSheetPdfUrl(orderId: Order['id']): string;
}

const baseClient = APIClientInternals.crudClient<Order, OrderCreateDto, OrderUpdateDto>(Order, 'orders');
export const ordersClient =
  <RealEntityType extends Entity<unknown> = Order>(overrideEntity?: Type<RealEntityType>) =>
  (optionsIn?: ClientOptions<RealEntityType>) => {
    const base = baseClient(overrideEntity)(optionsIn);
    type ClientType = typeof base;
    const newBase = base as ClientType & Partial<OrdersClientExtensions>;

    newBase.getBarcodeImageUrl = (orderId: number, options: { barWidth?: number; height?: number } = {}): string => {
      const baseUrl = `${base.options?.baseURL || ''}/${base.resourcePath}/${orderId}/code.png`;
      const params = Object.entries(options ?? {}).map(([k, v]) => [k, v.toString()]);

      return `${baseUrl}?${new URLSearchParams(params).toString()}`;
    };

    newBase.getBarcodePdfUrl = (orderId: number, numCodes = 1): string =>
      `${base.options?.baseURL || ''}/${base.resourcePath}/${orderId}/code.pdf?n=${numCodes}`;

    newBase.getOrderSheetPdfUrl = (orderId: number): string =>
      `${base.options?.baseURL || ''}/${base.resourcePath}/${orderId}/sheet.pdf`;

    return newBase as ClientType & OrdersClientExtensions;
  };
