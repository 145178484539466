import { minDate, registerDecorator, ValidationOptions } from 'class-validator';
import { isFunction } from 'lodash';
import { ValidatorType } from './validators';

// eslint-disable-next-line @typescript-eslint/naming-convention
const _MinDate =
  // eslint-disable-next-line @typescript-eslint/ban-types
  (date: Date | (() => Date), validationOptions?: ValidationOptions) => (obj: Object, propName: string) =>
    registerDecorator({
      name: 'MinDate',
      target: obj.constructor,
      propertyName: propName,
      constraints: [],
      options: {
        message: () => `${propName}:MinDate;${(isFunction(date) ? date() : date).toISOString()}`,
        ...validationOptions,
      },
      validator: {
        validate(value: Date | string): boolean {
          const validateAgainst = isFunction(date) ? date() : date;
          const validateValue: Date = typeof value === 'string' ? new Date(value) : value;

          return minDate(validateValue, validateAgainst);
        },
      },
    });

export const MinDate = _MinDate as ValidatorType<typeof _MinDate>;

Object.defineProperty(MinDate, 'validatorName', { value: 'MinDate' });
