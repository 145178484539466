import { Environment } from '@common/frontend/services/api-client';
import { envConfig } from '@root/envConfig';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import {
  AnalysisApi,
  AuthApi,
  InsurerApi,
  MaterialApi,
  OrderAndRefApi,
  OrderApi,
  PatientApi,
  ReferencesApi,
} from '@services/api/Models';
import { CataloguesApi } from '@services/api/Models/CataloguesApi';
import { ContactApi } from '@services/api/Models/ContactApi';
import { OrderTemplateApi } from '@services/api/Models/OrderTemplateApi';
import { ProfaDoctorsApi } from '@services/api/Models/ProfaDoctorsApi';
import { SamplingMaterialApi } from '@services/api/Models/SamplingMaterialApi';
import { UsersApi } from '@services/api/Models/UsersApi';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react';

/**
 * The record of API services. You need to provide the class name for the API service, and it will automatically be
 * constructed by the API provider.
 */
const Apis = {
  analysisApi: AnalysisApi,
  authApi: AuthApi,
  contactApi: ContactApi,
  insurerApi: InsurerApi,
  profaDoctorsApi: ProfaDoctorsApi,
  materialApi: MaterialApi,
  samplingMaterialsApi: SamplingMaterialApi,
  orderAndRefApi: OrderAndRefApi,
  orderApi: OrderApi,
  orderTemplateApi: OrderTemplateApi,
  patientApi: PatientApi,
  referencesApi: ReferencesApi,
  usersApi: UsersApi,
  cataloguesApi: CataloguesApi,
} as const satisfies Record<
  string,
  {
    new (client: QueryClient, helper: ApiCallHelper): unknown;
  }
>;

type ApiContextType = {
  [key in keyof typeof Apis]: InstanceType<typeof Apis[key]>;
};

const ApiContext = createContext({} as ApiContextType);

Environment.CURRENT = {
  baseUrl: envConfig.url.api.clientSide,
  unboundedPageSize: envConfig.constants.api.unboundedPageSize,
};

export const ApiProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');
  const router = useRouter();

  const apiContext = useMemo(
    () => {
      const helper = new ApiCallHelper(t, router.push.bind(router));

      // Build API instances from their constructors.
      return Object.fromEntries(
        Object.entries(Apis).map(([key, Ctor]) => [key, new Ctor(queryClient, helper)]),
      ) as ApiContextType;
    },
    // No need to re-calculate when router changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, queryClient],
  );

  return <ApiContext.Provider value={apiContext}>{children}</ApiContext.Provider>;
};

export const useApi: () => ApiContextType = () => useContext(ApiContext);
