import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@services/store/store';

type PageState = {
  searchString?: string;
};

const initialState: PageState = {
  searchString: '',
};

export const pageStateSlice = createSlice({
  name: 'pageState',
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string | undefined>) => {
      state.searchString = action.payload;
    },
  },
});

export const { setSearchString } = pageStateSlice.actions;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const selectSearchString = (state: RootState) => state.pageState.searchString;
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
