import { DotPath } from '@ch-apptitude-icc/common/shared/type-utils';
import { OrderFormikWrapper } from '@features/orders/components/detail/order/OrderFormikWrapper';
import { OrderOrderData } from '@features/orders/store/OrderState/types';
import { Input, CountrySelect } from '@features/ui/components';
import { useTranslation } from 'next-i18next';

export const OtherForm = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <>
      <OrderFormikWrapper label={t('entities.patient.lastName')} name="invoicing.lastname" required>
        <Input type="text" />
      </OrderFormikWrapper>

      <OrderFormikWrapper label={t('entities.patient.firstName')} name="invoicing.firstname" required>
        <Input type="text" />
      </OrderFormikWrapper>

      <OrderFormikWrapper label={t('entities.patient.address.address1')} name="invoicing.address1" required>
        <Input type="text" />
      </OrderFormikWrapper>

      <OrderFormikWrapper label={t('entities.patient.address.address2')} name="invoicing.address2">
        <Input type="text" />
      </OrderFormikWrapper>

      <OrderFormikWrapper
        label={`${t('entities.patient.address.zipCode')} / ${t('entities.patient.address.city')}`}
        names={['invoicing.postcode', 'invoicing.city'] satisfies Array<DotPath<OrderOrderData>>}
        required
      >
        <Input type="text" className="block w-1/3" />
        <Input type="text" className="block w-2/3" />
      </OrderFormikWrapper>

      <OrderFormikWrapper label={t('entities.patient.address.country')} name="invoicing.countryCode" required>
        <CountrySelect />
      </OrderFormikWrapper>
    </>
  );
};
