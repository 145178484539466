import { PickType, staticImplements } from '@ch-apptitude-icc/common/shared/type-utils';
import { OrderSamplingStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { CreateOrderFront } from '@features/orders/types/CreateOrderFront';
import { CreateOrderFrontStep } from '@features/orders/types/CreateOrderFrontStep';

@staticImplements<CreateOrderFrontStep<CreateOrderStepMaterials>>()
export class CreateOrderStepMaterials extends PickType(CreateOrderFront, [
  'otherMaterial',
  'sampleDate',
  'samplingStatus',
  'status',
  'summonClient',
]) {
  public static from(dto: CreateOrderFront): CreateOrderStepMaterials {
    return {
      otherMaterial: dto.otherMaterial,
      sampleDate: dto.sampleDate,
      samplingStatus: dto.samplingStatus,
      status: dto.status,
      summonClient: dto.summonClient,
    };
  }

  public static mergeTo(source: CreateOrderStepMaterials, dto: CreateOrderFront): CreateOrderFront {
    return {
      ...dto,
      otherMaterial: source.otherMaterial,
      samplingStatus: source.samplingStatus,
      summonClient: source.summonClient,

      // If the sampling status is not done, we want to clear any (possibly invalid) sampling date
      sampleDate:
        source.samplingStatus === OrderSamplingStatus.SAMPLING_DONE ||
        source.samplingStatus === OrderSamplingStatus.SAMPLE_LATER
          ? source.sampleDate
          : undefined,

      // Intentional: don't merge the status back, as we don't want to mark an order as "submitted" locally if the
      // update bounces from the server!
      // status: source.status,
    };
  }
}
