/* Define your feature flags here */
export enum FeatureFlags {
  AUTOSYNC_MANDATORS = 'autosync_mandators',
  MANDATORS_PATIENT_SUMMON = 'mandators_patient_summon',
  ALLOW_MULTIPLE_PRICINGS = 'allow_multiple_pricings',
  READ_HL7_REPORTS = 'read_hl7_reports',
  ANONIMIZE = 'DANGEROUS-NEVER-IN-PRODUCTION__ANONIMIZE-DATA',
  LABLINK_RATELIMIT = 'RATELIMIT',
  AM_FILE_VIEW = 'am_file_view',
  AM_REPORTS = 'am_reports',
}

/* Define enabled feature flags here */
const enabledFlags: Partial<Record<FeatureFlags, EnabledFlag>> = {
  [FeatureFlags.ANONIMIZE]: { upToIncluding: 'preprod' },
  [FeatureFlags.LABLINK_RATELIMIT]: { exact: 'prod' },

  [FeatureFlags.AUTOSYNC_MANDATORS]: { upToIncluding: 'prod' },
  [FeatureFlags.AM_FILE_VIEW]: { upToIncluding: 'prod' },

  [FeatureFlags.READ_HL7_REPORTS]: { upToIncluding: 'preprod' },
  [FeatureFlags.AM_REPORTS]: { upToIncluding: 'preprod' },

  // LVI: disabled in June 2023 for ~1-2 months.
  // As half the team is going on vacation for 1 month, we don't want to release this potentially risky feature yet,
  // so we have locked it behind a flag
  // [FeatureFlags.ALLOW_MULTIPLE_PRICINGS]: { upToIncluding: 'prod' },
};

type EnabledFlag =
  | {
      /**
       * Enable a flag for this environment only
       */
      exact: Environment;
    }
  | {
      /**
       * Enable a flag for this environment and all the others before it.
       *
       * Environments ordering : FullLocal < Local < dev < Preprod < Prod
       */
      upToIncluding: Environment;
    };

const environments = { fulllocal: 0, local: 1, dev: 2, preprod: 3, prod: 4 } as const;
export type Environment = keyof typeof environments;

const currentEnv = ((process.env.NEXT_PUBLIC_SERVER_ENVIRONMENT || process.env.SERVER_ENV) as Environment) || 'local';

export function isFeatureEnabled(flag: FeatureFlags, environment?: Environment): boolean {
  const env = environment || currentEnv;
  const enablement = enabledFlags[flag];

  if (enablement) {
    if ('exact' in enablement) {
      return enablement.exact === env;
    }

    if ('upToIncluding' in enablement) {
      return environments[env] <= environments[enablement.upToIncluding];
    }
  }

  return false;
}
