import { IsDefined } from '@ch-apptitude-icc/common/shared/validation';
import { OrderCreateDto, OrderSamplingStatus, OrderStatus, Patient } from '@ch-apptitude-icc/lablink/shared/entities';
import { convertErrorsToFormik, FormikEntityWrapper } from '@common/frontend/components/ui-forms/FormikEntityWrapper';
import { OnError, OnSuccessMutation } from '@common/frontend/services/api-client';
import { MaterialDateTimeInput } from '@features/orders/components/detail/material';
import { useMaterialsList } from '@features/orders/services/useMaterialsList';
import { selectData, selectFromStep, selectOrderId } from '@features/orders/store/OrderState/selectors';
import {
  setOrderData,
  setOrderJustCreated,
  setOrderStepData,
  setOrderStepStatus,
  setServerErrors,
} from '@features/orders/store/OrderState/slice';
import { OrderMaterialData } from '@features/orders/store/OrderState/types';
import { createOrderFromOrder } from '@features/orders/types/createOrderFromOrder';
import { CreateOrderStepMaterials } from '@features/orders/types/CreateOrderStepMaterials';
import { ButtonTabs, Checkbox, FormikElementWrapper, LineCard, TextArea, Toast } from '@features/ui/components';
import { Spinner } from '@features/ui/components/Spinner';
import { envConfig, SERVER_ENVIRONMENTS } from '@root/envConfig';
import { OrderFront, useApi } from '@services/api';
import { useFormatters } from '@services/formatters';
import { getRoute } from '@services/routes';
import { useAppDispatch, useAppSelector } from '@services/store/hooks';
import { getTWBreakpoint } from '@utils/tailwind';
import { addHours, format, subDays } from 'date-fns';
import { FastField, Form, FormikProps, useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OrderCard } from './OrderCard';

const useOrderSubmission = (setSubmitting?: FormikProps<CreateOrderStepMaterials>['setSubmitting']) => {
  const { orderApi } = useApi();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const orderData = useAppSelector(selectData);
  const existingOrderId = useAppSelector(selectOrderId);

  const onError: OnError = useCallback(
    (error, next) => {
      setSubmitting?.(false);
      if (error.response?.status === 400) {
        const data = error.response.data as { message: string | string[] };

        if (Array.isArray(data.message)) {
          dispatch(setServerErrors(data.message));
        } else if (envConfig.environment !== SERVER_ENVIRONMENTS.prod) {
          // eslint-disable-next-line no-console
          console.error('Could not decode the message', data);
        }
      }

      next();
    },
    [setSubmitting, dispatch],
  );

  const onSuccess: OnSuccessMutation<OrderFront> = useCallback(
    data => {
      dispatch(setOrderJustCreated(+data.id));

      if (orderData && existingOrderId) {
        setSubmitting?.(false);
        dispatch(
          setOrderData({
            ...createOrderFromOrder(data),
            existing: { id: +data.id, requestDate: data.requestDate ?? undefined, order: data },
          }),
        );
      } else {
        router.replace(getRoute.orderDetail({ id: data.id })).catch(() => undefined);
      }
    },
    [setSubmitting, dispatch, orderData, existingOrderId, router],
  );

  const addOne = orderApi.useAddOne({ onError, onSuccess });
  const updateOne = orderApi.useUpdateOne(undefined, { onError, onSuccess });

  const submit = useCallback(
    (updateStatus?: OrderStatus) => {
      setSubmitting?.(true);
      if (orderData) {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- ID-1507: to not change the DTO, the data is cleaned before sending
        const { disabled: _, ...patient } = (orderData.patient ?? {}) as Patient;
        const toSend: OrderCreateDto = {
          ...orderData,
          patient,
          status: updateStatus ?? orderData.status,
        };

        if (existingOrderId) {
          // @ts-expect-error FIXME orderData id is not of the correct type
          updateOne.mutate({ id: existingOrderId, ...toSend });
        } else {
          addOne.mutate(toSend);
        }
      }
    },
    [addOne, existingOrderId, orderData, updateOne, setSubmitting],
  );

  return submit;
};

export const OrderMaterials = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const matData = useAppSelector(selectFromStep.material.selectData);
  console.log(matData);
  const serverErrors = useAppSelector(selectFromStep.material.selectErrors);
  const serverErrorsAsFormik = convertErrorsToFormik(serverErrors, t);

  const formikRef = useRef<FormikProps<CreateOrderStepMaterials>>(null);
  const sendToServer = useOrderSubmission(formikRef.current?.setSubmitting ?? undefined);
  const [submitNextRender, setSubmitNextRender] = useState<OrderStatus | false>(false);

  const requestedMaterials = useMaterialsList();
  const requiresComment = requestedMaterials?.some(mat => mat.material.requiredComment);

  useEffect(() => {
    if (submitNextRender !== false) {
      setSubmitNextRender(false);
      sendToServer(submitNextRender);
    }
  }, [sendToServer, submitNextRender]);

  return (
    <FormikEntityWrapper<CreateOrderStepMaterials>
      innerRef={formikRef}
      type={CreateOrderStepMaterials}
      initialValues={matData}
      initialErrors={serverErrorsAsFormik}
      initialTouched={serverErrorsAsFormik}
      validate={v => {
        const errors = [...OrderCreateDto.validateFinalStep(v)];
        if (requiresComment && (!v.otherMaterial || !v.otherMaterial.trim())) {
          errors.push(`otherMaterial:${IsDefined.validatorName}`);
        }
        return errors;
      }}
      onSubmit={values => {
        const { status } = values;
        dispatch(setOrderStepData(values));
        dispatch(setOrderStepStatus({ key: 'material', status: 'done' }));

        // We need to delay the call because the submission code reads state from Redux, which is not up to date
        // yet when we reach this call.
        setSubmitNextRender(status);
      }}
      enableReinitialize
    >
      <Form id="material">
        <OrderCard icon="prescriptionBottle" stepKey="material" title={t('pages.order.materials.title')}>
          <OrderMaterialsContent />
        </OrderCard>
      </Form>
    </FormikEntityWrapper>
  );
};

function OrderMaterialsContent(): JSX.Element {
  const { fDate, fTime } = useFormatters();

  const isEditing = useAppSelector(selectFromStep.material.selectIsEditing);

  const { values: matData, setFieldValue } = useFormikContext<CreateOrderStepMaterials>();

  // Required, otherwise the tab container changes everytime the component is rendered and this causes the entire
  //  options group to be rerendered
  const tabContainer = useMemo(() => <div className="pt-4" />, []);
  const { t } = useTranslation('common');

  const sampleDate = matData.sampleDate ? new Date(matData.sampleDate) : undefined;

  const requestedMaterials = useMaterialsList();

  if (!requestedMaterials) {
    return <Spinner />;
  }

  const requiresComment = requestedMaterials.some(mat => mat.material.requiredComment);

  return (
    <div className="flex flex-col gap-4">
      {isEditing && <Trans i18nKey="pages.order.materials.subtitle" t={t} />}

      <div className="flex flex-col gap-2 lg:w-1/2">
        {requestedMaterials ? (
          requestedMaterials.map(material => (
            <LineCard
              key={material.material.id}
              left={<span className="font-semibold text-blue-500">{material.amount}</span>}
              main={`${material.material.name}${
                material.material.tubeColor ? ` (${material.material.tubeColor})` : ''
              }`}
            />
          ))
        ) : (
          <Spinner />
        )}
      </div>

      {isEditing ? (
        <>
          <div className="mt-8">
            <ButtonTabs
              btnGroup={{ breakpointWidth: getTWBreakpoint('lg', { asPx: true }) }}
              container={tabContainer}
              selected={matData.samplingStatus || OrderSamplingStatus.SAMPLING_DONE}
              onChange={samplingStatus => void setFieldValue('samplingStatus', samplingStatus)}
              tabs={[
                {
                  button: { label: t('pages.order.materials.sampling.tab-title.done'), leftIcon: 'prescriptionBottle' },
                  component: (
                    <FastField name="sampleDate">
                      {() => (
                        <MaterialDateTimeInput
                          min={format(subDays(new Date(), 4), 'yyyy-MM-dd')}
                          max={format(addHours(new Date(), 8), 'yyyy-MM-dd')}
                          readonly={!isEditing}
                          name="sampleDate"
                          required={matData.samplingStatus === OrderSamplingStatus.SAMPLING_DONE}
                        />
                      )}
                    </FastField>
                  ),
                  tabId: OrderSamplingStatus.SAMPLING_DONE,
                },
                {
                  button: { label: t('pages.order.materials.sampling.tab-title.later'), leftIcon: 'timer' },
                  component: (
                    <>
                      <Toast
                        title={t('pages.order.materials.sampling.later-msg', { status: t('pages.orders.chips.todo') })}
                        variant="info"
                      />
                      <FastField name="sampleDate">
                        {() => <MaterialDateTimeInput readonly={!isEditing} name="sampleDate" />}
                      </FastField>
                    </>
                  ),
                  tabId: OrderSamplingStatus.SAMPLE_LATER,
                },
                {
                  button: {
                    label: t('pages.order.materials.sampling.tab-title.at-icc'),
                    leftIcon: 'ellipsisHorizontal',
                  },
                  component: (
                    <FormikElementWrapper<OrderMaterialData> className="lg:w-1/2" name="summonClient">
                      <Checkbox name="summonClient">{t('pages.order.materials.sampling.summon-client')}</Checkbox>
                    </FormikElementWrapper>
                  ),
                  tabId: OrderSamplingStatus.SAMPLE_AT_LAB,
                },
              ]}
            />
          </div>

          {requiresComment && (
            <Toast
              title={t('pages.order.materials.required-remark.title')}
              variant="info"
              message={t('pages.order.materials.required-remark.message', {
                materials: requestedMaterials
                  .filter(mat => mat.material.requiredComment)
                  .map(mat => mat.material.name)
                  .join(', '),
              })}
            />
          )}

          <FormikElementWrapper<OrderMaterialData>
            className="lg:w-1/2"
            label={t('entities.order.otherMaterial')}
            name="otherMaterial"
            required={requiresComment}
          >
            <TextArea resize="vertical" />
          </FormikElementWrapper>
        </>
      ) : (
        <>
          {matData.samplingStatus === OrderSamplingStatus.SAMPLING_DONE && sampleDate && (
            <span>
              <Trans
                i18nKey="pages.order.materials.sampling.sampled-on"
                t={t}
                values={{
                  date: fDate(sampleDate),
                  time: fTime(sampleDate),
                }}
              />
            </span>
          )}
          {matData.samplingStatus === OrderSamplingStatus.SAMPLE_LATER && (
            <span>{t('pages.order.materials.sampling.tab-title.later')}</span>
          )}
          {matData.samplingStatus === OrderSamplingStatus.SAMPLE_AT_LAB && (
            <span>{t('pages.order.materials.sampling.tab-title.at-icc')}</span>
          )}
          {matData.summonClient && <span>{t('pages.order.materials.sampling.summon-client')}</span>}
          {matData.otherMaterial && (
            <span>
              {t('entities.order.otherMaterial')}: &quot;{matData.otherMaterial}&quot;
            </span>
          )}
        </>
      )}
    </div>
  );
}
