export enum Software {
  LAVIGNY = 'Instlav',
  ACHILES = 'Achiles',
  MEDIWAY = 'Mediway',
  DATAMED = 'Datamed',

  SYNLAB = 'Synlab',
  PROMED = 'Promed',
}

export const Softwares: Software[] = [
  Software.LAVIGNY,
  Software.ACHILES,
  Software.DATAMED,
  Software.MEDIWAY,
  Software.PROMED,
  Software.SYNLAB,
];
