// @ts-check

/** @type { import('./url.config').SingleUrlConfig } */
const defaultUrlConfig = {
  api: {
    clientSide: '/api',
    serverSide: '/api',
  },
  base: 'http://localhost:4200/',
};

/** @type { import("./url.config").UrlConfig }  */
const urlConfig = {
  local: {
    api: {
      clientSide: 'https://icc-dev.appti.ch/api',
      serverSide: 'https://icc-dev.appti.ch/api',
    },
    base: 'http://localhost:4200/',
  },
  fulllocal: {
    base: 'http://localhost:4200/',
    api: {
      clientSide: 'http://localhost:3333/api',
      serverSide: 'http://localhost:3333/api',
    },
  },
  dev: { ...defaultUrlConfig, base: 'https://icc-dev.appti.ch/' },
  preprod: { ...defaultUrlConfig, base: 'https://icc-preprod.appti.ch/' },
  prod: { ...defaultUrlConfig, base: 'https://lablink.laboicc.ch/' },
};

module.exports = urlConfig;
