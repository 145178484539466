import { PatientFieldsInterface } from '@ch-apptitude-icc/common/shared/entities';
import { IsNumber, IsOptional, Length, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Column } from 'typeorm';
import { PatientBase } from '../embeded';

export class OrderPatientFields extends PatientBase implements PatientFieldsInterface {
  /** ID got from AM sync */
  @Column('int', { name: 'fkPatient', nullable: true })
  @IsNumber()
  @IsOptional()
  existingPatientId?: number | null;

  /**
   * External patient reference, new field, not used by ICC yet
   */
  @Column('varchar', { length: 20, name: 'externalPatientReference', nullable: true })
  @IsOptional()
  @Length(2, 20)
  externalReference?: string | null;

  /**
   * Contains the GLN of the insurance company (see for example https://www.medidata.ch/md/cms/system/modules/com.cgx.medidata.web/elements/versichererliste_fr.pdf%3Fl%3Dfr%26c%3Da%26pt%3Dmediport_arzt%26vn%3Dmediport)
   *
   * This is duplicated data, also present in the `Assurances` table reachable using the `insurer` field.
   */
  @Column('varchar', { length: 20, name: 'EANassurance', nullable: true })
  @MaxLength(20)
  @IsOptional()
  insurerGLN?: string | null;
}
