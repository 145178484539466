import { Message } from 'yup';

type MessageParams = {
  path: string;
};

export const yupErrorMessage =
  <T extends Record<string, unknown>>(
    validatorName: string,
    addArgs?: (ctx: MessageParams & T) => string[],
  ): Message<T> =>
  (ctx: MessageParams & T) =>
    [`${ctx.path}:${validatorName}`, ...(addArgs ? addArgs(ctx) : [])].join(';');
