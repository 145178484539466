import { AnalysisSectorGroup, AnalysisSectorGroups } from '@features/analyses/utils';
import { ButtonGroup, ButtonGroupButton, ButtonGroupProps } from '@features/ui/components';
import { useTranslation } from 'next-i18next';

const favorites = {
  icon: 'star',
  i18nKey: 'pages.order.analysis.favorites',
  sectors: [],
} satisfies AnalysisSectorGroup;

export const Tabs = {
  favorites,
  ...AnalysisSectorGroups,
} as const;

export const SmallTabs = {
  favorites,
  all: {
    icon: 'flask',
    i18nKey: 'pages.order.analysis.all',
    sectors: [],
  } satisfies AnalysisSectorGroup,
};

export type AnalysisTabID = keyof typeof Tabs | keyof typeof SmallTabs;

export type ButtonSectorGroupsProps = Pick<ButtonGroupProps<number>, 'breakpointWidth' | 'className'> & {
  onChange?: (sectorGroup: AnalysisTabID) => void;
  selected?: AnalysisTabID;
  small: boolean;
};

export const ButtonAnalysisGroups = (props: ButtonSectorGroupsProps): JSX.Element => {
  const { t } = useTranslation();

  const { onChange, selected, small, ...bgProps } = props;

  const buttons = Object.entries(small ? SmallTabs : Tabs).map<ButtonGroupButton<AnalysisTabID>>(([key, value]) => ({
    btnId: key as AnalysisTabID,
    label: t(value.i18nKey),
    leftIcon: value.icon,
  }));

  return (
    <ButtonGroup<AnalysisTabID>
      buttons={buttons}
      onChange={btnId => {
        if (onChange) {
          onChange(btnId);
        }
      }}
      selected={selected ?? false}
      {...bgProps}
    />
  );
};
