export enum AnalysisType {
  GENERAL = 1,
  BACTERIOLOGY = 5,
  ANTIBIOTICS = 7,
  ESSENTIAL_OILS = 9,
  DRUGS = 11,
  MEDICINES = 13,

  GROUP_GENERAL = 2,
  GROUP_BACTERIOLOGY_GROUP = 6,
  GROUP_ANTIBIOTICS = 8,
  GROUP_ESSENTIAL_OILS = 10,
  GROUP_DRUGS = 12,
  GROUP_MEDICINES = 14,

  GROUP_PERS = 18,

  __UNUSED_0 = 0,
  __UNUSED_3 = 3,
  __UNUSED__BACTERIOLOGY_1 = 4,

  __RESERVED_15 = 15,
  __RESERVED_GROUP_16 = 16,

  __LEGACY_BILLING = 17,
  __LEGACY_CALUX = 19,
  __LEGACY_GROUP_CALUX = 20,
}

/**
 * The values of the enum
 *
 * `Object.values` also includes the keys ...
 */
export const ANALYSIS_TYPES = Object.values(AnalysisType).filter(v => typeof v === 'number') as readonly number[];

export function isGroup(analysisType?: AnalysisType) {
  /*
  From legacy code:

Public Function InGroup(TypAna As Integer) As Boolean
    InGroup = False
    If TypAna = 2 Or TypAna = 6 Or TypAna = 8 Or TypAna = 10 Or TypAna = 12 Or TypAna = 14 Or TypAna = 16 Or TypAna = 18 Or TypAna = 20 Then InGroup = True
End Function
 */

  return analysisType && analysisType % 2 === 0 && analysisType !== 4;
}
