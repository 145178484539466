import { SortDirection } from '@ch-apptitude-icc/common/shared/api-client';
import { Icon } from '@features/ui/components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export type TableSort = { direction: SortDirection; id: string };

export type SortableHeaderProps = {
  className?: string;
  currentSort: TableSort | null;
  label: string;
  onSort: (sort: TableSort | null) => void;
  selector: string;
};

export const SortableHeader = ({
  label,
  className,
  onSort,
  selector: id,
  currentSort,
}: SortableHeaderProps): JSX.Element => {
  const [direction, setDirection] = useState<SortDirection | null>(null);

  useEffect(() => {
    setDirection(currentSort?.id === id ? currentSort.direction : null);
  }, [currentSort, id]);

  const changeDirection = () => {
    if (direction === 'ASC') {
      onSort({ direction: 'DESC', id });
    } else if (direction === 'DESC') {
      onSort(null);
    } else {
      onSort({ direction: 'ASC', id });
    }
  };

  return (
    <div className={className}>
      <span
        className={classNames('cursor-pointer pr-2 hover:font-bold hover:underline', {
          'font-bold ': currentSort?.id === id,
        })}
        onClick={changeDirection}
        role="rowheader"
        tabIndex={-1}
      >
        {label}
      </span>
      {direction && <Icon icon={direction === 'ASC' ? 'sortUp' : 'sortDown'} />}
    </div>
  );
};
