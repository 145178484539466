/* eslint-disable react-hooks/rules-of-hooks */
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { OmitType } from '@ch-apptitude-icc/common/shared/type-utils';
import { AuthClient, clients } from '@ch-apptitude-icc/lablink/shared/client';
import { AuthUserConnected, ENDPOINTS, Role, User } from '@ch-apptitude-icc/lablink/shared/entities';
import {
  ApiFactory,
  Keys,
  QueryKeys,
  UseGetOptions,
  UseMutationOptions,
  APIDetailResponse,
} from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { clearUrlFromExtraQueryParams } from '@services/routes';
import { QueryClient, useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Transform, TransformationType } from 'class-transformer';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'node:querystring';
import qs, { ParsedQs } from 'qs';

export class UserFront extends OmitType(User, ['id']) implements Entity<string> {
  @Transform(({ value, type }) => {
    if (type === TransformationType.CLASS_TO_PLAIN) {
      return Number(value);
    }
    return String(value);
  })
  id!: string;
}

export type HasRole = Omit<UseQueryResult<APIDetailResponse<UserFront>, AxiosError>, 'data'> & { data: boolean };

interface CustomKeys extends Keys<UserFront> {
  hasRole: (role: Role) => QueryKeys;
  me: () => QueryKeys;
}

const api = clients.auth(UserFront);

export class AuthApi extends ApiFactory<UserFront, typeof api, CustomKeys, AuthClient<UserFront>>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    const customCatalogSetKey = () => ({
      me: () => [ENDPOINTS.auth.impersonate],
      hasRole: (role: Role) => [ENDPOINTS.auth.impersonate, 'hasRole', role as string],
    });

    super(queryClient, helper, api, customCatalogSetKey);
  }

  public useLogin(
    options?: UseMutationOptions<APIDetailResponse<UserFront>>,
  ): UseMutationResult<
    AxiosResponse<UserFront>,
    AxiosError,
    { password: string; rememberMe?: boolean; username: string }
  > {
    return useMutation({
      mutationFn: ({ rememberMe, username, password }) => this.api.login(username, password, rememberMe ?? true),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: data =>
        this.apiCallHelper.onSuccessMutation(
          data,
          async () => {
            await this.rqKeys.invalidQuery('me');
          },
          options?.onSuccess,
        ),
      retry: false,
    });
  }

  public useLogout(
    options?: UseMutationOptions<undefined>,
  ): UseMutationResult<AxiosResponse<unknown>, AxiosError, undefined> {
    return useMutation({
      mutationFn: () => this.api.logout(),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: () =>
        this.rqKeys.invalidQuery('me').then(() => options?.onSuccess && options.onSuccess(undefined, async () => {})),
      retry: false,
    });
  }

  public useGetMe<T = UserFront>(
    options?: UseGetOptions<APIDetailResponse<T>>,
    selector: (data: AuthUserConnected) => T = ({ user }) => user as T,
  ): UseQueryResult<APIDetailResponse<T>, AxiosError> {
    const router = useRouter();
    const { api: client } = this;

    async function removeNonInteractiveLoginMaterialFromUrl() {
      let { query }: { query: ParsedQs } = router;
      if (router.asPath.includes('?') && Object.entries(router.query).length === 0) {
        query = qs.parse(router.asPath.split('?')[1]);
      }

      await router.replace(
        clearUrlFromExtraQueryParams(
          {
            pathname: router.pathname,
            query: query as ParsedUrlQueryInput,
          },
          ['username', 'auth'],
        ),
      );
    }

    async function queryFn({ signal }: { signal?: AbortSignal }) {
      // Sometimes NextJS does not parse our parameters somehow
      let { query }: { query: ParsedQs } = router;
      if (router.asPath.includes('?') && Object.entries(router.query).length === 0) {
        query = qs.parse(router.asPath.split('?')[1]);
      }

      const { auth, username } = query; // as { auth?: string; username?: string };
      if (!auth || !username || typeof auth !== 'string' || typeof username !== 'string') {
        // No non-interactive auth params present, standard request
        return client.me({ signal });
      }

      // We clear the URL parameters first to avoid sending a new auth attempt every time.
      await removeNonInteractiveLoginMaterialFromUrl();
      await client.nonInteractiveLogin(username, auth, { signal }).catch(loginError => {
        // [ID-1619] This was set in the previous code
        // do not known if it can fail the current flow, so it was kept
        console.error('Non interactive login failed.', loginError);
      });

      return client.me({ signal });
    }

    return useQuery({
      enabled: options?.enabled ?? true,
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: data => options?.onSuccess && options?.onSuccess(data),
      queryFn: params => queryFn(params).then(_ => _.data),

      queryKey: this.rqKeys.keys.me(),
      retry: false,

      // Why `as never` ? It totally breaks the type inference for the others options
      select: selector as never,
    });
  }

  public useHasRole(role: Role): UseQueryResult<APIDetailResponse<boolean>, AxiosError> {
    return this.useGetMe(undefined, ({ user }) => user?.roles?.includes(role) ?? false);
  }
}
