import { ValidationOptions } from 'class-validator';
import { ValidateIf } from './validators';

export const IsOptionalIf = <T extends object = object, U = unknown>(
  predicate: (obj: T, value: U) => boolean,
  validationOptions?: ValidationOptions,
) =>
  ValidateIf((obj, value) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const isOptional = predicate(obj, value);

    if (isOptional) {
      // Value is optional, we only validate it if it's not null
      return value != null;
    }
    return true;
  }, validationOptions);
