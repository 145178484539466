import { OrderStatus } from '@ch-apptitude-icc/common/shared/entities';
import { OmitType, PartialType } from '@ch-apptitude-icc/common/shared/type-utils';
import { IsEnum, IsObject, IsOptional, ValidateNested } from '@ch-apptitude-icc/common/shared/validation';
import { plainToInstance, Transform, Type } from 'class-transformer';
import { OrderCreateDto } from './order-create.dto';
import { errors } from '../../../errors';
import { OrderPatientFields } from '../order-patient.fields';

class PartialPatientFields extends PartialType(OrderPatientFields) {}

export class OrderUpdateDto extends PartialType(OmitType(OrderCreateDto, ['patient', 'status'])) {
  @Type(() => PartialPatientFields)
  @ValidateNested({ errorName: errors.orders.validation.requirePatient })
  @IsObject({ errorName: errors.orders.validation.requirePatient })
  @Transform(({ value }) =>
    value
      ? plainToInstance(PartialPatientFields, OrderPatientFields.nullifyIfEmpty(value as OrderPatientFields))
      : undefined,
  )
  @IsOptional()
  patient?: PartialPatientFields;

  @IsEnum(OrderStatus)
  @IsOptional()
  status!: OrderStatus;
}
