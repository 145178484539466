import { Order } from '@ch-apptitude-icc/lablink/shared/entities';
import { useApi } from '@services/api';
import { useTranslation } from 'next-i18next';

export function OrderBarCode({ orderId }: { orderId: Order['id'] }): JSX.Element {
  const { t } = useTranslation('common');
  const { orderApi } = useApi();

  return (
    <img
      src={orderApi.getBarcodeImageUrl(orderId, { height: 32, barWidth: 2 })}
      alt={t('pages.order.patient.barcodeAlt')}
    />
  );
}
