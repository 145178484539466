import { PatientFront } from '@services/api';
import { UrlObject } from 'url';

export const routeRegex = {
  order: /(^\/$)|(^\/orders.*)/,
  patient: /^\/patients.*/,
  resources: /^\/resources.*/,
  contacts: /^\/contacts.*/,
};

export const QUERY_ENUM = {
  modal: 'modal',
  modalId: 'modalId',
  modalParamOne: 'modalParamOne',
  patientId: 'patientId',
} as const;

type RoutesConfig = Readonly<{ [key: string]: (..._: never[]) => UrlObject | RoutesConfig }>;

// Helper function to force types without loosing keys inference
function makeGetRoute<T extends RoutesConfig>(routes: T): T {
  return routes;
}

export type LoginRouteQuery = {
  redirectUrl?: string;
};
export type LoginRouteParams = {
  query?: LoginRouteQuery;
};

export type OrderRouteParams = {
  id: string | number;
};

export type OrderNewRouteQuery = {
  patientId?: string;
};

export type OrderNewRouteParams = {
  query?: OrderNewRouteQuery;
};

export const getRoute = makeGetRoute({
  error: () => ({ pathname: '/500' }),
  down: () => ({ pathname: '/down' }),
  home: () => ({ pathname: '/' }),
  passwordForget: () => ({ pathname: '/password-forget' }),
  login: (params?: LoginRouteParams) => ({ pathname: '/login', query: params?.query }),
  loginForRequiredAuthPage: (redirectUrl: string) =>
    clearUrlFromExtraQueryParams(
      {
        pathname: '/',
        query: {
          [QUERY_ENUM.modalParamOne]: redirectUrl,
        },
      },
      [QUERY_ENUM.modalId],
    ),
  orderDetail: (params: OrderRouteParams) => ({ pathname: `/orders/${params.id}` }),
  orderNew: (params?: OrderNewRouteParams) => ({ pathname: '/orders/new', query: params?.query }),
  patients: () => ({ pathname: '/patients' }),
  patientDetail: (patientId: PatientFront['id']) => ({ pathname: `/patients/${patientId}` }),
  resources: () => ({ pathname: '/resources' }),
  contacts: () => ({ pathname: '/contacts' }),
  settings: () => ({ pathname: '/settings' }),
} as const);

export function clearUrlFromExtraQueryParams(queryAndPath: UrlObject, toDelete: Array<string>): UrlObject {
  const newQueryAndPath = queryAndPath;
  toDelete.forEach(key => {
    // @ts-expect-error since we're in a separate block ts thinks this can be null, but it cannot
    delete newQueryAndPath.query[key];
  });

  return newQueryAndPath;
}

// !!! WARNING !!!
// This function don't work if you use it with a route which contains
// dynamic routing like /event/[eventSlug]/...
export function buildAsUrl(url: UrlObject): string {
  let path = url.pathname;
  if (url.query && path) {
    path += '?';
    const queryList = Object.keys(url.query);
    queryList.forEach((key, index) => {
      // @ts-expect-error since we're in a separate block ts thinks url.query can be null, but it cannot
      path += `${key}=${encodeURIComponent(String(url.query[key]))}`;
      if (index !== queryList.length - 1) {
        path += '&';
      }
    });
  }
  return path ?? '';
}
