import { OrderCreateDto, OrderSamplingStatus, OrderStatus, Role } from '@ch-apptitude-icc/lablink/shared/entities';
import { selectData, selectIsComplete } from '@features/orders/store/OrderState/selectors';
import { CreateOrderStepMaterials } from '@features/orders/types/CreateOrderStepMaterials';
import { Button } from '@features/ui/components';
import { ButtonProps } from '@features/ui/components/Button';
import { createSelector } from '@reduxjs/toolkit';
import { useApi } from '@services/api';
import { useMediaQuery } from '@services/hooks/window/query';
import { useAppSelector } from '@services/store/hooks';
import { useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export type OrderMgmtSaveProps = {
  btnDraft?: Pick<ButtonProps, 'className' | 'variant'>;
  btnSend?: Pick<ButtonProps, 'className'>;
};

/**
 * Contains only the 2 buttons and data
 */
export const OrderButtonSave = (props: OrderMgmtSaveProps): JSX.Element => {
  const { authApi } = useApi();
  const canCreateOrder = authApi.useHasRole(Role.DOCTOR);

  // We don't use the hook from formik, as the context may be undefined (for the toplevel buttons)
  const { values, submitForm, isSubmitting, setFieldValue } = useFormikContext<CreateOrderStepMaterials>();

  // Selector always return false or a valid data to avoid re-render
  const toSend = useAppSelector(createSelector([selectIsComplete, selectData], (isValid, data) => isValid && data));

  const isGreaterSM = useMediaQuery('sm');
  const { t } = useTranslation();
  const [lastClicked, setLastClicked] = useState<string | null>(null);

  // Enable the send button
  const ok2Send = toSend && values.samplingStatus && values.samplingStatus !== OrderSamplingStatus.SAMPLE_LATER;

  // Submission Logic
  const [submitNextRender, setSubmitNextRender] = useState(false);
  useEffect(() => {
    if (submitNextRender) {
      setSubmitNextRender(false);

      void submitForm();
    }
  }, [submitNextRender, submitForm]);

  const onClick = (btn: 'save-draft' | 'send', { status }: Pick<OrderCreateDto, 'status'>) => {
    setLastClicked(btn);
    // FIXME: update of formik allow to wait on applied changes
    void setFieldValue('status', status);

    // We need to delay the submission because the formik state is not yet updated with the status
    setSubmitNextRender(true);
  };

  return (
    <>
      {canCreateOrder.data && (
        // @ts-expect-error Due to the XOR of `icon` and `label`
        <Button
          {...props.btnDraft}
          label={isGreaterSM ? t('pages.order.contentHeader.saveDraft') : undefined}
          leftIcon={isGreaterSM ? 'floppyDisk' : undefined}
          icon={isGreaterSM ? undefined : 'floppyDisk'}
          disabled={!toSend || isSubmitting}
          onClick={() => onClick('save-draft', { status: OrderStatus.TO_ORDER })}
          loading={isSubmitting && lastClicked === 'save-draft'}
          variant={props.btnDraft?.variant || 'transparent-outline'}
        />
      )}

      {canCreateOrder.data && (
        <Button
          {...props.btnSend}
          disabled={!ok2Send || isSubmitting}
          label={t('pages.order.contentHeader.sendOrder')}
          leftIcon="inbox"
          onClick={() => onClick('send', { status: OrderStatus.ORDERED })}
          loading={isSubmitting && lastClicked === 'send'}
          variant="primary"
        />
      )}
    </>
  );
};
