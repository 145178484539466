import { MagicValues, OrderedAnalysisInterface } from '@ch-apptitude-icc/common/shared/entities';

export type ShouldApplyAdminFeesResult =
  | {
      shouldApplyAdminFees: true;
      defaultAdminFees: {
        analysisId: number;
        price: number;
      };
    }
  | { shouldApplyAdminFees: false };

const defaultAdminFees = {
  analysisId: MagicValues.MAIN_MANAGEMENT_FEE_ANALYSIS_ID,
  price: 21.6,
} as const;

/**
 * Checks if administrative fees should be applied to an order, and if so, returns the default fees that typically
 * apply for such order
 *
 * This is made into a business rule because it is too specific and the generic system, if any, would change a lot before
 * actually being used as generic.
 * @param order
 */
export function shouldApplyAdminFees(order: OrderedAnalysisInterface[]): ShouldApplyAdminFeesResult {
  if (order.find(analysis => analysis.pricingCategoryId === MagicValues.DEFAULT_PRICING_CATEGORY)) {
    return { shouldApplyAdminFees: true, defaultAdminFees: { ...defaultAdminFees } };
  }
  return { shouldApplyAdminFees: false };
}
