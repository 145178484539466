import { computeMaterialsListForOrder, MaterialsListForOrder } from '@ch-apptitude-icc/common/shared/business-rules';
import { SamplingMaterial } from '@ch-apptitude-icc/lablink/shared/entities';
import { selectFromStep } from '@features/orders/store/OrderState/selectors';
import { useApi } from '@services/api';
import { useAppSelector } from '@services/store/hooks';
import { useMemo } from 'react';

export const useMaterialsList: () => MaterialsListForOrder<SamplingMaterial> | undefined = () => {
  const { requestedAnalysis } = useAppSelector(selectFromStep.analysis.selectData);
  const { analysisApi, samplingMaterialsApi } = useApi();

  const analysesMap = analysisApi.useGetMap();
  const samplingMaterialsMap = samplingMaterialsApi.useGetMap();

  return useMemo(() => {
    if (requestedAnalysis && analysesMap.data && samplingMaterialsMap.data) {
      return computeMaterialsListForOrder(
        analysesMap.data,
        samplingMaterialsMap.data,
        requestedAnalysis.map(ra => ra.analysisId),
      );
    }

    return undefined;
  }, [requestedAnalysis, analysesMap.data, samplingMaterialsMap.data]);
};
