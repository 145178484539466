import { PickType, staticImplements } from '@ch-apptitude-icc/common/shared/type-utils';
import { OrderedAnalysis } from '@ch-apptitude-icc/lablink/shared/entities';
import { CreateOrderFront } from '@features/orders/types/CreateOrderFront';
import { CreateOrderFrontStep } from '@features/orders/types/CreateOrderFrontStep';

@staticImplements<CreateOrderFrontStep<CreateOrderStepAnalysis>>()
export class CreateOrderStepAnalysis extends PickType(CreateOrderFront, ['requestedAnalysis']) {
  constructor(ids?: Array<Pick<OrderedAnalysis, 'analysisId' | 'pricingCategoryId'>>) {
    super();
    if (ids) {
      this.requestedAnalysis = ids.map(({ analysisId, pricingCategoryId }) => ({
        analysisId,
        pricingCategoryId,
      }));
    }
  }

  public static from(dto: CreateOrderFront): CreateOrderStepAnalysis {
    return { requestedAnalysis: dto.requestedAnalysis };
  }

  public static mergeTo(source: CreateOrderStepAnalysis, dto: CreateOrderFront): CreateOrderFront {
    return { ...dto, requestedAnalysis: source.requestedAnalysis };
  }
}
