import { Button, Input, InputProps } from '@features/ui/components';
import { FormElementWrapper, FormElementWrapperProps } from '@features/ui/components/form/FormElementWrapper';
import { format, parse } from 'date-fns';
import { useField } from 'formik';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export type MaterialDateTimeInputProps = Pick<FormElementWrapperProps, 'required'> &
  Required<Pick<FormElementWrapperProps, 'name'>> &
  Pick<InputProps, 'onBlur' | 'max' | 'min'> & {
    readonly?: boolean;
  };

type MaterialDateTimeInputState = { date: string | null; time: string | null };

function splitDate(dateInput?: string | Date | null): MaterialDateTimeInputState {
  const date = (() => {
    const d = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

    return Number.isNaN(d?.getTime()) ? undefined : d;
  })();

  return {
    date: date ? format(date, 'yyyy-MM-dd') : null,
    time: date ? format(date, 'HH:mm') : null,
  };
}

function mergeDate(dateInput?: MaterialDateTimeInputState): string | undefined {
  if (!dateInput) {
    return undefined;
  }

  // If the "date" part is not set, we assume the user wants to only set the time _for today_, and set the date to
  // today by default
  const defaultDate = new Date().toISOString().split('T')[0];
  const date = parse(`${dateInput.date ?? defaultDate} ${dateInput.time ?? '00:00'}`, 'yyyy-MM-dd HH:mm', new Date());

  return Number.isNaN(date?.getTime()) ? undefined : date?.toISOString();
}

/**
 * This is only used in [OrderMaterial](../OrderMaterial.tsx).
 */
export const MaterialDateTimeInput = ({ readonly, name, ...props }: MaterialDateTimeInputProps): JSX.Element => {
  const { t } = useTranslation();

  const [{ value }, { error, touched }, { setValue }] = useField<string>({ name });
  const { date, time } = useMemo(() => splitDate(value), [value]);

  const setDate = (state: MaterialDateTimeInputState) => {
    void setValue(mergeDate(state) ?? '');
  };

  const { onBlur, ...formProps } = props;

  return (
    <FormElementWrapper
      childrenClassName="flex-col sm:flex-row flex-wrap !gap-4"
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      label={t('pages.order.materials.sampling.datetime') as string}
      names={[name]}
      {...formProps}
    >
      <div className="flex flex-col gap-1 sm:flex-row">
        <Input
          // TODO ID-403: remove? (from backend?)
          min={props.min}
          max={props.max}
          name="date"
          onBlur={onBlur}
          onChange={ev => setDate({ time, date: ev.target.value })}
          value={date ?? ''}
          readonly={readonly}
          type="date"
          error={!!error && touched}
        />

        <Input
          // TODO: remove? (from backend?)
          name="time"
          onBlur={onBlur}
          onChange={ev => setDate({ time: ev.target.value, date })}
          value={time ?? ''}
          readonly={readonly}
          type="time"
          error={!!error && touched}
        />
      </div>

      {!readonly && (
        <div className="flex flex-1 justify-end sm:justify-start">
          <Button
            className=""
            label={t('common.now')}
            leftIcon="stopWatch"
            variant="primary-transparent"
            onClick={() => {
              setDate(splitDate(new Date()));
            }}
          />
        </div>
      )}
    </FormElementWrapper>
  );
};
