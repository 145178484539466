/**
 * This file contains some magic values that are mainly used for business rules purposes
 */
export const MagicValues = {
  // KEY_ANA = 'GRS'
  // If it changes for some reason we may need to do a DB query once in a while to find it...
  SANGUINE_GROUP_ANALYSIS_ID: 37009,

  LAVIGNY_DOCTOR_IDS: new Set([144839, 145523]),

  PROFA_DOCTOR_IDS: new Set([140194, 140193, 144665, 140197, 140195, 144666, 140196, 144664, 145477]),

  VIDY_DOCTOR_IDS: new Set([145159, 145161, 145160]),

  /**
   * @deprecated
   */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  PRICINGS: [...Array(16)],

  DEFAULT_PRICING_CATEGORY: 9,

  MAIN_MANAGEMENT_FEE_ANALYSIS_ID: 37384,
} as const;
