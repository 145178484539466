/**
 * Endpoints for the different actions.
 *
 * Note that the nested values do not mean that they are just concatenated,
 * it could use a dynamic value in between (e.g. `/users/<id>/groups`)
 */
export const AUTH_ENDPOINTS = {
  lablink: {
    root: 'auth',

    /** Get connected user */
    me: 'me',
    impersonate: 'impersonate',
    redirect: { endpoint: 'sess-redirect', query: '_sess' },
  },
} as const;
