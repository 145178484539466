import { Select, SelectProps } from '@features/ui/components/form/Select';
import { Skeleton } from '@features/ui/components/Skeleton';
import { useApi } from '@services/api';

export const ProfaDoctorSelect = (props: Omit<SelectProps<string>, 'options'>): JSX.Element => {
  const { profaDoctorsApi } = useApi();
  const doctors = profaDoctorsApi.useGetAll();

  if (doctors.isLoading) {
    return <Skeleton />;
  }

  return (
    <Select
      {...props}
      options={
        doctors.data?.map(result => ({
          value: result.name ?? ' ',
          label: result.name ?? ' ',
        })) ?? []
      }
    />
  );
};
