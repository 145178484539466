import { StatusModal } from '@features/orders/components';
import {
  EditFavoritesModal,
  EditFavoritesModalProps,
} from '@features/orders/components/detail/analysis/EditFavoritesModal';
import { Button } from '@features/ui/components';
import { ConfirmModal, ConfirmModalProps } from '@features/ui/modal/components/ConfirmModal';
import { Modal } from './components/Modal';

export const modalsList = {
  test: () => (
    <Modal
      title="Test"
      description="Hello from the modal!"
      body={onClose => (
        <div className="flex justify-center pt-4">
          <Button label="Custom body" onClick={onClose} />
        </div>
      )}
    />
  ),
  statusInfo: () => <StatusModal />,
  editFavorites: (params: Pick<EditFavoritesModalProps, 'selectedAnalyses' | 'updateId'>) => (
    <EditFavoritesModal {...params} />
  ),
  confirm: (params: ConfirmModalProps) => <ConfirmModal {...params} />,
} as const;

export type ModalName = keyof typeof modalsList;
