import { Button } from '@features/ui/components';
import { ButtonProps } from '@features/ui/components/Button';
import { Modal, ModalProps } from '@features/ui/modal';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export enum ConfirmModalButton {
  BUTTON_YES,
  BUTTON_NO,
}

const ButtonMapping = {
  [ConfirmModalButton.BUTTON_YES]: {
    label: 'common.validate',
    leftIcon: 'check',
  },
  [ConfirmModalButton.BUTTON_NO]: {
    label: 'common.cancel',
    leftIcon: 'arrowTurnDownLeft',
    variant: 'secondary',
  },
} as const satisfies Record<ConfirmModalButton, ButtonProps>;

export type ConfirmModalProps = {
  buttons?: ConfirmModalButton[];
  title: string;
  message: string;
  onClick: (clickedButton?: ConfirmModalButton) => void;
};

export function ConfirmModal({
  title,
  message,
  onClick,
  buttons,
  onClose,
}: ConfirmModalProps & Pick<ModalProps, 'onClose'>): JSX.Element {
  const { t } = useTranslation('common');
  const finalButtons = useMemo(
    () => buttons ?? [ConfirmModalButton.BUTTON_NO, ConfirmModalButton.BUTTON_YES],
    [buttons],
  );

  const body: ModalProps['body'] = bodyOnClose => (
    <div className="flex flex-col px-24 pb-5 lg:w-[840px]">
      <div className="py-10 text-center">
        {message.split('\n').map(line => (
          <p key={line}>{line}</p>
        ))}
      </div>

      <div className="flex flex-row items-center justify-between gap-x-4">
        {finalButtons.map(btn => {
          const btnBase = ButtonMapping[btn];

          return (
            <Button
              {...btnBase}
              key={btn}
              label={btnBase.label && t(btnBase.label)}
              onClick={() => {
                onClick(btn);
                if (bodyOnClose) {
                  bodyOnClose();
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );

  return <Modal body={body} title={title} onClose={onClose} />;
}
