import { AnalysisType } from './enums';

export interface AnalysisInterface {
  samplingMaterialId?: number | null;
  id: number;
  analysisType: AnalysisType;
  key: string | null;
  name: string | null;
}

export interface WebsiteFields {
  publicName: string | null;
  publicSubCategory: string | null;
  displayTab?: AnalysisDisplayTab | null;
  displayInVadeMecum: boolean;
}

export enum AnalysisDisplayTab {
  /**
   * As initially designed, this would have included the CHEMICAL and HEMATOLOGY sectors.
   *
   * Currently, it is equivalent to the 'C' legacy catalog type.
   */
  MEDICAL = 'MEDICAL',

  /**
   * As initially designed, this would have included the MICROBIOLOGY sector.
   *
   * Currently, it is equivalent to the 'B' legacy catalog type.
   */
  MICROBIOLOGY = 'MICROBIOLOGY',

  /**
   * As initially designed, this would have included the BIOMONITORING and TOXICOLOGY sectors.
   *
   * Currently, it is equivalent to the 'A' legacy catalog type.
   */
  TOXICOLOGY = 'TOXICOLOGY',
}

export const AnalysisDisplayTabs = Object.values(AnalysisDisplayTab);
