import { ValueTransformer } from 'typeorm';

export const dateTransformer: ValueTransformer = {
  from(value?: Date | string | null): string | null {
    if (value != null) {
      if (typeof value === 'string') {
        return value;
      }
      return value.toISOString();
    }
    return null;
  },

  to(value?: string | null): Date | null | string | undefined {
    if (typeof value === 'string' && value?.startsWith('%') && value?.endsWith('%')) {
      // Special case for LIKE requests.
      // By returning the value as a string, we ensure that TypeORM doesn't apply any time-related transformation to it
      return value;
    }

    if (typeof value === 'object') {
      return value;
    }

    return value ? new Date(Date.parse(value)) : value;
  },
};
