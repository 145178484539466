import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { Order as OrderBack } from '@ch-apptitude-icc/lablink/shared/entities';

import { ApiFactory, Keys } from '@common/frontend/services/api-client';
import { OrderFront } from '@features/orders/types/OrderFront';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.orders(OrderFront);

export class OrderApi extends ApiFactory<OrderFront, typeof api, Keys<OrderFront>, ReturnType<typeof api>>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }

  getBarcodeImageUrl(orderId: OrderBack['id'], options?: { barWidth?: number; height?: number }): string {
    return this.api.getBarcodeImageUrl(orderId, options);
  }

  getBarcodePdfUrl(orderId: OrderBack['id'], numCodes?: number): string {
    return this.api.getBarcodePdfUrl(orderId, numCodes);
  }

  getOrderSheetPdfUrl(orderId: OrderBack['id']): string {
    return this.api.getOrderSheetPdfUrl(orderId);
  }
}
