import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { Catalogue } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.catalogues(Catalogue);

export class CataloguesApi extends ApiFactory<Catalogue, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}

export { Catalogue as AnalysisGroupFront };
