import {
  faArrowUp,
  faBriefcaseMedical,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faClockRotateLeft,
  faEllipsis,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFlask,
  faFloppyDisk,
  faHospital,
  faLink,
  faBars,
  faMagnifyingGlass,
  faMars,
  faMarsAndVenus,
  faPager,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPhone,
  faPrescriptionBottle,
  faReceipt,
  faSortDown,
  faSortUp,
  faStopwatch,
  faTrash,
  faTurnDown,
  faUpDown,
  faUser,
  faUserDoctor,
  faUserPlus,
  faVenus,
  faVial,
  faVirus,
  faXmark,
  faRightFromBracket,
  faArrowLeftLong,
  faArrowRightLong,
  faCircleCheck,
  faCircleInfo,
  faCircleMinus,
  faMug,
  faTriangleExclamation,
  faCircleXmark,
  faLeftLong,
  faCircleExclamation,
  faFileMagnifyingGlass,
  faInboxFull,
  faMessagesQuestion,
  faArrowTurnDownLeft,
  faFileLines,
  faLightEmergencyOn,
  faRectangleBarcode,
  faSpinnerThird,
  faTablets,
  faTimer,
  faPaperPlaneTop,
  faStar,
  faHexagonExclamation,
  faHexagonPlus,
} from '@fortawesome/pro-regular-svg-icons';

export const ICONS = {
  arrowTurnDownLeft: faArrowTurnDownLeft,
  arrowDownLeft: faArrowLeftLong,
  arrowRight: faArrowRightLong,
  arrowUp: faArrowUp,
  bars: faBars,
  briefcaseMedical: faBriefcaseMedical,
  calendar: faCalendar,
  check: faCheck,
  checkCircle: faCircleCheck,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  circleInfo: faCircleInfo,
  circleMinus: faCircleMinus,
  clock: faClock,
  clockRotateLeft: faClockRotateLeft,
  coffee: faMug,
  ellipsis: faEllipsis,
  ellipsisHorizontal: faEllipsis,
  ellipsisVertical: faEllipsisVertical,
  exclamationCircle: faCircleExclamation,
  eye: faEye,
  eyeSlash: faEyeSlash,
  fileMagnifyingGlass: faFileMagnifyingGlass,
  filePdf: faFilePdf,

  // TODO ID-364: Get correct icon [LVI: I think the icon Barbara used is no longer available in FA; so I used another one that is closer than the ugly PDF one]
  fileReport: faFileLines,
  flask: faFlask,
  floppyDisk: faFloppyDisk,
  hospital: faHospital,
  hexagonExclamation: faHexagonExclamation,
  hexagonPlus: faHexagonPlus,
  inbox: faInboxFull,
  label: faPager,
  lightEmergency: faLightEmergencyOn,
  link: faLink,
  logout: faRightFromBracket,
  longArrowAltLeft: faLeftLong,
  magnifyingGlass: faMagnifyingGlass,
  mars: faMars,
  marsAndVenus: faMarsAndVenus,
  messageQuestion: faMessagesQuestion,
  paperPlane: faPaperPlane,
  paperPlaneTop: faPaperPlaneTop,
  pen: faPen,
  penToSquare: faPenToSquare,
  phone: faPhone,
  prescriptionBottle: faPrescriptionBottle,
  receipt: faReceipt,
  rectangleBarcode: faRectangleBarcode,
  sortDown: faSortDown,
  sortUp: faSortUp,
  spinner: faSpinnerThird,
  star: faStar,
  stopWatch: faStopwatch,
  tablets: faTablets,
  timer: faTimer,
  trash: faTrash,
  turnDown: faTurnDown,
  upDown: faUpDown,
  user: faUser,
  userDoctor: faUserDoctor,
  userPlus: faUserPlus,
  venus: faVenus,
  vial: faVial,
  virus: faVirus,
  warning: faTriangleExclamation,
  xmark: faXmark,
  xmarkCircle: faCircleXmark,
} as const;

export type IconType = keyof typeof ICONS | 'loading';
