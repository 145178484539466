import { AuthImpersonator } from '@ch-apptitude-icc/common/shared/entities';
import { User } from '../entities/user';

export enum AuthUserConnectedType {
  REGULAR = '-',

  /** Type for impersonation */
  IMPERSONATED = 'impersonated',
}

export interface AuthUserConnectedBase<T extends AuthUserConnectedType> {
  type: T;
  user: User;
}

/** "Regular" info for a connected user */
export type AuthUserConnectedRegular = AuthUserConnectedBase<AuthUserConnectedType.REGULAR>;

/** Info for a user that is impersonated */
export interface AuthUserConnectedImpersonated extends AuthUserConnectedBase<AuthUserConnectedType.IMPERSONATED> {
  impersonator: AuthImpersonator;
}

/** The info about the connected user */
export type AuthUserConnected = AuthUserConnectedRegular | AuthUserConnectedImpersonated;
