import { useEffect, useMemo, useState } from 'react';

// TODO ID-355: wrong folder?

/**
 * Dimension the window (from innerHeight and innerWidth)
 */
export interface WindowDimension {
  height: number;
  width: number;
}

/**
 * Get the current dimension from the window
 */
export function getWindowDimension(): WindowDimension {
  if (typeof window === 'undefined') {
    return { height: 0, width: 0 };
  }

  return { height: window.innerHeight, width: window.innerWidth };
}

/**
 * Optional dimension is ignored.
 * @warning if both are optional, always trigger the hook
 */
export type BreakPoint = Partial<WindowDimension>;

export interface UseWindowResizeOptions {
  /**
   * @default false
   */
  disable?: boolean;
}
/**
 * "Listen" to the resize event.
 * Add breakpoints to only be triggered when desired.
 * @warning with breakpoints, the value returned is when the dimension changed
 * @hack use 1 breakpoint with a really big value to "disable" the "listener"
 */
export function useWindowResize(breakpoints?: BreakPoint[], options?: UseWindowResizeOptions): WindowDimension {
  const [size, setSize] = useState<WindowDimension>(getWindowDimension());

  const handleResize = useMemo(() => {
    if (!breakpoints || breakpoints.length === 0) {
      // No breakpoint detection
      return () => setSize(getWindowDimension());
    }

    return () => {
      const dim = getWindowDimension();

      for (const breakpoint of breakpoints) {
        // [break, size, dim].sort()[1] assures that, if the break is in the middle, there's a change in the size
        // if break and dim are the same, then the value changes once and won't happen again for the same value

        // a `switch` instead of an if with `||`
        switch (true) {
          case breakpoint.height === breakpoint.width && breakpoint.width === undefined:
          case breakpoint.height !== undefined &&
            breakpoint.height !== dim.height &&
            [breakpoint.height, size.height, dim.height].sort((a, b) => a - b)[1] === breakpoint.height:
          case breakpoint.width !== undefined &&
            breakpoint.width !== dim.width &&
            [breakpoint.width, size.width, dim.width].sort((a, b) => a - b)[1] === breakpoint.width:
            setSize(dim);
            return;
          default:
        }
      }
    };
    // - dep 1: Only if the breakpoints change
    // - dep 2: Only detect the size change with breakpoints
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(breakpoints), breakpoints?.length && size]);

  useEffect(() => {
    if (options?.disable) {
      return () => {};
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, options?.disable]);

  return size;
}
