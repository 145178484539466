import { Toast } from '@features/ui/components';
import { Id, toast } from 'react-toastify';
// eslint-disable-next-line import/no-relative-parent-imports -- We don't export the type to the outside world so we need to import it relatively
import { ToastProps } from '../components/Toast';

export function showToast({ onClose, ...props }: ToastProps): Id {
  return toast(({ closeToast }) => <Toast {...props} closeToast={closeToast} />, {
    // The only way to remove style is through inline style, classnames are not working
    bodyStyle: { borderRadius: 0, margin: 0, padding: 0 },
    closeButton: false,
    hideProgressBar: true,
    // onClose is not triggered by the Toast component itself but here to catch all possible ways of closing the toast
    onClose,
    style: { backgroundColor: 'transparent', borderRadius: 0, minHeight: 0, padding: 0 },
  });
}
