import { shouldApplyAdminFees } from '@ch-apptitude-icc/common/shared/business-rules';
import { PricingCategory } from '@ch-apptitude-icc/lablink/shared/entities';
import { Spinner } from '@features/ui/components/Spinner';
import { AnalysisFront, useApi } from '@services/api';
import { useFormatters } from '@services/formatters';
import { useTranslation } from 'next-i18next';
import { OrderAnalysisChip, OrderAnalysisChipProps } from './OrderAnalysisChip';

export type SelectedAnalysesList = {
  /**
   * @default true
   */
  editable?: boolean;
  pricing?: PricingCategory;
  analyses: AnalysisFront[];
};

export type OrderAnalysesSelectionProps = {
  analyses: Array<SelectedAnalysesList>;
  chip: Pick<OrderAnalysisChipProps, 'onClick'>;
  editable?: boolean;
  /**
   * @default Function that returns true
   */
  isAnalysisEditable?: (analysis: AnalysisFront) => boolean;
};

export const OrderAnalysesSelection = ({
  analyses,
  chip,
  editable,
  isAnalysisEditable,
}: OrderAnalysesSelectionProps): JSX.Element => {
  const { fNCurrency } = useFormatters();
  const { t } = useTranslation('common');
  const { authApi } = useApi();
  const me = authApi.useGetMe();

  if (me.isLoading || !me.data) {
    return <Spinner />; // Should never occur
  }

  const adminFees = shouldApplyAdminFees(
    analyses
      .filter(p => p.pricing && p.analyses && p.analyses.length)
      .map(pricing => ({ pricingCategoryId: pricing.pricing!.id, analysisId: pricing.analyses[0].id })),
  );
  const fee = adminFees.shouldApplyAdminFees ? adminFees.defaultAdminFees.price : 0;

  const price = analyses.reduce(
    (total, list) => total + list.analyses.reduce((subTotal, analysis) => subTotal + (analysis.pricing?.value ?? 0), 0),
    fee,
  );

  const analysesWithNoPrice = analyses.reduce(
    (total, list) => total + list.analyses.filter(analysis => (analysis.pricing?.value ?? -1) < 0).length,
    0,
  );

  return (
    <div className="flex flex-col gap-2 sm:flex-row">
      <div className="flex flex-1 flex-row flex-wrap items-center gap-2">
        <span className="text-lg">{t('pages.order.analysis.selected')}</span>

        <div className="flex flex-1 flex-col gap-2">
          {!analyses.length && <span className="text-sm text-gray-500">{t('pages.order.analysis.select')}</span>}

          {analyses.map(({ editable: editableAnalysis = true, pricing, analyses: selected }) => {
            const editableChip = editableAnalysis && editable;

            if (me.data.doctor?.displayPrincingInLablink === true && analyses.length > 1) {
              // Multiple pricing groups + pricings are visible
              return (
                <div key={pricing?.id} className="flex flex-row flex-wrap items-center gap-2">
                  <span className="text-sm font-light text-gray-500">
                    {pricing?.name
                      ? t('pages.order.analysis.pricing', { pricingName: pricing?.name })
                      : t('pages.order.analysis.noPricing')}
                  </span>
                  <SelectedAnalysesInGroup
                    analyses={selected}
                    isAnalysisEditable={isAnalysisEditable}
                    editable={editableChip}
                    chip={chip}
                  />
                </div>
              );
            }

            return (
              <div key={pricing?.id} className="flex flex-1 flex-row flex-wrap items-center gap-2">
                <SelectedAnalysesInGroup
                  analyses={selected}
                  isAnalysisEditable={isAnalysisEditable}
                  editable={editableChip}
                  chip={chip}
                />
              </div>
            );
          })}
        </div>
      </div>

      {me.data.doctor?.displayPrincingInLablink === true && price > 0 && (
        <div className="flex flex-col items-end">
          <span className="text-2xl">{t('pages.order.analysis.totalPrice', { price: fNCurrency(price) })}</span>

          {fee > 0 && (
            <span className="text-xs text-gray-400">
              {t('pages.order.analysis.totalFee', { fee: fNCurrency(fee) })}
            </span>
          )}
          {analysesWithNoPrice > 0 && (
            <span className="text-xs text-gray-400">
              {t('pages.order.analysis.totalNoPrice', { num: analysesWithNoPrice })}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

function SelectedAnalysesInGroup({
  analyses,
  editable,
  chip,
  isAnalysisEditable = () => true,
}: {
  analyses: Array<AnalysisFront>;
} & Pick<OrderAnalysesSelectionProps, 'chip' | 'editable' | 'isAnalysisEditable'>) {
  return (
    <>
      {analyses.map(analysis => (
        <OrderAnalysisChip
          key={analysis.id}
          analysis={analysis}
          clickable={isAnalysisEditable(analysis) && editable}
          onClick={chip.onClick}
          selected
        />
      ))}
    </>
  );
}
