import { helpers, Type } from '@ch-apptitude-icc/common/shared/type-utils';
import { AxiosRequestConfig } from 'axios';
import { stringify } from 'qs';

// eslint-disable-next-line @typescript-eslint/naming-convention
const _fieldRenames = new Map<Type<unknown>, { localName: string; remoteName: string; type?: Type<unknown> }[]>();

export const BaseAxiosOptions: AxiosRequestConfig = {
  paramsSerializer: params => stringify(params, { allowDots: true }),
};

export function rename<T>(tpe: Type<T>, objectToRename: Record<string, unknown>): Record<string, unknown> {
  if (!_fieldRenames.has(tpe)) {
    createSerializationRenamer(tpe);
  }

  const output = { ...objectToRename };
  _fieldRenames.get(tpe)?.forEach(({ localName, remoteName, type }) => {
    let element = objectToRename?.[localName];

    if (type && typeof element === 'object') {
      element = rename(type, element as Record<string, unknown>);
    }

    delete output[localName];
    output[remoteName] = element;
  });

  return output;
}

export function createSerializationRenamer<T>(tpe: Type<T>) {
  if (_fieldRenames.has(tpe)) {
    return; // Already exists
  }

  _fieldRenames.set(tpe, []);

  helpers
    .getMetadataStorage()
    .getExposedMetadatas(tpe)
    .filter(meta => meta.options.name)
    .forEach(metadata => {
      // Can we find a type data?
      const type = helpers
        .getMetadataStorage()
        .findTypeMetadata(tpe, metadata.propertyName!)
        ?.typeFunction() as Type<unknown>;

      _fieldRenames.get(tpe)!.push({
        localName: metadata.propertyName!,
        remoteName: metadata.options.name!,
        type,
      });
    });
}
