import classNames from 'classnames';
import { Icon } from './icon';

export type LineCardProps = {
  className?: string;
  left?: React.ReactNode;
  main?: React.ReactNode;
  onRemove?: () => void;
  right?: React.ReactNode;
};

export const LineCard = ({ left, right, main, onRemove, className }: LineCardProps): JSX.Element => (
  <div className={classNames('min-h-8 flex w-full items-center rounded-lg bg-gray-50 px-3 py-1 shadow', className)}>
    {left && <div className="text-primary ml-3 pr-3">{left}</div>}
    {main && <div className="flex-1 overflow-hidden text-ellipsis text-lg font-medium text-gray-800">{main}</div>}
    {right && <div className="text-sm text-gray-500">{right}</div>}
    {onRemove && <Icon icon="xmarkCircle" className="ml-5 text-gray-300" onClick={onRemove} />}
  </div>
);
