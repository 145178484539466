import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { ProfaDoctor } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.profaDoctors(ProfaDoctor);

export class ProfaDoctorsApi extends ApiFactory<ProfaDoctor, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}

export { ProfaDoctor as ProfaDoctorFront };
