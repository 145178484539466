import * as cv from 'class-validator';
import { ValidationOptions } from 'class-validator';
import { ValidationArguments } from 'class-validator/types/validation/ValidationArguments';

function msg(key: string) {
  return (args: ValidationArguments): string => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    const constraints: string[] = args.constraints?.filter(c => !!c).map(anyval => anyval?.toString() as string) ?? [];
    return [`${args.property}:${key}`, ...constraints].join(';');
  };
}

export type ValidatorType<T> = T & { validatorName: string };

export type ExtendedValidationOptions = ValidationOptions & { errorName?: string };

// eslint-disable-next-line @typescript-eslint/ban-types
function setValidatorName<T extends Function>(name: string, func: T): ValidatorType<T> {
  Object.defineProperty(func, 'name', { value: name });

  // Minimizers (particularly for the frontend bundles) remove function names, so if we want code to be able to access
  //  the validator name it needs to be defined in a different property
  Object.defineProperty(func, 'validatorName', { value: name });
  return func as T & { validatorName: string };
}

function wrap0(
  name: string,
  func: (args?: ExtendedValidationOptions) => PropertyDecorator,
): ValidatorType<(args?: ExtendedValidationOptions) => PropertyDecorator> {
  return setValidatorName(name, (args?: ExtendedValidationOptions) =>
    func({ ...args, message: args?.message ?? msg(args?.errorName ?? name) }),
  );
}

function wrap1<T1>(
  name: string,
  func: (arg1: T1, args?: ExtendedValidationOptions) => PropertyDecorator,
): ValidatorType<(arg1: T1, args?: ExtendedValidationOptions) => PropertyDecorator> {
  return setValidatorName(name, (arg1: T1, args?: ExtendedValidationOptions) =>
    func(arg1, { ...args, message: args?.message ?? msg(args?.errorName ?? name) }),
  );
}

function wrap1Opt<T1>(
  name: string,
  func: (arg1?: T1, args?: ExtendedValidationOptions) => PropertyDecorator,
): ValidatorType<(arg1?: T1, args?: ExtendedValidationOptions) => PropertyDecorator> {
  return setValidatorName(name, (arg1?: T1, args?: ExtendedValidationOptions) =>
    func(arg1, { ...args, message: args?.message ?? msg(args?.errorName ?? name) }),
  );
}

function wrap2<T1, T2>(
  name: string,
  func: (arg1: T1, arg2: T2, args?: ExtendedValidationOptions) => PropertyDecorator,
): ValidatorType<(arg1: T1, arg2: T2, args?: ExtendedValidationOptions) => PropertyDecorator> {
  return setValidatorName(name, (arg1: T1, arg2: T2, args?: ExtendedValidationOptions) =>
    func(arg1, arg2, { ...args, message: args?.message ?? msg(args?.errorName ?? name) }),
  );
}

export const IsDefined = wrap0('IsDefined', cv.IsDefined);
export const IsOptional = wrap0('IsOptional', cv.IsOptional);
export const Equals = wrap1('Equals', cv.Equals);
export const NotEquals = wrap1('NotEquals', cv.NotEquals);
export const IsEmpty = wrap0('IsEmpty', cv.IsEmpty);
export const IsNotEmpty = wrap0('IsNotEmpty', cv.IsNotEmpty);
export const IsIn = wrap1('IsIn', cv.IsIn);
export const IsNotIn = wrap1('IsNotIn', cv.IsNotIn);
export const IsBoolean = wrap0('IsBoolean', cv.IsBoolean);
export const IsDate = wrap0('IsDate', cv.IsDate);
export const IsString = wrap0('IsString', cv.IsString);
export const IsNumber = wrap1Opt('IsNumber', cv.IsNumber);
export const IsInt = wrap0('IsInt', cv.IsInt);
export const IsArray = wrap0('IsArray', cv.IsArray);
export const IsEnum = wrap1('IsEnum', cv.IsEnum);
export const IsDivisibleBy = wrap1('IsDivisibleBy', cv.IsDivisibleBy);
export const IsPositive = wrap0('IsPositive', cv.IsPositive);
export const IsNegative = wrap0('IsNegative', cv.IsNegative);
export const Min = wrap1('Min', cv.Min);
export const Max = wrap1('Max', cv.Max);
export const IsBooleanString = wrap0('IsBooleanString', cv.IsBooleanString);
export const IsDateString = wrap1Opt('IsDateString', cv.IsDateString);
export const IsNumberString = wrap1Opt('IsNumberString', cv.IsNumberString);
export const Contains = wrap1('Contains', cv.Contains);
export const NotContains = wrap1('NotContains', cv.NotContains);
export const IsAlpha = wrap1('IsAlpha', cv.IsAlpha);
export const IsAlphanumeric = wrap1('IsAlphanumeric', cv.IsAlphanumeric);
export const IsDecimal = wrap1('IsDecimal', cv.IsDecimal);
export const IsAscii = wrap0('IsAscii', cv.IsAscii);
export const IsBase32 = wrap0('IsBase32', cv.IsBase32);
export const IsBase64 = wrap0('IsBase64', cv.IsBase64);
export const IsIBAN = wrap0('IsIBAN', cv.IsIBAN);
export const IsBIC = wrap0('IsBIC', cv.IsBIC);
export const IsByteLength = wrap2('IsByteLength', cv.IsByteLength);
export const IsCreditCard = wrap0('IsCreditCard', cv.IsCreditCard);
export const IsCurrency = wrap1Opt('IsCurrency', cv.IsCurrency);
export const IsEthereumAddress = wrap0('IsEthereumAddress', cv.IsEthereumAddress);
export const IsBtcAddress = wrap0('IsBtcAddress', cv.IsBtcAddress);
export const IsDataURI = wrap0('IsDataURI', cv.IsDataURI);
export const IsEmail = wrap1Opt('IsEmail', cv.IsEmail);
export const IsFQDN = wrap1Opt('IsFQDN', cv.IsFQDN);
export const IsFullWidth = wrap0('IsFullWidth', cv.IsFullWidth);
export const IsHalfWidth = wrap0('IsHalfWidth', cv.IsHalfWidth);
export const IsVariableWidth = wrap0('IsVariableWidth', cv.IsVariableWidth);
export const IsHexColor = wrap0('IsHexColor', cv.IsHexColor);
export const IsHSL = wrap0('IsHSL', cv.IsHSL);
export const IsRgbColor = wrap1Opt('IsRgbColor', cv.IsRgbColor);
export const IsIdentityCard = wrap1Opt('IsIdentityCard', cv.IsIdentityCard);
export const IsPassportNumber = wrap1('IsPassportNumber', cv.IsPassportNumber);
export const IsPostalCode = wrap1('IsPostalCode', cv.IsPostalCode);
export const IsHexadecimal = wrap0('IsHexadecimal', cv.IsHexadecimal);
export const IsOctal = wrap0('IsOctal', cv.IsOctal);
export const IsMACAddress = wrap1('IsMACAddress', cv.IsMACAddress);
export const IsIP = wrap1Opt('IsIP', cv.IsIP);
export const IsPort = wrap0('IsPort', cv.IsPort);
export const IsISBN = wrap1('IsISBN', cv.IsISBN);
export const IsEAN = wrap0('IsEAN', cv.IsEAN);
export const IsISIN = wrap0('IsISIN', cv.IsISIN);
export const IsISO8601 = wrap1('IsISO8601', cv.IsISO8601);
export const IsJSON = wrap0('IsJSON', cv.IsJSON);
export const IsJWT = wrap0('IsJWT', cv.IsJWT);
export const IsObject = wrap0('IsObject', cv.IsObject);
export const IsNotEmptyObject = wrap1Opt('IsNotEmptyObject', cv.IsNotEmptyObject);
export const IsLowercase = wrap0('IsLowercase', cv.IsLowercase);
export const IsLatLong = wrap0('IsLatLong', cv.IsLatLong);
export const IsLatitude = wrap0('IsLatitude', cv.IsLatitude);
export const IsLongitude = wrap0('IsLongitude', cv.IsLongitude);
export const IsMobilePhone = wrap2('IsMobilePhone', cv.IsMobilePhone);
export const IsISO31661Alpha2 = wrap0('IsISO31661Alpha2', cv.IsISO31661Alpha2);
export const IsISO31661Alpha3 = wrap0('IsISO31661Alpha3', cv.IsISO31661Alpha3);
export const IsLocale = wrap0('IsLocale', cv.IsLocale);
export const IsPhoneNumber = wrap1('IsPhoneNumber', cv.IsPhoneNumber);
export const IsMongoId = wrap0('IsMongoId', cv.IsMongoId);
export const IsMultibyte = wrap0('IsMultibyte', cv.IsMultibyte);
export const IsSurrogatePair = wrap0('IsSurrogatePair', cv.IsSurrogatePair);
export const IsUrl = wrap1('IsUrl', cv.IsUrl);
export const IsMagnetURI = wrap0('IsMagnetURI', cv.IsMagnetURI);
export const IsUUID = wrap1('IsUUID', cv.IsUUID);
export const IsFirebasePushId = wrap0('IsFirebasePushId', cv.IsFirebasePushId);
export const IsUppercase = wrap0('IsUppercase', cv.IsUppercase);
export const Length = wrap2('Length', cv.Length);
export const MinLength = wrap1('MinLength', cv.MinLength);
export const MaxLength = wrap1('MaxLength', cv.MaxLength);
export const Matches = wrap1<RegExp>('Matches', cv.Matches);
export const IsMilitaryTime = wrap0('IsMilitaryTime', cv.IsMilitaryTime);
export const IsHash = wrap1('IsHash', cv.IsHash);
export const IsMimeType = wrap0('IsMimeType', cv.IsMimeType);
export const IsSemVer = wrap0('IsSemVer', cv.IsSemVer);
export const IsISSN = wrap1('IsISSN', cv.IsISSN);
export const IsISRC = wrap0('IsISRC', cv.IsISRC);
export const IsRFC3339 = wrap0('IsRFC3339', cv.IsRFC3339);
export const ArrayContains = wrap1('ArrayContains', cv.ArrayContains);
export const ArrayNotContains = wrap1('ArrayNotContains', cv.ArrayNotContains);
export const ArrayNotEmpty = wrap0('ArrayNotEmpty', cv.ArrayNotEmpty);
export const ArrayMinSize = wrap1('ArrayMinSize', cv.ArrayMinSize);
export const ArrayMaxSize = wrap1('ArrayMaxSize', cv.ArrayMaxSize);
export const ArrayUnique = wrap1('ArrayUnique', cv.ArrayUnique<unknown>);
export const IsInstance = wrap1('IsInstance', cv.IsInstance);
export const Allow = wrap0('Allow', cv.Allow);
export const ValidateNested = wrap0('ValidateNested', cv.ValidateNested);
export const ValidateIf = wrap1('ValidateIf', cv.ValidateIf);
