import { ReportStatus } from '../enums';

export abstract class AbstractReference {
  id!: number;

  iccFileId!: number;

  reportStatus!: ReportStatus;

  copyId!: number;

  doctorId!: number | null;

  patientId!: number | null;

  orderId!: number | null;

  reportDate!: Date | null;

  sampleDate!: Date | null;

  receptionDate!: Date | null;

  file?: string | null;

  md5?: string | null;

  read?: boolean;

  materialId?: string | null;
}
