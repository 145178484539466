import { format as dateFormat, isValid } from 'date-fns';

/**
 * JS Date or a timestamp
 */
export type FDate = Required<Parameters<typeof dateFormat>>[0];

/**
 * Function that formats a date.
 *
 * Can get the used format.
 */
export interface DateTimeFormatter {
  /**
   * Formats the given [date](.) to a specified format, took from the translations.
   */
  (date: FDate): string;
  /**
   * The format used in this function
   */
  format: string;
}

/**
 * Creates a [DateTimeFormatter](.) with the given format.
 */
export function createDateTimeFormatter(format: string): DateTimeFormatter {
  const fn: DateTimeFormatter = date => (isValid(date) ? dateFormat(date, fn.format) : '');
  fn.format = format;

  return fn;
}
