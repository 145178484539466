import { strings } from '@ch-apptitude-icc/common/shared/utils';
import { registerDecorator, ValidationOptions } from 'class-validator';
import { ValidatorType } from './validators';

export const AVSNumberRegex = /756\.?[0-9]{4}\.?[0-9]{4}\.?[0-9]{2}/;

export const testAvsNumber = (value: string): boolean => {
  if (!AVSNumberRegex.test(value)) {
    return false;
  }

  // Extract only digits to check the EAN13 last digit
  const number = strings.toSsnNormalized(value);

  const checkDigit = +number.charAt(12);
  const checkSum =
    number
      .substring(0, 12)
      .split('')
      .map((char, index) => (index % 2 === 0 ? 1 : 3) * +char)
      .reduce((acc, sum) => acc + sum, 0) % 10;

  return checkSum === 0 ? checkDigit === 0 : checkDigit === 10 - checkSum;
};

// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/naming-convention
const _IsAVSNumber = (validationOptions?: ValidationOptions) => (obj: Object, propName: string) =>
  registerDecorator({
    name: 'IsAVSNumber',
    target: obj.constructor,
    propertyName: propName,
    constraints: [],
    options: {
      message: `${propName}:IsAVSNumber`,
      ...validationOptions,
    },
    validator: {
      validate(value: unknown): boolean {
        if (typeof value !== 'string') {
          return false;
        }

        return testAvsNumber(value);
      },
    },
  });

export const IsAVSNumber = _IsAVSNumber as ValidatorType<typeof _IsAVSNumber>;
Object.defineProperty(IsAVSNumber, 'validatorName', { value: 'IsAVSNumber' });
