import {
  getMetadataStorage,
  inheritPropertyInitializers,
  inheritTransformationMetadata,
  inheritTypeormMetadata,
  inheritValidationMetadata,
} from './type-helpers.utils';
import { MappedType, Type } from '../type';

/**
 *
 * @param classRef
 * @param keys
 * @param expose if true, an `@Expose()` annotation will be added to the fields in `keys`
 * @constructor
 */
export function PickType<T, K extends keyof T>(
  classRef: Type<T>,
  keys: readonly K[],
  expose?: boolean,
): MappedType<Pick<T, typeof keys[number]>> & { pickedFields: readonly K[] } {
  const isInheritedPredicate = (propertyKey: string) => keys.includes(propertyKey as K);

  abstract class PickClassType {
    constructor() {
      inheritPropertyInitializers(this, classRef, isInheritedPredicate);
    }

    static readonly pickedFields = keys;
  }

  inheritValidationMetadata(classRef, PickClassType, isInheritedPredicate);
  inheritTransformationMetadata(classRef, PickClassType, isInheritedPredicate);
  inheritTypeormMetadata(classRef, PickClassType, isInheritedPredicate);

  if (expose) {
    const metadataStorage = getMetadataStorage();
    keys.forEach(key =>
      metadataStorage.addExposeMetadata({
        target: PickClassType,
        propertyName: key as string,
        options: {},
      }),
    );
  }

  return PickClassType as MappedType<Pick<T, typeof keys[number]>> & { pickedFields: readonly K[] };
}
