import { Icon } from '@features/ui/components';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';

// This should always be in sync with header height in css below
export const HEADER_HEIGHT = 80;

type HeaderProps = { left?: ReactNode; right?: ReactNode };

export const Header = ({ left, right }: HeaderProps): JSX.Element => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [router.route]);

  return (
    // Header height to sync below
    <div className="bg-brand flex h-20 w-full justify-center">
      <div className="container relative flex flex-row items-center border-b border-b-white/20 py-4 px-6 text-white md:px-0">
        <Link href="/" className="mr-14">
          <Image src="/images/icc_logo.png" alt="ICC logo" height="41" width="166" />
        </Link>
        <div
          className={classNames(
            { hidden: !mobileMenuOpen },
            'flex flex-grow flex-col md:flex md:flex-row md:gap-6',
            'bg-brand absolute top-20 left-0 z-10 w-full p-6 md:relative md:top-0 md:z-0 md:h-auto md:w-auto md:bg-transparent md:p-0',
          )}
        >
          <div className="flex-grow">{left}</div>
          <div>{right}</div>
        </div>
        <div className="flex flex-grow items-center justify-end md:hidden">
          <Icon icon="bars" className="h-6 text-white" onClick={() => setMobileMenuOpen(value => !value)} />
        </div>
      </div>
    </div>
  );
};
