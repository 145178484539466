import { Select } from '@features/ui/components';
import { Skeleton } from '@features/ui/components/Skeleton';
import { envConfig } from '@root/envConfig';
import { useApi } from '@services/api';
import { useEffect } from 'react';

export const CatalogueSelect = ({
  selected,
  setSelected,
}: {
  selected?: number;
  setSelected: (selected: number) => void;
}): JSX.Element => {
  const { cataloguesApi } = useApi();
  const catalogues = cataloguesApi.useGetList({
    pageSize: envConfig.constants.api.unboundedPageSize,
    sorted: { order: 'ASC' },
  });

  useEffect(() => {
    if (catalogues.data?.total === 1) {
      setSelected(catalogues.data.results[0].id);
    } else if (catalogues.data && catalogues.data?.total > 1) {
      if (!catalogues.data.results.map(result => result.id).includes(selected ?? -1)) {
        console.warn(
          `Current selected catalogue ${selected ?? '<na>'} is invalid (not in ${catalogues.data.results
            .map(result => result.id)
            .join(', ')})`,
        );
        setSelected(catalogues.data.results[0].id);
      }
    }
  }, [selected, setSelected, catalogues.data]);

  if (!catalogues.data) {
    return <Skeleton />;
  }

  if (catalogues.data.total === 1) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Select
      options={catalogues.data.results.map(result => ({ label: result.name, value: result.id }))}
      onChange={chg => setSelected(+chg.target.value)}
      value={selected}
      required
    />
  );
};
