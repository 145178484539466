export enum Gender {
  FEMALE = 'F',
  MALE = 'H',
  UNKNOWN = 'T',
}

export const GENDER_TITLES = {
  [Gender.FEMALE]: ['D', Gender.FEMALE, 'S', 'X', 'Y'],
  [Gender.MALE]: [Gender.MALE, 'M'],
  [Gender.UNKNOWN]: ['A', 'C', 'E', 'J', 'P', 'R', Gender.UNKNOWN, '-'],
} as const;

export const Titles = [
  ...GENDER_TITLES[Gender.FEMALE],
  ...GENDER_TITLES[Gender.MALE],
  ...GENDER_TITLES[Gender.UNKNOWN],
] as const;
export type GenderTitle = typeof Titles[number];

export const Genders = Object.values(Gender);
export const GendersUnknown = Object.values(GENDER_TITLES[Gender.UNKNOWN]);

const genderMapping = Object.fromEntries(
  Object.entries(GENDER_TITLES).flatMap(([key, titleList]) => titleList.map(title => [title, key as Gender])),
) as Record<GenderTitle, Gender>;

export const genderTransformer = {
  from: (value: string | null): Gender => (value ? genderMapping[value as never] || Gender.UNKNOWN : Gender.UNKNOWN),
  to: (value: Gender | null): string | null => (value ? value.valueOf() : Gender.UNKNOWN),
};
