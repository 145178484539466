import { IsNumber, IsOptional, ValidateNested } from '@ch-apptitude-icc/common/shared/validation';
import { Type } from 'class-transformer';
import { Analysis } from './analysis';
import { Catalogue } from './catalogue';

export class CatalogueAnalysis {
  @ValidateNested()
  @Type(() => Analysis)
  analysis?: Analysis;

  @ValidateNested()
  @Type(() => Catalogue)
  catalogue?: Catalogue;

  @IsNumber()
  @IsOptional()
  lineOrder?: number | null;
}
