import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { OmitType } from '@ch-apptitude-icc/common/shared/type-utils';
import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { Material as MaterialBack } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';
import { Transform, TransformationType } from 'class-transformer';

export class MaterialFront extends OmitType(MaterialBack, ['id']) implements Entity<string> {
  @Transform(({ value, type }) => {
    if (type === TransformationType.CLASS_TO_PLAIN) {
      return Number(value);
    }
    return String(value);
  })
  id!: string;
}

const api = clients.materials(MaterialFront);

export class MaterialApi extends ApiFactory<MaterialFront, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}
