import { Button } from '@features/ui/components';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

type ContentHeaderProps = {
  backLink?: boolean;
  rightElement?: ReactNode;
  subtitle?: string;
  title: string | ReactNode;
  upperTitle?: string;
};

export const ContentHeader = ({
  title,
  subtitle,
  upperTitle,
  rightElement,
  backLink,
}: ContentHeaderProps): JSX.Element => {
  const router = useRouter();

  return (
    <div className="flex min-h-[9rem] flex-col flex-wrap gap-4 px-4 py-2 md:flex-row md:items-center md:px-0">
      <div className="flex flex-col items-start">
        {upperTitle &&
          (backLink ? (
            <Button
              label={upperTitle}
              variant="header-link"
              onClick={() => router.back()}
              leftIcon="longArrowAltLeft"
              className="pb-2"
            />
          ) : (
            <span className="block pb-2 text-xs uppercase text-white">{upperTitle}</span>
          ))}
        <div className="text-2xl font-bold text-white md:text-3xl">{title}</div>
        {subtitle && <span className="block pt-2 text-xs text-white">{subtitle}</span>}
      </div>

      <div className="flex flex-1 justify-end">{rightElement}</div>
    </div>
  );
};
