import { MaxLength, MinLength } from '@ch-apptitude-icc/common/shared/validation';
import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { type Analysis as AnalysisType } from './analysis';
import { type Doctor as DoctorType } from './doctor';

export class OrderTemplate {
  @PrimaryGeneratedColumn()
  id?: number;

  doctor?: DoctorType;

  @Column({ name: 'doctorId' })
  doctorId!: number;

  @Column({ type: 'varchar', length: 64 })
  @MaxLength(64)
  @MinLength(3)
  templateName?: string;

  analysis?: AnalysisType[];
}
