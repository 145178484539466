import { IsEmail, Matches, MinLength } from '@ch-apptitude-icc/common/shared/validation';

export class ConfirmPasswordResetDto {
  @IsEmail()
  email!: string;

  @Matches(/^[A-Z0-9_-]{30,34}$/i)
  key!: string;

  @MinLength(8)
  newPassword!: string;
}
