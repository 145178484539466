import { Button } from '@features/ui/components';
import { useRouter } from 'next/router';

type HeaderMenuItem = { activeRegex: RegExp; label: string; url: string };

type HeaderMenuProps = {
  items: HeaderMenuItem[];
};

export const HeaderMenu = ({ items }: HeaderMenuProps): JSX.Element => {
  const router = useRouter();

  return (
    <div className="flex flex-col gap-4 md:flex-row">
      {items.map(({ label, url, activeRegex }) => (
        <Button label={label} url={url} key={label} variant="transparent" active={activeRegex.test(router.pathname)} />
      ))}
    </div>
  );
};
