/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any,@typescript-eslint/ban-types */

interface Type<T = any> extends Function {
  new (...args: any[]): T;
}

const existingTypes: Map<Type, Map<string, Type>> = new Map();

/**
 * This function creates a new entity derived from an existing entity, adding a suffix to the column names.
 *
 * It should only be used for embedded columns mapping, and only in the @Column decorator. The field type
 * should still be the original field type.
 *
 * @param SourceType
 * @param suffix
 * @constructor
 */
export function WithSuffix<T extends Object>(SourceType: Type<T>, suffix: string): Type<T> {
  // Check if the type already exists
  if (!existingTypes.has(SourceType)) {
    existingTypes.set(SourceType, new Map());
  }

  if (existingTypes.get(SourceType)?.has(suffix)) {
    return existingTypes.get(SourceType)!.get(suffix)! as Type<T>;
  }

  let SuffixedType: any;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parent = Object.getPrototypeOf(SourceType);
  if (parent) {
    const parentClass = WithSuffix(parent, suffix);

    abstract class SuffixedTypeWithParent extends parentClass {}

    SuffixedType = SuffixedTypeWithParent;
  } else {
    abstract class SuffixedTypeNoParent {}

    SuffixedType = SuffixedTypeNoParent;
  }

  // Change the name of the class dynamically
  Object.defineProperty(SuffixedType, 'name', {
    value: `${SourceType.name}${suffix}`,
  });

  let storage: import('typeorm/metadata-args/MetadataArgsStorage').MetadataArgsStorage;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require,@typescript-eslint/no-unsafe-assignment
    storage = require('typeorm').getMetadataArgsStorage();
  } catch (e) {
    return SuffixedType as Type<T>;
  }

  // Clone the Entity metadata, adding a suffix to the column name
  if (storage) {
    storage.filterColumns(SourceType).forEach(data => {
      storage.columns.push({
        ...data,

        options: { ...data.options, name: `${data.options.name ?? ''}${suffix}` },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        target: SuffixedType,
      });
    });

    storage.filterEmbeddeds(SourceType).forEach(data => {
      storage.embeddeds.push({
        ...data,

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        target: SuffixedType,
        type: () => WithSuffix(data.type() as Type, suffix),
      });
    });
  }
  return SuffixedType as Type<T>;
}
