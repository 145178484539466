// this could be set in multiple files

import { AUTH_ENDPOINTS } from '@ch-apptitude-icc/common/shared/entities';

/**
 * Endpoints for the different entities.
 *
 * Note that the nested values do not mean that they are just concatenated,
 * it could use a dynamic value in between (e.g. `/users/<id>/groups`)
 */
export const ENDPOINTS = Object.freeze({ auth: AUTH_ENDPOINTS.lablink });
