import { Popover, Transition } from '@headlessui/react';
import classnames from 'classnames';
import { Fragment, useState } from 'react';

type TooltipProps = {
  children: React.ReactNode;
  className?: string;
  position?: 'left' | 'right' | 'top' | 'bottom';
  content: React.ReactNode;
};

export const Tooltip = ({ position, children, className, content }: TooltipProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Popover
      className="max-w-96 relative inline "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Popover.Button as="div" className="inline">
        {children}
      </Popover.Button>

      <Transition show={isHovered} as={Fragment}>
        <Popover.Panel
          className={classnames(
            'bg-brand-black absolute z-10 grid w-48 content-center rounded-md py-[2px] px-3 text-center text-white ',
            className,
            { 'bottom-full left-2/4 mb-1 -translate-x-1/2': !position || position === 'top' },
            { 'top-full right-2/4 mt-1 translate-x-1/2': position === 'bottom' },
            { 'right-full mr-1 -translate-y-1/2': position === 'left' },
            { 'left-full bottom-full ml-1 translate-y-1/2': position === 'right' },
          )}
        >
          {content}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
