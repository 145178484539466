import { AnalysisSector, OrderBilledTo, OrderedAnalysis } from '@ch-apptitude-icc/lablink/shared/entities';
import { convertErrorsToFormik, FormikEntityWrapper } from '@common/frontend/components/ui-forms/FormikEntityWrapper';
import { OrderCard } from '@features/orders/components';
import { OrderFormikWrapper } from '@features/orders/components/detail/order/OrderFormikWrapper';
import { useAvailablePricingCategories } from '@features/orders/services/useAvailablePricingCategories';
import {
  selectFromStep,
  selectInsurerIsThirdPartyPayer,
  selectOrderExtraInsurer,
} from '@features/orders/store/OrderState/selectors';
import { setOrderEditingStep, setOrderStepData, setOrderStepStatus } from '@features/orders/store/OrderState/slice';
import {
  CreateOrderStepInvoicing,
  CreateOrderStepOrder,
  CreateOrderStepOrderSecondDoctor,
} from '@features/orders/types/CreateOrderStepOrder';
import { ButtonTab, ButtonTabs, Checkbox, Icon, Input, TextArea, Toast, Tooltip } from '@features/ui/components';
import { Skeleton } from '@features/ui/components/Skeleton';
import { useApi } from '@services/api';
import { useAppDispatch, useAppSelector } from '@services/store/hooks';
import { DeepKeys } from '@tanstack/react-table';
import { getTWBreakpoint } from '@utils/tailwind';
import classnames from 'classnames';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { CopyToDoctorForm } from './order/CopyToDoctorForm';
import { InsuranceForm } from './order/InsuranceForm';
import { OrderPatientForm } from './order/OrderPatientForm';
import { OtherForm } from './order/OtherForm';

export function OrderOrder(): JSX.Element {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const orderData = useAppSelector(selectFromStep.order.selectData);
  const serverErrors = useAppSelector(selectFromStep.order.selectErrors);
  const serverErrorsAsFormik = convertErrorsToFormik(serverErrors, t);

  const isEditing = useAppSelector(selectFromStep.order.selectIsEditing);
  const insurer = useAppSelector(selectOrderExtraInsurer);

  return (
    <FormikEntityWrapper<CreateOrderStepOrder>
      type={CreateOrderStepOrder}
      initialValues={{ ...orderData, billedTo: orderData.billedTo ?? OrderBilledTo.INSURANCE }}
      initialErrors={serverErrorsAsFormik}
      initialTouched={serverErrorsAsFormik}
      onSubmit={values => {
        dispatch(setOrderStepData(values));
        dispatch(setOrderEditingStep('material'));
        dispatch(setOrderStepStatus({ key: 'order', status: 'done' }));
      }}
      validate={values => CreateOrderStepOrder.validate(values, insurer)}
      enableReinitialize
    >
      <Form id="order">
        <OrderCard icon="receipt" stepKey="order" title={t('common.order_one')}>
          {isEditing ? <OrderOrderFormFields /> : <OrderOrderContentShort />}
        </OrderCard>
      </Form>
    </FormikEntityWrapper>
  );
}

const errorIcon = <Icon icon="exclamationCircle" className="rounded-full bg-white text-red-600" />;
const OrderRow = ({ children }: Pick<HTMLAttributes<HTMLDivElement>, 'children'>) => (
  <div className="flex flex-col gap-2 md:flex-row">{children}</div>
);

function useCheckPricingsRequireMandatorBilling() {
  const analyses: OrderedAnalysis[] = useAppSelector(selectFromStep.analysis.selectData).requestedAnalysis;
  const pricingCategories = useAvailablePricingCategories();

  return useMemo(() => {
    if (!analyses || !pricingCategories) {
      return false;
    }

    const selectedPricingCategoriesIds = new Set(analyses.map(analysis => analysis.pricingCategoryId));
    const selectedPricingCategories = [...selectedPricingCategoriesIds].map(pricingCategoryId =>
      pricingCategoryId ? pricingCategories[pricingCategoryId] : undefined,
    );

    // Returns true if one of the selected pricing categories has forceMandatorBilling
    return !!selectedPricingCategories.find(pricingCategory =>
      pricingCategory ? pricingCategory.forceMandatorBilling : false,
    );
  }, [analyses, pricingCategories]);
}

function useAnalysesShouldSuggestMedicationRemarks(): boolean {
  const analyses: OrderedAnalysis[] = useAppSelector(selectFromStep.analysis.selectData).requestedAnalysis;
  const { analysisApi } = useApi();
  const allAnalyses = analysisApi.useGetMap();

  return useMemo(() => {
    if (!analyses || !allAnalyses.data) {
      return false;
    }

    return analyses
      .map(ana => allAnalyses.data.get(ana.analysisId))
      .some(ana => ana?.sector === AnalysisSector.BIOMONITORING || ana?.sector === AnalysisSector.TOXICOLOGY);
  }, [analyses, allAnalyses.data]);
}

const TabComponent = ({ children, title }: { children: ReactNode; title: string }) => (
  <>
    <div className="text-brand-black py-5 text-lg">{title}</div>

    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:w-3/4">{children}</div>
  </>
);
const RowFirstElementClasses = 'md:min-w-[300px] lg:min-w-[400px]';

function OrderOrderFormFields(): JSX.Element {
  const { t } = useTranslation('common');
  const { values, setFieldValue, errors } = useFormikContext<CreateOrderStepOrder>();
  const thirdPartyPayer = useAppSelector(selectInsurerIsThirdPartyPayer);

  const anonymousPatient = !!useAppSelector(selectFromStep.patient.selectData).patient?.anonymous;

  const { authApi } = useApi();
  const me = authApi.useGetMe();
  const analysesRequireMandatorBilling = useCheckPricingsRequireMandatorBilling();
  const suggestMedicationRemarks = useAnalysesShouldSuggestMedicationRemarks();
  const requireMandatorBilling = useMemo(
    () => analysesRequireMandatorBilling || anonymousPatient || (me.data?.doctor?.forceMandatorBilling ?? false),
    [me.data, analysesRequireMandatorBilling, anonymousPatient],
  );

  useEffect(() => {
    // When the 'second doctor data' checkbox is ticked, make sure to create a second doctor data with mandatory
    // fields set to null so that they can be touched later by formik to display errors
    if (values.sendCopyToSecondDoctor && !values.secondDoctorData) {
      void setFieldValue('secondDoctorData', new CreateOrderStepOrderSecondDoctor());
    } else if (!values.sendCopyToSecondDoctor) {
      void setFieldValue('secondDoctorData', null);
    }
  }, [setFieldValue, values.sendCopyToSecondDoctor, values.secondDoctorData]);

  useEffect(() => {
    if (requireMandatorBilling) {
      void setFieldValue('billedTo', OrderBilledTo.DOCTOR);
    }
  }, [requireMandatorBilling, setFieldValue]);

  const hasError = useCallback(
    (...fields: DeepKeys<CreateOrderStepOrder>[]) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const fieldInErrors = (field: string, errors: object): boolean => {
        const [head, ...tail] = field.split('.');

        if (typeof errors === 'object' && head in errors) {
          if (tail.length === 0) {
            return true;
          }
          return fieldInErrors(tail.join('.'), errors[head as keyof typeof errors] as object);
        }
        return false;
      };

      return !!fields.find(field => fieldInErrors(field, errors));
    },
    [errors],
  );

  const tabs: Array<ButtonTab<OrderBilledTo>> = useMemo(() => {
    let tooltipContent = '';
    if (anonymousPatient) {
      tooltipContent = t('pages.order.order.onlyMandatorAvailableForPatient');
    } else if (analysesRequireMandatorBilling) {
      tooltipContent = t('pages.order.order.onlyMandatorAvailableForAnalyses');
    } else {
      tooltipContent = t('pages.order.order.onlyMandatorAvailableForMandator');
    }
    const container = requireMandatorBilling
      ? (button: JSX.Element) => (
          <Tooltip content={tooltipContent} position="top">
            {button}
          </Tooltip>
        )
      : undefined;

    const base: Array<ButtonTab<OrderBilledTo>> = [
      {
        button: {
          label: t('common.insurance'),
          leftIcon: 'hospital',
          autoFocus: true,
          topRightIcon: hasError(
            'patient.insurerId',
            'patient.socialSecurityNumber',
            'patient.emailAddress',
            'patient.telephoneNumber',
            'patient.address',
          )
            ? errorIcon
            : undefined,
          container,
        },
        component: (
          <TabComponent
            title={
              thirdPartyPayer
                ? t('pages.order.order.insuranceTitleWithThirdPartyPayer')
                : t('pages.order.order.insuranceTitleWithoutThirdPartyPayer')
            }
          >
            <InsuranceForm />
          </TabComponent>
        ),
        tabId: OrderBilledTo.INSURANCE,
        disabled: requireMandatorBilling,
      },
      {
        button: {
          label: t('common.patient'),
          leftIcon: 'user',
          topRightIcon: hasError('patient.address') ? errorIcon : undefined,
          container,
        },
        component: (
          <TabComponent title={t('pages.order.order.patientTitle')}>
            <OrderPatientForm />
          </TabComponent>
        ),
        tabId: OrderBilledTo.PATIENT,
        disabled: requireMandatorBilling,
      },
    ];

    if (me.data && (me.data.doctor?.allowMandatorBilling || requireMandatorBilling)) {
      base.push({
        button: {
          label: t('pages.order.order.prescriber'),
          leftIcon: 'userDoctor',
        },
        component: (
          <TabComponent title={t('pages.order.order.prescriberTitle')}>
            {me.data ? (
              <Input
                disabled
                type="text"
                value={`${me.data.doctor?.firstname ?? ''} ${me.data.doctor?.lastname ?? ''}`.trim()}
              />
            ) : (
              <Skeleton />
            )}
          </TabComponent>
        ),
        tabId: OrderBilledTo.DOCTOR,
      });
    }

    base.push({
      button: {
        label: t('common.other'),
        leftIcon: 'ellipsis',
        topRightIcon: hasError('invoicing') ? errorIcon : undefined,
        container,
      },
      component: (
        <TabComponent title={t('pages.order.order.otherTitle')}>
          <OtherForm />
        </TabComponent>
      ),
      tabId: OrderBilledTo.OTHER_BILLING,
      disabled: requireMandatorBilling,
    });

    return base;
  }, [t, me.data, thirdPartyPayer, requireMandatorBilling, hasError, analysesRequireMandatorBilling, anonymousPatient]);

  return (
    <div className="flex flex-col gap-y-6 gap-x-4">
      <OrderRow>
        <div className={classnames('whitespace-nowrap text-sm text-gray-500', RowFirstElementClasses)}>
          {t('pages.order.order.invoiceToDots')}
          <span className="text-red-400"> *</span>
        </div>

        <ButtonTabs
          btnGroup={{ breakpointWidth: getTWBreakpoint('sm', { asPx: true }) }}
          selected={values?.billedTo || false}
          onChange={tabId => {
            if (tabId === OrderBilledTo.OTHER_BILLING && !values.invoicing) {
              void setFieldValue('invoicing', new CreateOrderStepInvoicing());
            } else if (tabId !== OrderBilledTo.OTHER_BILLING) {
              void setFieldValue('invoicing', null);
            }
            void setFieldValue('billedTo', tabId);
          }}
          tabs={tabs}
        />
      </OrderRow>

      <OrderFormikWrapper className={RowFirstElementClasses} inline name="urgent">
        <Checkbox>{t('pages.order.order.urgentAnalysis')}</Checkbox>
      </OrderFormikWrapper>

      <OrderFormikWrapper className={RowFirstElementClasses} inline name="isAddition">
        <Checkbox>{t('pages.order.order.isAddition')}</Checkbox>
      </OrderFormikWrapper>

      <OrderRow>
        <OrderFormikWrapper className={RowFirstElementClasses} inline name="copyToPatient">
          <Checkbox>{t('pages.order.order.sendEmailToPatient')}</Checkbox>
        </OrderFormikWrapper>

        {values.copyToPatient && (
          <div className="w-full">
            <div className="lg:w-3/4">
              <OrderFormikWrapper className="md:w-1/2" inline name="patient.emailAddress" required>
                <Input type="email" />
              </OrderFormikWrapper>
            </div>
          </div>
        )}
      </OrderRow>

      <OrderRow>
        <OrderFormikWrapper className={RowFirstElementClasses} inline name="sendCopyToSecondDoctor">
          <Checkbox>{t('pages.order.order.sendCopyTo')}</Checkbox>
        </OrderFormikWrapper>

        {values.sendCopyToSecondDoctor && (
          <div className="w-full">
            <CopyToDoctorForm />
          </div>
        )}
      </OrderRow>

      {suggestMedicationRemarks && (
        <Toast
          title={t('pages.order.order.medicationTitle')}
          variant="info"
          message={t('pages.order.order.medicationBody')}
        />
      )}

      <OrderFormikWrapper label={t('pages.order.order.orderRemark')} name="remark" className="lg:w-1/2">
        <TextArea resize="vertical" />
      </OrderFormikWrapper>
    </div>
  );
}

function OrderOrderContentShort() {
  const { insurerApi } = useApi();
  const { billedTo, copyToPatient, patient, urgent, secondDoctorData, sendCopyToSecondDoctor, remark, isAddition } =
    useAppSelector(selectFromStep.order.selectData);
  const { t } = useTranslation();

  const insurer = insurerApi.useGetOne(patient?.insurerId || 0, {
    enabled: billedTo === OrderBilledTo.INSURANCE && !!patient?.insurerId,
  });

  let invoiceTo: ReactNode | undefined;
  switch (billedTo) {
    case OrderBilledTo.INSURANCE:
      // TODO ID-364: get name depending on the current language
      invoiceTo = (
        <>
          {t('common.insurance')} {insurer.data && <span className="italic">({insurer.data.nameFrench})</span>}
        </>
      );
      break;

    case OrderBilledTo.PATIENT:
      invoiceTo = t('common.patient');
      break;

    case OrderBilledTo.DOCTOR:
      invoiceTo = t('pages.order.order.prescriber');
      break;

    case OrderBilledTo.OTHER_BILLING:
      invoiceTo = t('common.other');
      break;

    default:
  }

  return (
    <div className="flex flex-col gap-y-4 text-sm text-gray-900">
      {invoiceTo && (
        <span>
          {t('pages.order.order.invoiceTo')} {invoiceTo}
        </span>
      )}

      {urgent && <span>{t('pages.order.order.urgentAnalysis')}</span>}

      {isAddition && <span>{t('pages.order.order.isAddition')}</span>}

      {copyToPatient && <span>{t('pages.order.order.closedInfos.sendCopyToPatient')}</span>}
      {sendCopyToSecondDoctor && secondDoctorData && (
        <span>
          {t('pages.order.order.closedInfos.sendCopyToSecondDoctor')} ({secondDoctorData.firstname}{' '}
          {secondDoctorData.lastname})
        </span>
      )}
      {remark && (
        <span>
          {t('pages.order.order.orderRemark')}: &quot;{remark}&quot;
        </span>
      )}
    </div>
  );
}
