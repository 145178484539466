import { OrderButtonSave } from '@features/orders/components/detail/OrderButtonSave';
import { selectEditingStep, selectFromStep, selectIsReadonly } from '@features/orders/store/OrderState/selectors';
import { setOrderEditingStep } from '@features/orders/store/OrderState/slice';
import { OrderStepKeys, OrderStepKeysOrdering } from '@features/orders/store/OrderState/types';
import { CreateOrderStepPatient } from '@features/orders/types/CreateOrderStepPatient';
import { Button, Card, FooterDisplay, Icon, IconType, Toast } from '@features/ui/components';
import { useAppDispatch, useAppSelector } from '@services/store/hooks';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

function isPatientFormData(value: unknown): value is CreateOrderStepPatient {
  return typeof value === 'object' && value !== null && 'patient' in value;
}

type OrderCardProps = {
  children?: ReactNode;
  /**
   * Make the card editable
   *
   * `true` overrides internal logic
   *
   * @default false
   */
  editable?: boolean;
  icon: IconType;
  stepKey: OrderStepKeys;
  /**
   * The next or save button.
   * Overrides internal one
   */
  submitButton?: ReactNode;
  title: ReactNode;
};

export const OrderCard = ({
  children,
  editable = false,
  icon,
  stepKey,
  submitButton,
  title,
}: OrderCardProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const selectStep = selectFromStep[stepKey];
  const isEditing = useAppSelector(selectStep.selectIsEditing);
  const selectedStep = useAppSelector(selectEditingStep);
  const status = useAppSelector(selectStep.selectStatus);
  const isReadonly = useAppSelector(selectIsReadonly) && !editable;

  const { isValid, values } = useFormikContext();

  let patientSelected = false;
  if (isPatientFormData(values)) {
    patientSelected = !!values.patient;
  }

  const stepsConfig: { [key in OrderStepKeys]: { footerDisplay: FooterDisplay } } = {
    patient: {
      footerDisplay: patientSelected ? FooterDisplay.RIGHT_SIDE : FooterDisplay.NONE,
    },
    analysis: {
      footerDisplay: FooterDisplay.BOTTOM,
    },
    order: {
      footerDisplay: FooterDisplay.RIGHT_SIDE,
    },
    material: {
      footerDisplay: FooterDisplay.BOTTOM,
    },
  };

  const { t } = useTranslation();

  const lastStep: typeof stepKey = 'material';

  const saveButton = (
    <div className="flex flex-col  gap-3 md:flex-row">
      <OrderButtonSave btnDraft={{ variant: 'primary-transparent-outline' }} />
    </div>
  );

  const nextStepButton = <Button label={t('common.next')} leftIcon="arrowRight" type="submit" />;

  const nextButton = isEditing && (submitButton ?? (stepKey === lastStep ? saveButton : nextStepButton));
  const editButton = (!selectedStep ||
    OrderStepKeysOrdering.indexOf(stepKey) <= OrderStepKeysOrdering.indexOf(selectedStep)) && (
    <Button
      icon="penToSquare"
      variant="primary-transparent-outline"
      className={classNames({ hidden: isEditing })}
      onClick={() => dispatch(setOrderEditingStep(stepKey))}
    />
  );

  const header = (
    <div className="flex flex-row items-center">
      <Icon icon={icon} className="h-5" />

      <span className="block flex-1 pl-4 text-2xl text-gray-800">{title}</span>

      {!isReadonly && (
        <>
          {nextButton} {editButton}
        </>
      )}
    </div>
  );

  const footer = <div className="flex justify-end">{nextButton}</div>;
  const { footerDisplay } = stepsConfig[stepKey];

  const opened = isEditing || status !== 'untouched';

  return (
    <Card
      className={classNames('w-full', { 'pb-6': opened })}
      header={header}
      footer={footer}
      footerDisplay={footerDisplay}
    >
      {opened && (
        <div className="flex flex-col gap-y-5 ">
          {children}

          {/* Show the errors if: - the step is being edited, the step has been open, the whole order is editable (!readonly) */}
          {!isEditing && !isValid && !isReadonly && <Toast title={t('pages.order.step.in-error')} variant="error" />}
        </div>
      )}
    </Card>
  );
};
