import { FindAllRequest } from '@ch-apptitude-icc/common/shared/api-client';
import { Select } from '@features/ui/components';
import { SelectProps } from '@features/ui/components/form/Select';
import { InsurerFront, useApi } from '@services/api';
import { useTranslation } from 'next-i18next';

export type InsurerSelectProps<Multiple extends boolean | undefined = undefined> = Omit<
  SelectProps<InsurerFront['id'], Multiple>,
  'options'
> & {
  apiParams?: FindAllRequest<InsurerFront>;
};

export const MAX_INSURANCE_LOAD_PARAMS = {
  page: 0,
  pageSize: 10000,
  sorted: { nameFrench: 'ASC' },
} as const satisfies FindAllRequest<InsurerFront>;

export const InsurerSelect = <Multiple extends boolean | undefined = undefined>({
  apiParams = MAX_INSURANCE_LOAD_PARAMS,
  ...props
}: InsurerSelectProps<Multiple>): JSX.Element => {
  const { i18n } = useTranslation('common');
  const { insurerApi } = useApi();
  const list = insurerApi.useGetList(apiParams);

  return (
    <Select
      {...props}
      options={
        list.data
          ? list.data.results.map(insurer => ({
              label: i18n.language === 'DE' ? insurer.nameGerman : insurer.nameFrench,
              value: insurer.id,
            }))
          : []
      }
    />
  );
};
