import { DotPath, XOR } from '@ch-apptitude-icc/common/shared/type-utils';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { ErrorMessage } from './ErrorMessage';

/**
 * @template T is used to force a valid name
 */
export type FormElementWrapperProps<T = string> = {
  children: ReactNode;
  childrenClassName?: string;
  className?: string;
  required?: boolean;
  label?: string;
  inline?: boolean;
} & XOR<{ name: T extends string ? T : T extends object ? DotPath<T> : string }, { names: string[] }>;

/**
 * Wrapper for form elements that adds a label and helper text.
 * It passes down id (name here), required, multiple and error props to the children, there is not need to set them on the children.
 */
export const FormElementWrapper = <T,>({
  label,
  children,
  inline,
  required,
  className,
  childrenClassName,
  name,
  names,
}: FormElementWrapperProps<T>): JSX.Element => (
  <div className={classNames('flex flex-col', className)}>
    {!inline && (
      <label htmlFor={name ?? names!.join(',')} className="block text-sm text-gray-500">
        {label}
        {required ? <span className="text-red-400"> *</span> : null}
      </label>
    )}
    <div
      className={classNames(
        { 'mt-1': !inline, 'flex flex-row gap-1': React.Children.count(children) > 1 },
        childrenClassName,
      )}
    >
      {children}
    </div>
    {(names ?? [name]).map(fieldName => (
      <ErrorMessage key={fieldName} name={fieldName} />
    ))}
  </div>
);
