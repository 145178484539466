import { HasAddress, HasName } from '@ch-apptitude-icc/common/shared/entities';
import { Length } from '@ch-apptitude-icc/common/shared/validation';
import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { Address } from '../embeded';

export class Invoicing extends Address implements HasAddress, HasName {
  @PrimaryGeneratedColumn({ name: 'pkFacturation', type: 'int' })
  readonly id?: number;

  @Column('varchar', { length: 75, name: 'Nom', nullable: true })
  @Length(2, 75)
  lastname!: string | null;

  @Column('varchar', { length: 75, name: 'Prénom', nullable: true })
  @Length(2, 75)
  firstname!: string | null;
}
