import {
  getMetadataStorage,
  inheritPropertyInitializers,
  inheritTransformationMetadata,
  inheritTypeormMetadata,
  inheritValidationMetadata,
} from './type-helpers.utils';
import { MappedType, Type } from '../type';

/**
 *
 * @param classRef
 * @param keys
 * @param exclude if true, will also add an automatic @Exclude annotation for all fields in `keys`, so that they are not sent if still present
 * @constructor
 */
export function OmitType<T, K extends keyof T>(
  classRef: Type<T>,
  keys: readonly K[],
  exclude?: boolean,
): MappedType<Omit<T, typeof keys[number]>> {
  const isInheritedPredicate = (propertyKey: string) => !keys.includes(propertyKey as K);

  abstract class OmitClassType {
    constructor() {
      inheritPropertyInitializers(this, classRef, isInheritedPredicate);
    }
  }

  inheritValidationMetadata(classRef, OmitClassType, isInheritedPredicate);
  inheritTransformationMetadata(classRef, OmitClassType, isInheritedPredicate);
  inheritTypeormMetadata(classRef, OmitClassType, isInheritedPredicate);

  if (exclude) {
    const metadataStorage = getMetadataStorage();
    keys.forEach(key =>
      metadataStorage.addExcludeMetadata({
        target: OmitClassType,
        propertyName: key as string,
        options: {},
      }),
    );
  }

  return OmitClassType as MappedType<Omit<T, typeof keys[number]>>;
}
