export enum OrderSamplingStatus {
  /**
   * Sampling will be done directly by the lab
   */
  SAMPLE_AT_LAB = 'SAMPLE_AT_LAB',

  /**
   * Sample will be done directly at patient's home.
   *
   * @deprecated Unused for now, usage is unclear - reject any use.
   */
  SAMPLE_AT_PATIENT = 'SAMPLE_AT_PATIENT',

  /**
   * Sampling will be done later, the order cannot be sent with status ORDERED
   */
  SAMPLE_LATER = 'SAMPLE_LATER',

  /**
   * The sampling has been done, a sampling date must be present
   */
  SAMPLING_DONE = 'SAMPLING_DONE',
}
