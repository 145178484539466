import {
  IsDefined,
  IsEmail,
  IsInt,
  Length,
  Matches,
  Max,
  MaxDate,
  MaxLength,
  Min,
  MinDate,
  MinLength,
  yupErrorMessage,
} from '@ch-apptitude-icc/common/shared/validation';
import { setLocale } from 'yup';

const t = yupErrorMessage;

export function initYup() {
  setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: 'field_invalid',
      defined: t(IsDefined.validatorName),
      required: t(IsDefined.validatorName),
      notNull: t(IsDefined.validatorName),
    },
    // use functions to generate an error object that includes the value from the schema
    number: {
      min: t(Min.validatorName, ctx => [ctx.min.toString(10)]),
      moreThan: t(Min.validatorName, ctx => [ctx.more.toString(10)]),
      max: t(Max.validatorName, ctx => [ctx.max.toString(10)]),
      lessThan: t(Max.validatorName, ctx => [ctx.less.toString(10)]),
      integer: t(IsInt.validatorName),
    },
    string: {
      matches: t(Matches.validatorName),
      max: t(MaxLength.validatorName, ctx => [ctx.max.toString(10)]),
      min: t(MinLength.validatorName, ctx => [ctx.min.toString(10)]),
      email: t(IsEmail.validatorName),
    },
    array: {
      max: t(MaxLength.validatorName, ctx => [ctx.max.toString(10)]),
      min: t(MinLength.validatorName, ctx => [ctx.min.toString(10)]),
      length: t(Length.validatorName, ctx => [ctx.length.toString(10)]),
    },
    date: {
      max: t(MaxDate.validatorName, ctx => [ctx.max.toString()]),
      min: t(MinDate.validatorName, ctx => [ctx.min.toString()]),
    },
  });
}
