// Used in the app, no import of node package (path, fs, ...)

/** @type {import('tailwindcss').Config['theme']} */
module.exports = {
  colors: {
    // Before adding a new color, check if it's already available in tailwind, if it is, redefine it in the next section
    primary: {
      DEFAULT: '#0e8bab',
    },
    brand: {
      DEFAULT: '#1aa7c2',
      black: '#333333',
    },

    // Here we redefine Tailwind colors so that it's easier to find the name from the hex value from the design
    // In the future, if the design uses Tailwind colors names, we can remove this
    // DO NOT MODIFY a color mapping to a color that is not the same as tailwind
    transparent: 'transparent',
    current: 'currentColor',
    white: '#ffffff',
    black: '#000000',

    amber: {
      500: '#f59e0b',
    },
    cyan: {
      100: '#cffafe',
      700: '#0e7490',
    },
    emerald: {
      100: '#d1fae5',
      800: '#065f46',
    },
    gray: {
      50: '#f9fafb',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      800: '#1f2937',
      900: '#111827',
    },
    violet: { 100: '#ede9fe', 800: '#5b21b6' },
    orange: { 100: '#ffedd5', 800: '#9a3412' },
    sky: { 50: '#f0f9ff', 100: '#e0f2fe', 500: '#0ea5e9', 800: '#075985' },
    green: { 50: '#f0fdf4', 100: '#dcfce7', 400: '#4ade80', 700: '#15803d', 800: '#166534' },
    red: { 50: '#fef2f2', 400: '#f87171', 600: '#dc2626', 700: '#b91c1c', 800: '#991b1b' },
    yellow: { 50: '#fefce8', 400: '#facc15', 700: '#a16207', 800: '#854d0e' },
    blue: { 50: '#eff6ff', 400: '#60a5fa', 700: '#1d4ed8', 800: '#1e40af' },
  },
  extend: {
    fontSize: {
      '2xs': '0.625rem',
    },
    zIndex: {
      modal: 100,
      loadingOverlay: 400,
      toast: 500,
    },
    height: {
      152: '38rem',
    },
    minWidth: {
      32: '8rem',
      52: '13rem',
    },
    maxWidth: {
      52: '13rem',
    },
  },
};
