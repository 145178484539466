import { booleanTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { IsBoolean, IsDate, IsIn, IsOptional, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Type } from 'class-transformer';
import { Column } from 'typeorm';

/**
 * Contains fields linked to Covid-19
 */
export class OrderCovidFields {
  @Column('bit', { default: () => "'b'0''", name: 'CovidPrinted', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  covidPrinted?: boolean;

  @Column('bit', { default: () => "'b'0''", name: 'CovidPrinted1', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  covidPrinted1?: boolean;

  /**
   * Date at which this order was sent using the Covid website
   */
  @Column('datetime', { name: 'DateVisite', nullable: true })
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  orderIssueDate?: Date | null;

  /**
   * Should this test be a PCR test
   */
  @Column('bit', { default: () => "'b'0''", name: 'PCR', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  pcr?: boolean;

  /**
   * Should this test be an antigen test
   */
  @Column('bit', { default: () => "'b'0''", name: 'Antigen', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  antigen?: boolean;

  /**
   * If set, an EU-DCC Covid certificate should be produced.
   */
  @Column('bit', { default: () => "'b'0''", name: 'Certif', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  requestCertificate?: boolean;

  /**
   * If set, a travel attestation should be produced, in French
   */
  @Column('bit', { default: () => "'b'0''", name: 'AttestFR', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  requestTravelAttestationFrench?: boolean;

  /**
   * If set, a travel attestation should be produced, in English
   */
  @Column('bit', { default: () => "'b'0''", name: 'AttestEN', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  requestTravelAttestationEnglish?: boolean;

  /**
   * If set, a serology test should be performed
   */
  @Column('bit', { default: () => "'b'0''", name: 'Serol', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  serology?: boolean;

  @Column('bit', { default: () => "'b'0''", name: 'AttestFRserol', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  requestSerologyAttestationFrench?: boolean;

  @Column('bit', { default: () => "'b'0''", name: 'AttestENserol', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  requestSerologyAttestationEnglish?: boolean;

  @Column('bit', { default: () => "'b'0''", name: 'AttestCNserol', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  requestSerologyAttestationChinese?: boolean;

  /**
   * @deprecated Aside from 5 lines in the database, nothing writes or reads in this field
   */
  @Column('bit', { default: () => "'b'0''", name: 'QueSerol', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  onlyPerformSerology?: boolean;

  @Column('varchar', { length: 20, name: 'NoPasseport', nullable: true })
  @MaxLength(20)
  @IsOptional()
  passportNumber?: string | null;

  @Column('bit', { default: () => "'b'0''", name: 'sympto', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  hasSymptoms?: boolean;

  /**
   * @deprecated It seems new orders always set this to 1 and code that would set it to 0 (canceling an appointment) is commented out
   */
  @Column('bit', { default: () => "'b'0''", name: 'ConfirmRDV', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  confirmsAppointment?: boolean;

  /**
   * The meaning of this field is very unclear.
   *
   * IT LOOKS LIKE:
   *
   *  - C is used for orders passed on /covidicc (orders by third parties)
   *  - F is used for orders passed on /planicovid (ordery by patients directly)
   *  - E was used by an earlier revision of /planicovid ('recdata - Copie.php')
   */
  @Column('varchar', { length: 1, name: 'Site', nullable: true })
  @IsIn(['E', 'F', 'C', null])
  @IsOptional()
  site?: 'E' | 'F' | 'C' | null;

  @Column('varchar', { length: 50, name: 'EmailPharm', nullable: true })
  @MaxLength(50)
  @IsOptional()
  pharmacyEmail?: string | null;
}
