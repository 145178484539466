import { isValidPhoneNumber } from 'libphonenumber-js';
import { TestConfig } from 'yup';
import { yupErrorMessage } from './utils';
import { IsAVSNumber, testAvsNumber } from '../IsAVSNumber';
import { IsPhoneNumber } from '../validators';

export const isPhoneNumber = (): TestConfig<string | undefined | null> => ({
  name: IsPhoneNumber.validatorName,
  message: yupErrorMessage(IsPhoneNumber.validatorName),
  test: value => value == null || isValidPhoneNumber(value, 'CH'),
});

export const isAvsNumber = (): TestConfig<string | undefined | null> => ({
  name: IsAVSNumber.validatorName,
  message: yupErrorMessage(IsAVSNumber.validatorName),
  test: value => value == null || testAvsNumber(value),
});
