import 'reflect-metadata';

import '@features/ui/globals.css';
import { ModalProvider } from '@features/ui/modal/';
import { NextPageWithLayout } from '@features/ui/types/NextPageWithLayout';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { envConfig } from '@root/envConfig';
import nextI18NextConfig from '@root/next-i18next.config';
import { ApiProvider } from '@services/api';
import { ReactQueryProvider } from '@services/reactQuery';
import { storeWrapper } from '@services/store/store';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, ...rest }: AppPropsWithLayout): JSX.Element {
  const getLayout = Component.getLayout ?? (page => page);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { store, props } = storeWrapper.useWrappedStore(rest);

  return (
    <ReduxProvider store={store}>
      <ReactQueryProvider withSSR={false} /* dehydratedState={props.pageProps.dehydratedState} */>
        <ApiProvider>
          <ModalProvider>
            <Head>
              <title>ICC</title>
            </Head>

            {getLayout(
              <>
                {}
                <Component {...props} />
                <ToastContainer />
              </>,
            )}
          </ModalProvider>
        </ApiProvider>
      </ReactQueryProvider>
    </ReduxProvider>
  );
}

const MyAppWithErrorCatcher = ({ Component, ...pageProps }: AppPropsWithLayout): JSX.Element => (
  <Provider config={envConfig.rollbar}>
    <ErrorBoundary>
      {/* @ts-expect-error MyApp is missing some properties apparently, I don't see why */}
      <MyApp Component={Component} pageProps={pageProps} />
    </ErrorBoundary>
  </Provider>
);

export default appWithTranslation(MyAppWithErrorCatcher, nextI18NextConfig);
