/**
 * Describes what the pricing looks like
 */
export enum PricingType {
  /**
   * The pricing is done directly in swiss francs
   */
  CHF = 'chf',

  /**
   * The pricing is done in points according to OFAS base point prices.
   */
  POINTS = 'ofas_points',
}

export const PricingTypes = [PricingType.CHF, PricingType.POINTS];
