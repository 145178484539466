import { OrderedAnalysisInterface } from '@ch-apptitude-icc/common/shared/entities';
import { IsInt, IsNumber, IsOptional } from '@ch-apptitude-icc/common/shared/validation';
import { Exclude } from 'class-transformer';
import { Column, PrimaryGeneratedColumn } from 'typeorm';

import { type Order as OrderType } from './order';

export class OrderedAnalysis implements OrderedAnalysisInterface {
  @PrimaryGeneratedColumn({ name: 'pkCommandeAnalyse', type: 'int' })
  @Exclude()
  readonly id?: number;

  // This should become a foreign key once analysis data has been provisioned
  @Column('int', { name: 'fkAna', nullable: true })
  @IsNumber()
  analysisId!: number;

  readonly order?: OrderType;

  @Column('int', { name: 'pricingCategoryId', nullable: false, default: 9 })
  @IsInt()
  @IsOptional()
  pricingCategoryId?: number;

  readonly samplingMaterialId?: number | null;
}
