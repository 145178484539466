import { isFunction } from 'lodash';
import { isISO8601 } from 'validator';
import { TestConfig } from 'yup';
import { yupErrorMessage } from './utils';
import { MaxDate } from '../MaxDate';
import { MinDate } from '../MinDate';
import { IsDateString } from '../validators';

export const minDate = (date: Date | (() => Date) | string): TestConfig<string | undefined | null> => ({
  name: MinDate.validatorName,
  message: yupErrorMessage(MinDate.validatorName),
  test: value => {
    if (value == null) {
      return true;
    }
    if (typeof date === 'string') {
      return value >= date;
    }
    const validateAgainst = isFunction(date) ? date() : date;
    const validateValue: Date = new Date(value);
    return validateValue.getTime() >= validateAgainst.getTime();
  },
});

export const maxDate = (date: Date | (() => Date) | string): TestConfig<string | undefined | null> => ({
  name: MaxDate.validatorName,
  message: yupErrorMessage(MaxDate.validatorName),
  test: value => {
    if (value == null) {
      return true;
    }
    if (typeof date === 'string') {
      return value <= date;
    }
    const validateAgainst = isFunction(date) ? date() : date;
    const validateValue: Date = new Date(value);
    return validateValue.getTime() <= validateAgainst.getTime();
  },
});

export const isDateString = (): TestConfig<string | undefined | null> => ({
  test: value => value == null || isISO8601(value),
  message: yupErrorMessage(IsDateString.validatorName),
});
