const path = require('path');
const env = process.env.NEXT_PUBLIC_SERVER_ENVIRONMENT || process.env.SERVER_ENVIRONMENT || 'local';

module.exports = {
  i18n: {
    defaultLocale: 'fr',
    locales: ['fr'],
  },
  localePath: path.resolve('./public/locales'),
};
