export const orders = {
  /**
   * Custom validation errors returned as part of the validation of the order (via class-transformer)
   */
  validation: {
    /**
     * Returned when the SAMPLING_LATER order status is selected for an order that is not TO_ORDER, or when the sampling
     * date is missing in a SAMPLING_DONE order.
     */
    cannotSendWithoutSample: 'NoSampleDate',

    /**
     * Returned when trying to update or delete an order with status indicating it has already been transmitted to the lab.
     */
    orderAlreadyInProgress: 'OrderAlreadyInProgress',

    /**
     * Custom error message when analysis list is empty
     */
    analysisListEmpty: 'orders.PleaseSelectAtLeastOne',

    /**
     * Custom error message when sample date is not defined
     */
    requireSampleDate: 'orders.RequireSampleDate',

    /**
     * Custom error message when material is not defined
     */
    requireMaterial: 'orders.RequireMaterial',

    /**
     * Custom error message when patient is not defined
     */
    requirePatient: 'orders.RequirePatient',

    /**
     * The requested pricing is not allowed for the current user
     */
    illegalPricing: 'orders.IllegalPricing',
  },
} as const;
