import { InsurerType } from '@ch-apptitude-icc/common/shared/entities';
import { IsBoolean, IsEnum, IsNumber, IsOptional, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Column, Entity } from 'typeorm';

/**
 * TODO: remove the `@Entity()` label once the relation can be moved out of PatientFields
 */
@Entity('Assurances')
export class Insurer {
  @Column('int', { name: 'pkAssurances', primary: true })
  @IsNumber()
  id!: number;

  @Column({
    name: 'TypeAssureur',
    nullable: false,
    type: 'int',
  })
  @IsEnum(InsurerType)
  type!: InsurerType;

  @Column('varchar', { length: 100, name: 'Assureur', nullable: false })
  @MaxLength(100)
  nameFrench!: string;

  @Column('varchar', { length: 100, name: 'AssureurD', nullable: false })
  @MaxLength(100)
  nameGerman!: string;

  @Column('varchar', { length: 100, name: 'Adresse', nullable: true })
  @MaxLength(100)
  @IsOptional()
  address!: string | null;

  @Column('varchar', { length: 4, name: 'NoPostal', nullable: true })
  @MaxLength(4)
  @IsOptional()
  postcode!: string | null;

  @Column('varchar', { length: 50, name: 'Ville', nullable: true })
  @MaxLength(50)
  @IsOptional()
  city!: string | null;

  @Column('varchar', { length: 15, name: 'GLNassureur', nullable: false })
  @MaxLength(15)
  GLN!: string;

  /**
   * Some insurers are just "proxies" for other insurances that actually handle the prestations.
   *
   * If this column is different from `gln`, it contains the GLN of the actual insurance company.
   */
  @Column('varchar', { length: 15, name: 'GLNdestinataire', nullable: true })
  @MaxLength(15)
  @IsOptional()
  destinationGLN!: string | null;

  /**
   * Some insurers are just "proxies" for other insurances that actually handle the prestations.
   *
   * This column contains the name of the actual insurance company
   */
  @Column('varchar', { length: 100, name: 'Destinataire', nullable: true })
  @MaxLength(100)
  @IsOptional()
  destinationName!: string | null;

  @Column('boolean', { name: 'thirdPartyPayer', default: () => '0' })
  @IsBoolean()
  thirdPartyPayer!: boolean;
}
