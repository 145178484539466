import { OrderStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { CreateOrderFrontStep } from '@features/orders/types/CreateOrderFrontStep';
import { CreateOrderStepAnalysis } from '@features/orders/types/CreateOrderStepAnalysis';
import { CreateOrderStepMaterials } from '@features/orders/types/CreateOrderStepMaterials';
import { CreateOrderStepOrder } from '@features/orders/types/CreateOrderStepOrder';
import { CreateOrderStepPatient } from '@features/orders/types/CreateOrderStepPatient';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@services/store/store';
import { OrderStepKeys, OrderStepStatus } from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const selectOrderState = (state: RootState) => state.orderState;
export const selectExistingOrder = (state: RootState) => state.orderState.existingOrderData;
export const selectData = (state: RootState) => state.orderState.data;
export const selectEditingStep = (state: RootState) => state.orderState.editing;
export const selectServerErrors = (state: RootState) => state.orderState.serverErrors;
/**
 * @warning Use custom selectors in specific usage
 */
export const selectExtraData = (state: RootState) => state.orderState.extra;
export const selectStatus = createSelector(selectData, data => data.status ?? OrderStatus.TO_ORDER);
export const selectSteps = (state: RootState) => state.orderState.steps;

/**
 * Get the request date of the order
 * Always null on creation.
 */
export const selectRequestDate = createSelector(selectExistingOrder, data => data?.requestDate ?? null);
export const selectOrderId = createSelector(selectExistingOrder, data => data?.id);
export const selectJustCreatedId = (state: RootState) => state.orderState.orderJustCreatedId;
export const selectAnalysisSavedToFavorite = (state: RootState) => state.orderState.steps.analysis.savedToFavorite;

const selectErrors =
  <T>(selector: CreateOrderFrontStep<T>) =>
  (errors: string[]) =>
    errors.filter(error => {
      const field = error.split(':')[0];
      return selector.pickedFields.find(
        pickedField => field === pickedField || field?.startsWith(`${String(pickedField)}.`),
      );
    });

export const stepSelector = <T>(selector: CreateOrderFrontStep<T>, name: OrderStepKeys) => ({
  selectData: createSelector(selectData, data => selector.from(data)),
  selectErrors: createSelector(selectServerErrors, selectErrors(selector)),
  selectIsEditing: createSelector(selectEditingStep, editing => editing === name),
  selectStatus: createSelector(selectSteps, steps => steps[name].status),
  selectStep: createSelector(selectSteps, steps => steps[name]),
});

export const selectFromStep = {
  analysis: stepSelector<CreateOrderStepAnalysis>(CreateOrderStepAnalysis, 'analysis'),
  material: stepSelector<CreateOrderStepMaterials>(CreateOrderStepMaterials, 'material'),
  order: stepSelector<CreateOrderStepOrder>(CreateOrderStepOrder, 'order'),
  patient: stepSelector<CreateOrderStepPatient>(CreateOrderStepPatient, 'patient'),
} as const;

// Calculated selectors
export const selectAreOrderSteps = (stepStatus: OrderStepStatus) => (state: RootState) =>
  Object.values(state.orderState.steps).every(step => step.status === stepStatus);

/**
 * Determine if the order is being created
 */
export const selectIsNew = createSelector(selectOrderId, data => !data);
export const selectHasBeenOrdered = createSelector(
  [selectStatus, selectOrderId],
  (status, id) => !!id && status >= OrderStatus.ORDERED,
);
export const selectIsReadonly = selectHasBeenOrdered;

// This selector is not used
export const selectIsValid = createSelector(
  [selectAreOrderSteps('done'), selectServerErrors],
  (done, errors) => done && !Object.keys(errors).length,
);

export const selectIsComplete = (state: RootState) =>
  [state.orderState.steps.order, state.orderState.steps.analysis, state.orderState.steps.patient].every(
    step => step.status === 'done',
  );

// Determined/selected from extra
/**
 * Return ths currently set insurer
 */
export const selectOrderExtraInsurer = createSelector(selectExtraData, extra => extra.insurer);

export const selectInsurerIsThirdPartyPayer = createSelector(
  selectOrderExtraInsurer,
  insurer => insurer?.thirdPartyPayer,
);

export const selectInsurers = createSelector(selectOrderState, data => data.insurersList);
