import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { OrderTemplate } from '@ch-apptitude-icc/lablink/shared/entities';

import { ApiFactory, Keys } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.orderTemplates();

export class OrderTemplateApi extends ApiFactory<
  OrderTemplate,
  typeof api,
  Keys<OrderTemplate>,
  ReturnType<typeof api>
>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}
