import { APIClient, ClientOptions, APIClientInternals } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from '@ch-apptitude-icc/common/shared/type-utils';
import {
  ChangePasswordDto,
  ConfirmPasswordResetDto,
  ResetPasswordRequestDto,
  User,
} from '@ch-apptitude-icc/lablink/shared/entities';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface UsersClient<EntityType extends Entity<unknown>> extends APIClient<EntityType, never> {
  changePassword(request: ChangePasswordDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>>;

  requestPasswordReset(
    email: string | ResetPasswordRequestDto,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<void>>;

  resetPassword(request: ConfirmPasswordResetDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>>;
}

class UsersClientImpl<EntityType extends Entity<unknown>>
  extends APIClientInternals.BaseClient<EntityType>
  implements UsersClient<EntityType>
{
  constructor({ entityType, resourcePath, options }: APIClientInternals.APIClientSchemaBasedExtensions<EntityType>) {
    super(resourcePath, entityType, options);
  }

  requestPasswordReset(
    request: string | ResetPasswordRequestDto,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<void>> {
    return axios.post<void>(
      `${this.resourcePath}/request-password-reset`,
      typeof request === 'string' ? ({ email: request } satisfies ResetPasswordRequestDto) : request,
      { ...this.options, ...options },
    );
  }

  resetPassword(request: ConfirmPasswordResetDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
    return axios.post<void>(`${this.resourcePath}/password-reset`, request, { ...this.options, ...options });
  }

  changePassword(request: ChangePasswordDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
    return axios.patch<void>(`${this.resourcePath}/password`, request, { ...this.options, ...options });
  }
}

const baseUsersClient = APIClientInternals.emptyClient(User, 'users');

export const usersClient =
  <RealEntityType extends Entity<unknown> = User>(overrideEntity?: Type<RealEntityType>) =>
  (optionsIn?: ClientOptions<RealEntityType>): UsersClient<RealEntityType> =>
    new UsersClientImpl<RealEntityType>(baseUsersClient(overrideEntity)(optionsIn));
