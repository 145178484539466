import { registerDecorator, ValidationOptions } from 'class-validator';
import { ValidationArguments } from 'class-validator/types/validation/ValidationArguments';

export function PasswordMustMatch<T>(property: keyof T, validationOptions?: ValidationOptions) {
  return (object: object, propertyName: string): void => {
    registerDecorator({
      constraints: [property],
      name: 'passwordMustMatch',
      options: validationOptions,
      propertyName,
      target: object.constructor,
      validator: {
        defaultMessage(validationArguments?: ValidationArguments): string {
          return `${validationArguments?.property || ''}:MatchPassword`;
        },
        validate(value: unknown, args: ValidationArguments) {
          const relatedValue = (args.object as T)[property];
          return value === relatedValue;
        },
      },
    });
  };
}

Object.defineProperty(PasswordMustMatch, 'validatorName', { value: 'PasswordMustMatch' });
