import { createDateTimeFormatter, DateTimeFormatter } from '@services/formatters/datetime';
import {
  createNumberCurrencyFormatter,
  createNumberFormatter,
  NumberCurrencyFormatter,
  NumberFormatter,
} from '@services/formatters/number';
import { useTranslation } from 'next-i18next';

/**
 * Formatters interface
 *
 * The `f` stands for `format`.
 */
export interface UseFormatters {
  /**
   * Formats a date depending.
   */
  fDate: DateTimeFormatter;
  /**
   * Formats a date with its time.
   */
  fDatetime: DateTimeFormatter;
  /**
   * Formats a currency number (the currency sign is not included).
   */
  fNCurrency: NumberCurrencyFormatter;

  /**
   * Formats a number.
   */
  fNumber: NumberFormatter;
  /**
   * Formats a time only.
   */
  fTime: DateTimeFormatter;
}

/**
 * Hook returning formatters depending on the current translation.
 * @example ```tsx
 * const { fDate } = useFormatters();
 * const now = new Date();
 * return <span>{fDate(now)}</span>;
 * ```
 *
 * @return an object with different formatter functions
 */
export function useFormatters(): UseFormatters {
  const { t } = useTranslation('common');

  return {
    fDate: createDateTimeFormatter(t('common.formats.date')),
    fDatetime: createDateTimeFormatter(t('common.formats.datetime')),
    fTime: createDateTimeFormatter(t('common.formats.time')),

    fNCurrency: createNumberCurrencyFormatter(t('common.formats.locale-currency')),
    fNumber: createNumberFormatter(t('common.formats.locale-number')),
  };
}
