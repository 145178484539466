import { APIClientInternals } from '@ch-apptitude-icc/common/shared/api-client';
import {
  Analysis,
  Catalogue,
  CreateOrderTemplateDto,
  Insurer,
  Material,
  OrderOrReference,
  OrderTemplate,
  Patient,
  ProfaDoctor,
  SamplingMaterial,
  UpdateOrderTemplateDto,
} from '@ch-apptitude-icc/lablink/shared/entities';
import { authClient } from './custom/auth.client';
import { contactClient } from './custom/contact.client';
import { ordersClient } from './custom/orders.client';
import { referencesClient } from './custom/references.client';
import { usersClient } from './custom/users.client';

const { crudClient, listOnlyClient, readOnlyClient } = APIClientInternals;

export const clients = {
  auth: authClient,
  users: usersClient,
  contact: contactClient,
  references: referencesClient,
  profaDoctors: readOnlyClient(ProfaDoctor, 'profa-doctors'),
  analysis: readOnlyClient(Analysis, 'analysis'),
  catalogues: readOnlyClient(Catalogue, 'catalogues'),
  insurers: readOnlyClient(Insurer, 'insurers'),
  materials: readOnlyClient(Material, 'materials'),
  samplingMaterials: readOnlyClient(SamplingMaterial, 'sampling-materials'),
  orderTemplates: crudClient<OrderTemplate, CreateOrderTemplateDto, UpdateOrderTemplateDto>(
    OrderTemplate,
    'order-template',
  ),
  orders: ordersClient,
  ordersAndReferences: listOnlyClient<OrderOrReference>(OrderOrReference, 'orders-and-refs'),
  patients: readOnlyClient(Patient, 'patients'),
} as const;
