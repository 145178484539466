import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { PricingType } from './enums';

export class PricingCategory {
  @PrimaryGeneratedColumn()
  id!: number;

  @Column({ length: '30', type: 'varchar' })
  name!: string;

  @Column({ enum: PricingType, type: 'enum' })
  type!: PricingType;

  @Column('boolean', { name: 'forceMandatorBilling', default: () => 'false' })
  forceMandatorBilling?: boolean;
}
