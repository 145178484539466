import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from '@ch-apptitude-icc/common/shared/type-utils';
import { plainify } from '@ch-apptitude-icc/common/shared/utils';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { DeepPartial } from 'typeorm';
import { DeleteResultDto, FiltersType, FindAllRequest, PaginatedDto, SortedType } from './generic';
import { APIClient, Capability } from './types';
import { BaseAxiosOptions, rename } from './utils';

/**
 * A convenience type to make sure the list in `Capability` always reflect the name of the CRUD methods in the client.
 */
type InternalClientForceType = { [cap in Capability]: unknown };

/**
 * The implementation of the basic HTTP client.
 *
 * Objects passed to this class (via create/update/findAll) are first transformed to plain objects using
 * `instanceToPlain`, then the remaining fields are renamed based on the decorators on the main EntityType class.
 *
 * This means that if you want to implement field transformation logic, you must make sure you pass an **instance of
 * a class** to create/update, with the class in question having all the decorators you need to transform. You can
 * use `@nestjs/mapped-types` as a basis for those (for example, a good CreateDto is `class EntityCreateDto extends OmitType(Entity, ['id']).
 * The advantage of this approach is that you have a class (so you can set decorators on its fields), which already has all the fields
 * you need, and which inherits all the decorators from the parent class. So that's nice.
 *
 * IF YOU ONLY WANT TO RENAME SOME FIELDS, you don't need to make multiple DTOs. As long as your EntityType has the correct
 * `@Expose` annotations, we can detect that there is a field renaming involved and rename all the incoming objects easily.
 *
 * @type EntityType: the type of the entity this client serves
 * @type CreateType: the DTO for creating objects
 * @type UpdateType: the DTO for updating objects
 * @type IdType: the type of the ID field of the entity, automatically derived
 */
export class BaseClient<
  EntityType extends Entity<unknown>,
  CreateType = Omit<EntityType, 'id' & Partial<keyof EntityType>>,
  UpdateType = Partial<CreateType>,
  IdType extends Exclude<EntityType['id'], undefined | null> = Exclude<EntityType['id'], undefined | null>,
> implements InternalClientForceType
{
  constructor(
    public readonly resourcePath: string,
    public readonly entityType?: Type<EntityType>,
    public readonly options?: AxiosRequestConfig,
  ) {}

  readonly encodeToBackend = (
    entity?: EntityType | CreateType | UpdateType | FiltersType<EntityType> | SortedType<EntityType>,
  ) => (this.entityType ? rename(this.entityType, instanceToPlain(entity)) : instanceToPlain(entity));

  readonly decodeFromBackend: <T extends EntityType | EntityType[]>(
    e: T,
  ) => T extends EntityType[] ? EntityType[] : EntityType = (entity: EntityType | EntityType[]) =>
    // `as never` is used to ignore typechecking on these two expressions -- the typing is trivial and should work
    this.entityType ? plainify(plainToInstance(this.entityType, entity) as never) : (entity as never);

  readonly create = (entity: CreateType, options?: AxiosRequestConfig): Promise<AxiosResponse<EntityType>> =>
    axios
      .post<EntityType>(this.resourcePath, this.encodeToBackend(entity), {
        ...this.options,
        ...options,
      })
      .then(response => ({
        ...response,
        data: this.decodeFromBackend(response.data),
      }));

  readonly update = async (
    id: IdType,
    entity: UpdateType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EntityType>> => {
    const path = `${this.resourcePath}/${String(id)}`;
    const response = await axios.patch<EntityType>(path, this.encodeToBackend(entity), {
      ...this.options,
      ...options,
    });
    return { ...response, data: this.decodeFromBackend(response.data) };
  };

  readonly delete = (id: IdType, options?: AxiosRequestConfig): Promise<AxiosResponse<DeleteResultDto>> => {
    const path = `${this.resourcePath}/${String(id)}`;
    return axios.delete<DeleteResultDto>(path, { ...this.options, ...options });
  };

  readonly findAll = async (
    request?: FindAllRequest<EntityType>,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<PaginatedDto<EntityType>>> => {
    const rq = { ...request };
    rq.filtered = this.encodeToBackend(request?.filtered) as FiltersType<EntityType>;
    rq.sorted = this.encodeToBackend(request?.sorted) as SortedType<EntityType>;

    return axios
      .get<PaginatedDto<EntityType>>(this.resourcePath, {
        ...this.options,
        ...options,
        params: rq,
      })
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          // Transform to an object using class-transformer then back to plain (but with the transforms still applied)
          results: this.decodeFromBackend(response.data.results),
        },
      }));
  };

  readonly findOne = async (id: IdType, options?: AxiosRequestConfig): Promise<AxiosResponse<EntityType>> => {
    const path = `${this.resourcePath}/${String(id)}`;

    return (
      axios
        .get<EntityType>(path, { ...this.options, ...options })
        // Transform to an object using class-transformer then back to plain (but with the transforms still applied)
        .then(response => ({ ...response, data: this.decodeFromBackend(response.data) }))
    );
  };
}

export type APIClientSchemaBasedExtensions<EntityType> = {
  readonly entityType?: Type<EntityType>;
  readonly options?: AxiosRequestConfig;
  readonly resourcePath: string;
};

export type ClientOptions<T> = AxiosRequestConfig<T>;

/**
 * Do not export further!
 * @param capabilities
 */
export const clientFor =
  <CapType extends Partial<Capability>>(capabilities: ReadonlyArray<CapType>) =>
  <
    EntityType extends Entity<unknown>,
    CreateType extends Omit<EntityType, keyof EntityType> = Omit<EntityType, 'id'>,
    UpdateType extends DeepPartial<CreateType> = DeepPartial<CreateType>,
  >(
    t: Type<EntityType>,
    resourcePath: string,
  ) =>
  <
    RealEntityType extends Entity<unknown> = EntityType,
    RealCreateType extends Omit<RealEntityType, keyof RealEntityType> = CreateType extends Omit<
      RealEntityType,
      keyof RealEntityType
    >
      ? CreateType
      : Omit<RealEntityType, 'id'>,
    RealUpdateType extends DeepPartial<RealCreateType> = UpdateType extends DeepPartial<RealCreateType>
      ? UpdateType
      : DeepPartial<RealCreateType>,
  >(
    overrideEntity?: Type<RealEntityType>,
  ) =>
  (
    optionsIn?: ClientOptions<RealEntityType>,
  ): APIClient<RealEntityType, CapType, RealCreateType, RealUpdateType> &
    APIClientSchemaBasedExtensions<RealEntityType> => {
    const options: AxiosRequestConfig = {
      ...BaseAxiosOptions,
      // Default options
      ...optionsIn,
    };

    const entityType: Type<RealEntityType> = overrideEntity ?? (t as unknown as Type<RealEntityType>);
    const client = new BaseClient(resourcePath, entityType, options);

    Object.getOwnPropertyNames(BaseClient.prototype)
      .filter(v => v !== 'constructor')
      .filter(v => !capabilities.includes(v as CapType))
      .forEach(v => {
        client[v as CapType] = () => {
          throw new Error(`Method ${v} not implemented.`);
        };
      });

    return client as APIClient<RealEntityType, CapType, RealCreateType, RealUpdateType> &
      APIClientSchemaBasedExtensions<RealEntityType>;
  };

// Convenience types
export const crudClient = clientFor(['create', 'update', 'findAll', 'findOne', 'delete']);
export const readOnlyClient = clientFor(['findAll', 'findOne']);
export const listOnlyClient = clientFor(['findAll']);
export const emptyClient = clientFor<never>([] as never[]);
