import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { Button, ButtonProps } from './Button';

type DropdownItem = Omit<ButtonProps, 'variant'>;

type DropdownProps = { items: DropdownItem[]; label: ReactNode; open?: boolean; width?: string };

export const Dropdown = ({ label, open, items, width }: DropdownProps): JSX.Element => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button as={Fragment}>
        {typeof label === 'string' ? <Button label={label} rightIcon="chevronDown" /> : label}
      </Menu.Button>
    </div>

    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={classNames(
          'absolute right-0 z-10 mt-2 origin-top-right rounded-md border border-gray-200 bg-white shadow-lg focus:outline-none',
          { 'w-fit': !width },
          width,
        )}
      >
        <div className="py-1">
          {items.map(item => (
            <Menu.Item key={item.label}>
              {({ active }) => (
                // @ts-expect-error TS compiler will complain because of exclusive or type but the type is actually right
                <Button {...item} active={active} variant="dropdown-link" />
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);
