import { HEADER_HEIGHT } from '@features/ui/components';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { inter } from './fonts/inter';

export interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  header: React.ReactNode;

  /**
   * Classname for the top positions of the header
   *
   * @default top-0
   */
  headerTop?: `top-${string}`;
}

export const Layout = ({ children, className, header, headerTop = 'top-0' }: LayoutProps): JSX.Element => {
  const { t } = useTranslation('common');

  const scrollRef = useRef({
    /**
     * Last stored top scroll value
     */
    lastTop: 0,

    /**
     * Show the header?
     */
    showHeader: true,
  });

  const [, render] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // For Mobile or negative scrolling
      if (scrollTop < 0) {
        scrollTop = 0;
      }

      const previous = scrollRef.current.showHeader;
      const showHeader =
        // When the direction is up
        scrollTop <= scrollRef.current.lastTop ||
        // When the header top position is visible
        scrollTop <= HEADER_HEIGHT / 2;

      scrollRef.current = {
        lastTop: scrollTop,
        showHeader,
      };

      // Call a render only when the header visibility changes
      if (previous !== showHeader) {
        render(scrollTop);
      }
    };

    // On refresh, the page could already be scrolled => get initial values
    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={classNames('app-layout relative min-h-screen text-white', inter.className, className)}>
      <header
        className={classNames(
          {
            '-translate-y-full': !scrollRef.current.showHeader,
          },
          'bg-brand sticky z-40',
          headerTop,
          'transition-transform duration-300',
        )}
      >
        {header}
      </header>
      <div className="container mx-auto">
        <main className="pb-14">{children}</main>
        <footer className="absolute inset-x-0 bottom-0 flex justify-center p-4">
          <div className="flex gap-x-1 text-xs text-gray-400">
            <span>{t('common.company')}</span>
            <span>©</span>
            <span>{new Date().getFullYear()}</span>
          </div>
        </footer>
      </div>
    </div>
  );
};
