import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { Reference } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.references(Reference);

export class ReferencesApi extends ApiFactory<Reference, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }

  public getReportUrl(referenceId: number): string {
    return this.api.getReportRedirectUrl(referenceId);
  }
}
