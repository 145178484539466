import { Input } from '@features/ui/components';
import { Combobox } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import { Key, useRef } from 'react';

type SearchBarProps<T> = {
  /**
   * Disable the auto-opening of the choices on focus
   */
  disableOpenOnFocus?: boolean;
  isLoading?: boolean;
  keyAccessor: keyof T;
  line?: (option: T) => JSX.Element;
  onChange: (value?: T) => void;
  onQueryChange: (query: string) => void;
  options?: T[];
  query?: string;
  value?: T;
  debounceTime?: number;
  autoFocus?: boolean;
};

export const SearchBar = <T,>({
  value,
  onChange,
  options,
  keyAccessor,
  onQueryChange,
  query,
  disableOpenOnFocus,
  line,
  isLoading,
  debounceTime,
  autoFocus = false,
}: SearchBarProps<T>): JSX.Element => {
  const { t } = useTranslation('common');

  // hack from: https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2385032
  const comboBtn = useRef<HTMLInputElement>(null);

  return (
    <Combobox value={value} onChange={onChange} as="div" className="relative">
      <Combobox.Input
        as={Input}
        type="text"
        iconLeft={{
          className: 'text-primary',
          icon: 'magnifyingGlass',
        }}
        iconRight={
          query ? { className: 'text-gray-300', icon: 'xmarkCircle', onClick: () => onQueryChange('') } : undefined
        }
        placeholder={t('common.searchPlaceholder')}
        value={query}
        onChange={() => {}}
        onDebounce={onQueryChange}
        onFocus={() => !disableOpenOnFocus && comboBtn.current?.click()}
        debounceTime={debounceTime ?? 500}
        autoFocus={autoFocus}
      />

      <Combobox.Button className="hidden" ref={comboBtn as never} />

      <Combobox.Options className="absolute z-20 max-h-60 w-full overflow-auto rounded-b border-x border-b border-gray-200 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {/* Search is too fast so this appears like a glitch, add back if search ever slows down */}
        {/* {isLoading && (options == null || options.length === 0) && (
                <div className="grid place-items-center p-2">
                  <Icon icon="loading" />
                </div>
              )} */}
        {options &&
          options.map(option => (
            <Combobox.Option key={option[keyAccessor] as Key} value={option} className="cursor-pointer">
              {line ? line(option) : JSON.stringify(option)}
            </Combobox.Option>
          ))}
        {!isLoading && options?.length === 0 && <div className="p-2 text-sm font-bold">{t('common.noResults')}</div>}
      </Combobox.Options>
    </Combobox>
  );
};
