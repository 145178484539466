import { SortedType } from '@ch-apptitude-icc/common/shared/api-client';
import { OrderPositiveResult, OrderStatus, ReportStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { OrderActionsDropdown } from '@features/orders/components/table/OrderActionsDropdown';
import { statusToChipsVariant } from '@features/orders/utils/';
import {
  Button,
  Icon,
  SortableHeader,
  SortableHeaderProps,
  Table,
  TableColumn,
  tableColumnHelper,
  TableSort,
  Tooltip,
} from '@features/ui/components';
import { OrderFront, OrderOrReferenceFront, UserFront, useApi } from '@services/api';
import { useFormatters, UseFormatters } from '@services/formatters';
import { getRoute } from '@services/routes';
import { useAppSelector } from '@services/store/hooks';
import { selectSearchString } from '@services/store/pageStateSlice';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

const columnHelper = tableColumnHelper<OrderOrReferenceFront>();

const MaterialCell = ({ materialId }: { materialId: string | null | undefined }): JSX.Element => {
  const { materialApi } = useApi();
  const materials = materialApi.useGetMap();

  if (materialId) {
    const material = materials.data
      ? materials.data.get(materialId)?.name ?? materialId.toUpperCase()
      : materialId.toUpperCase();

    return <div className="font-medium text-gray-800">{material}</div>;
  }

  return <span />;
};

const columns: (
  t: TFunction,
  me: UserFront | undefined,
  sort: TableSort | null,
  setSort: SortableHeaderProps['onSort'],
  fDate: UseFormatters['fDate'],
  fDatetime: UseFormatters['fDatetime'],
  getReportUrl: (referenceId: number) => string,
) => TableColumn<OrderOrReferenceFront> = (t, me, sort, setSort, fDate, fDatetime, getReportUrl) => {
  const columnsDef = [
    columnHelper.accessor({
      // TODO ID-414: merge update
      accessor: order => ({ id: order.id, urgent: order.urgent }),
      column: {
        id: 'OrderNr.',
        header: () => (
          <SortableHeader label={t('pages.orders.table.orderNr')} selector="id" onSort={setSort} currentSort={sort} />
        ),
        cell: info => (
          <span>
            {info.getValue().id}
            {info.getValue().urgent && (
              <Tooltip content={columnHelper.trAsString(t('pages.orders.table.urgentAnalysis'))}>
                <Icon className="ml-1 text-amber-500" icon="lightEmergency" />
              </Tooltip>
            )}
          </span>
        ),
      },
      withClassName: 'w-32',
    }),
    columnHelper.accessor({
      accessor: 'status',
      column: {
        header: () => (
          <SortableHeader
            label={t('pages.orders.table.status')}
            selector="status"
            onSort={setSort}
            currentSort={sort}
          />
        ),
        cell: info => {
          // We need the cast because OrderStatus comes from lablink-common in getValue()
          const value = info.getValue();
          return value != null ? statusToChipsVariant[value](t) : null;
        },
      },
      withClassName: 'w-28',
    }),
    columnHelper.accessor({
      accessor: 'creationDate',
      column: {
        header: () => (
          <SortableHeader
            label={t('pages.orders.table.orderDate')}
            selector="creationDate"
            onSort={setSort}
            currentSort={sort}
          />
        ),
        cell: info => {
          const value = info.getValue();
          if (!value) {
            return undefined;
          }

          return <span className="font-medium text-gray-800">{fDate(new Date(value))}</span>;
        },
      },
      withClassName: 'w-32 hidden lg:table-cell',
    }),
    columnHelper.accessor({
      accessor: 'sampleDate',
      column: {
        header: () => (
          <SortableHeader
            label={t('pages.orders.table.samplingDate')}
            selector="sampleDate"
            onSort={setSort}
            currentSort={sort}
          />
        ),
        cell: info => {
          const value = info.getValue();
          if (!value) {
            return undefined;
          }

          return <span className="font-medium text-gray-800">{fDatetime(new Date(value))}</span>;
        },
      },
      withClassName: 'w-44 hidden lg:table-cell',
    }),
    columnHelper.accessor({
      accessor: 'materialId',
      column: {
        header: () => (
          <SortableHeader
            label={t('pages.orders.table.materialId')}
            selector="materialId"
            onSort={setSort}
            currentSort={sort}
          />
        ),
        cell: info => <MaterialCell materialId={info.getValue()} />,
      },
      withClassName: 'w-44 hidden lg:table-cell',
    }),
    columnHelper.accessor({
      accessor: 'orderUserReference',
      column: {
        header: () => (
          <SortableHeader
            label={t('pages.orders.table.orderUserReference')}
            selector="orderUserReference"
            onSort={setSort}
            currentSort={sort}
          />
        ),
        cell: info => <div className="font-medium text-gray-800">{info.getValue()}</div>,
      },
      withClassName: 'min-w-32',
    }),
  ];

  if (me?.doctor?.enableDepartment) {
    columnsDef.push(
      columnHelper.accessor({
        accessor: 'orderingDepartment',
        column: {
          header: () => (
            <SortableHeader
              label={t('pages.orders.table.orderingDepartment')}
              selector="orderingDepartment"
              onSort={setSort}
              currentSort={sort}
            />
          ),
          cell: info => <div className="font-medium text-gray-800">{info.getValue()}</div>,
        },
        withClassName: 'min-w-32',
      }),
    );
  }

  columnsDef.push(
    ...[
      columnHelper.accessor({
        accessor: 'patient',
        column: {
          header: () => (
            <SortableHeader
              label={t('pages.orders.table.patient')}
              selector="patient.lastname"
              onSort={setSort}
              currentSort={sort}
            />
          ),
          cell: info => (
            <div className="font-medium text-gray-800">
              <span className="uppercase">{info.getValue()?.lastName}</span> {info.getValue()?.firstName}
            </div>
          ),
        },
        withClassName: 'min-w-52',
      }),
      columnHelper.accessor({
        accessor: 'patient.birthDate',
        column: {
          header: () => (
            <SortableHeader
              label={t('pages.orders.table.birthDate')}
              selector="patient.birthDate"
              onSort={setSort}
              currentSort={sort}
            />
          ),
          cell: info => {
            const value = info.getValue();
            if (value) {
              return fDate(new Date(value));
            }

            return undefined;
          },
        },
        withClassName: 'w-32 hidden lg:table-cell',
      }),
      columnHelper.accessor({
        accessor: 'reportStatus',
        column: {
          header: columnHelper.trAsString(t('pages.orders.table.report')),
          cell: info =>
            info.getValue() && info.row.original.referenceId && info.row.original.reportFilePath ? (
              <Button
                label={reportToText(info.getValue(), t)}
                variant="table"
                leftIcon="fileReport"
                newTab
                url={getReportUrl(info.row.original.referenceId)}
                stopPropagation
                unreadNotification={info.row.original.unread}
              />
            ) : null,
        },
        withClassName: 'hidden lg:table-cell w-28',
      }),
      columnHelper.accessor({
        accessor: 'positiveResult',
        column: {
          header: columnHelper.trAsString(t('pages.orders.table.results')),
          cell: info => {
            if (info.getValue() === OrderPositiveResult.POSITIVE) {
              return (
                <Tooltip content={columnHelper.trAsString(t('pages.orders.table.positiveResults'))}>
                  <Icon className="ml-1 text-red-600" icon="hexagonPlus" />
                </Tooltip>
              );
            }
            if (info.getValue() === OrderPositiveResult.OUT_OF_BOUNDS) {
              return (
                <Tooltip content={columnHelper.trAsString(t('pages.orders.table.outOfBoundsResults'))}>
                  <Icon className="ml-1 text-amber-500" icon="hexagonExclamation" />
                </Tooltip>
              );
            }
            return null;
          },
        },
        withClassName: 'hidden lg:table-cell w-16',
      }),
      columnHelper.accessor({
        accessor: 'status',
        column: {
          header: '',
          cell: info => <OrderActionsDropdown orderOrReference={info.row.original} />,
        },
        withClassName: 'hidden lg:table-cell w-12',
      }),
    ],
  );

  return columnsDef;
};

type OrdersTableProps = {
  noResultsItems: string[];
  patientId?: string;
  statusSelection?: OrderStatus[];
};

export const OrdersTable = ({ statusSelection, noResultsItems, patientId }: OrdersTableProps): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const { fDate, fDatetime } = useFormatters();

  const { authApi } = useApi();
  const me = authApi.useGetMe();

  const searchString = useAppSelector(selectSearchString);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sort, setSort] = useState<TableSort | null>(null);

  useEffect(() => {
    setPagination(x => ({ ...x, pageIndex: 0 }));
  }, [sort, searchString, statusSelection, patientId]);

  const sortObject = (key: string, direction: string): SortedType<OrderFront> => {
    if (key.includes('.')) {
      const [currentKey, ...rest] = key.split('.');
      return { [currentKey]: sortObject(rest.join('.'), direction) };
    }
    return { [key]: direction };
  };

  const { orderAndRefApi, referencesApi } = useApi();
  const { data, isLoading, isError } = orderAndRefApi.useGetList({
    filtered: {
      status: statusSelection ? { $in: statusSelection } : undefined,
      patient: patientId ? { id: { $value: patientId } } : undefined,
      searchColumn: searchString ? { $like: `%${searchString}%` } : undefined,
    },
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorted: {
      unread: 'DESC',
      ...(sort ? sortObject(sort.id, sort.direction) : { creationDate: 'DESC' }),
      orderId: 'DESC',
      referenceId: 'DESC',
    },
  });

  const translatedColumns = columns(t, me.data, sort, setSort, fDate, fDatetime, (id: number) =>
    referencesApi.getReportUrl(id),
  );

  return (
    <div className="h-152">
      <Table
        columns={translatedColumns}
        data={data}
        isError={isError}
        isLoading={isLoading}
        pagination={[pagination, setPagination]}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onRowClick={order => order.orderId && router.push(getRoute.orderDetail({ id: order.orderId }).pathname)}
        noResultsItems={noResultsItems}
      />
    </div>
  );
};

function reportToText(reportStatus: ReportStatus | null, t: TFunction): string {
  switch (reportStatus) {
    case ReportStatus.CORRECTED_REPORT:
      return t('pages.orders.report.corrected');
    case ReportStatus.PRELIMINARY_REPORT:
      return t('pages.orders.report.preliminary');
    case ReportStatus.NORMAL_REPORT:
    default:
      return t('pages.orders.report.final');
  }
}
