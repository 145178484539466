import {
  Gender,
  genderTransformer,
  HasAddress,
  HasIdentityData,
  PatientInterface,
} from '@ch-apptitude-icc/common/shared/entities';
import { strings } from '@ch-apptitude-icc/common/shared/utils';
import {
  IsAVSNumber,
  IsBoolean,
  IsEmail,
  IsEnum,
  IsNumber,
  IsDateString,
  IsNumberString,
  IsOptional,
  IsOptionalIf,
  Length,
  MaxLength,
  MinLength,
  ValidateNested,
  MaxDate,
  Min,
  Max,
} from '@ch-apptitude-icc/common/shared/validation';
import { Transform, Type } from 'class-transformer';
import { Column, JoinColumn, ManyToOne } from 'typeorm';
import { Insurer } from '../insurer.entity';

export class Address implements HasAddress {
  /**
   * Returns `null` when all fields are falsy
   * @description Some fields have default values, so the objects are still created when empty.
   * This causes some condition to be unnecessary true.
   * @return the data or `null` when all fields are falsy
   */
  static nullifyIfEmpty(address?: Readonly<Address> | null): Address | undefined {
    if (!address) {
      return undefined;
    }

    // `countryCode` has/had a default value, so we ignored it
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { countryCode, ...addr } = address;
    if (Object.values(addr).find(v => !!v)) {
      return address;
    }

    return undefined;
  }

  @Column('varchar', { length: 75, name: 'Adresse1', nullable: true })
  @Length(5, 75)
  @IsOptional()
  address1?: string | null;

  @Column('varchar', { length: 75, name: 'Adresse2', nullable: true })
  @MaxLength(75)
  @IsOptional()
  address2?: string | null;

  @Column('char', { length: 10, name: 'NoPostal', nullable: true })
  @Length(4, 10)
  @IsNumberString()
  @IsOptional()
  postcode!: string | null;

  @Column('varchar', { length: 75, name: 'Domicile', nullable: true })
  @Length(2, 75)
  @IsOptional()
  city!: string | null;

  @Column('char', { length: 2, name: 'Pays' })
  @Length(2, 2)
  @IsOptional()
  countryCode!: string | null;
}

const PATIENT_BASE_CONSTRAINTS = {
  birthYear: {
    min: 1900,
    max: 2500,
  },
} as const satisfies Partial<Record<keyof PatientBase, unknown>>;

export class PatientBase implements HasIdentityData, PatientInterface {
  /**
   * Removes empty nested entities (such as address) and returns `null` when all fields are falsy
   * @description Some fields have default values, so the objects are still created when empty.
   * This causes some condition to be unnecessary true.
   * @return a "clean" version of the given data or `null` when all fields are falsy
   */
  static nullifyIfEmpty(personalData?: Readonly<PatientBase> | null): PatientBase | undefined {
    if (!personalData) {
      return undefined;
    }

    // `gender` has/had a default value, so we ignored it
    const { address, gender, ...data } = personalData;
    const finalAddress = Address.nullifyIfEmpty(address);

    if (finalAddress || Object.values(data).find(v => !!v)) {
      const output: PatientBase = { ...data };
      if (address) {
        output.address = address;
      }
      if (gender) {
        output.gender = gender;
      }
      return output;
    }

    return undefined;
  }

  @IsOptional()
  @Column('varchar', { length: 75, name: 'Nom', nullable: true })
  @Length(1, 52)
  lastname!: string | null;

  @IsOptional()
  @Column('varchar', { length: 75, name: 'Prénom', nullable: true })
  @Length(1, 52)
  firstname!: string | null;

  @Column(() => Address, { prefix: false })
  @IsOptional()
  @ValidateNested()
  @Type(() => Address)
  address?: Address;

  @Column('char', {
    length: 1,
    name: 'Sexe',
    nullable: true,
    transformer: genderTransformer,
  })
  @IsEnum(Gender)
  gender?: Gender;

  @Column('varchar', { length: 20, name: 'Tel', nullable: true })
  @MaxLength(20)
  @MinLength(6)
  @IsOptional()
  telephoneNumber?: string | null;

  @Column('varchar', { length: 50, name: 'Email', nullable: true })
  @MaxLength(50)
  @IsOptional()
  @IsEmail()
  emailAddress?: string | null;

  @Column('varchar', {
    length: 10,
    name: 'Anonyme',
    nullable: true,
    transformer: {
      to: (value?: boolean | null) => (value ? 'O' : null),
      from: (value?: string | null) => value === 'O',
    },
  })
  @IsBoolean()
  @IsOptional()
  anonymous?: boolean;

  @Column('date', { name: 'DateNaissance', nullable: true })
  @IsDateString()
  @IsOptionalIf<PatientBase>(obj => !!obj.anonymous) // Anonymous patient do no have this value
  @MaxDate(() => new Date())
  birthDate?: string | null;

  @Column('int', { name: 'AnneeNaissance', nullable: true })
  @IsOptionalIf<PatientBase>(({ anonymous }) => !anonymous) // Anonymous patient have this value
  @Transform(({ value }) => (value === null || value === undefined ? null : +value) as never) // Seems that `Type` does not work
  @IsNumber()
  @Min(PATIENT_BASE_CONSTRAINTS.birthYear.min)
  @Max(PATIENT_BASE_CONSTRAINTS.birthYear.max)
  birthYear?: number | null;

  @Column('float', { name: 'Poids', nullable: true })
  @IsNumber()
  @IsOptional()
  weight?: number | null;

  /**
   * Contains the social security number (AVS number) of the insured person
   */
  @Column('varchar', {
    length: 50,
    name: 'NoAssuré',
    nullable: true,
    transformer: {
      // Currently read as is
      from: str => str as never,
      // ID-1498: Slowly turing all SSN to normalized ones
      to: (str: string | null) => (str ? strings.toSsnNormalized(str) : null),
    },
  })
  @MaxLength(16)
  @IsAVSNumber()
  @IsOptional()
  socialSecurityNumber?: string | null;

  @JoinColumn({ name: 'fkAssurances' })
  @ManyToOne(() => Insurer, insurer => insurer.id, { createForeignKeyConstraints: false })
  insurer?: Insurer | null;

  @Column({ name: 'fkAssurances', nullable: true })
  @IsOptional()
  @IsNumber()
  insurerId?: number | null;

  /**
   * Contains the contract number of the insured person at the provided insurance company
   */
  @Column('varchar', { length: 50, name: 'NoPolice', nullable: true })
  @MaxLength(50)
  @IsOptional()
  insurancePolicyNumber?: string | null;
}

export class DoctorBase implements HasIdentityData {
  /**
   * Removes empty nested entities (such as address) and returns `null` when all fields are falsy
   * @description Some fields have default values, so the objects are still created when empty.
   * This causes some condition to be unnecessary true.
   * @return a "clean" version of the given data or `null` when all fields are falsy
   */
  static nullifyIfEmpty(personalData?: Readonly<DoctorBase> | null): DoctorBase | undefined {
    if (!personalData) {
      return undefined;
    }

    // `gender` has/had a default value, so we ignored it
    const { address, gender, ...data } = personalData;
    const finalAddress = Address.nullifyIfEmpty(address);

    if (finalAddress || Object.values(data).find(v => !!v)) {
      const output: DoctorBase = { ...data };
      if (address) {
        output.address = address;
      }
      if (gender) {
        output.gender = gender;
      }
      return output;
    }

    return undefined;
  }

  @Column('varchar', { length: 75, name: 'Nom', nullable: true })
  @Length(1, 52)
  lastname!: string | null;

  @Column('varchar', { length: 75, name: 'Prénom', nullable: true })
  @Length(1, 52)
  firstname!: string | null;

  @Column(() => Address, { prefix: false })
  @IsOptional()
  @ValidateNested()
  @Type(() => Address)
  address?: Address;

  @Column('char', {
    length: 1,
    name: 'Sexe',
    nullable: true,
    transformer: genderTransformer,
  })
  @IsEnum(Gender)
  gender?: Gender;

  @Column('varchar', { length: 20, name: 'Tel', nullable: true })
  @MaxLength(20)
  @MinLength(6)
  @IsOptional()
  telephoneNumber?: string | null;

  @Column('varchar', { length: 50, name: 'Email', nullable: true })
  @MaxLength(50)
  @IsOptional()
  @IsEmail()
  emailAddress?: string | null;

  @Column('varchar', { length: 15, name: 'Fax', nullable: true })
  @MaxLength(15)
  @IsOptional()
  faxNumber?: string | null;
}
