import { XOR } from '@ch-apptitude-icc/common/shared/type-utils';
import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { forwardRef, MouseEventHandler, ReactNode, Ref } from 'react';
import { UrlObject } from 'url';
import { Icon, IconType } from './icon';

type ButtonVariant = keyof typeof styleVariants;

type BaseProps = {
  active?: boolean;
  leftIcon?: IconType;
  // eslint-disable-next-line react/no-unused-prop-types -- false positive, probably a variant of https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#false-positives-sfc
  loading?: boolean;
  rightIcon?: IconType;
  variant?: ButtonVariant;
  topRightIcon?: IconType | ReactNode;
  unreadNotification?: boolean;
} & Pick<JSX.IntrinsicElements['button'], 'type' | 'className' | 'disabled' | 'autoFocus'>;

type LinkProps = { newTab?: boolean; scroll?: boolean; stopPropagation?: boolean; url: string | UrlObject };
type ClickProps = { onClick?: MouseEventHandler<HTMLButtonElement> };

type DisplayProps = XOR<{ label: string }, { icon: IconType }>;

export type ButtonProps = BaseProps & XOR<LinkProps, ClickProps> & DisplayProps;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ loading, ...btnProps }: ButtonProps, ref) => {
    const { t } = useTranslation('common');

    if (loading) {
      return (
        <Button
          {...(btnProps as BaseProps)}
          disabled
          label={t('component.button.loading')}
          leftIcon="loading"
          ref={ref}
          rightIcon={undefined}
        />
      );
    }

    const { variant = 'primary', disabled = false, type = 'button', autoFocus, ...props } = btnProps;

    const variantStyles = styleVariants[variant];

    const onClick = 'onClick' in props ? props.onClick : undefined;
    const { url, scroll, newTab } = 'url' in props ? props : { newTab: undefined, scroll: undefined, url: undefined };

    return url ? (
      <Link
        href={url}
        scroll={scroll}
        passHref
        target={newTab ? '_blank' : undefined}
        ref={ref as Ref<HTMLAnchorElement>}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          }
          if (props.stopPropagation) {
            e.stopPropagation();
          }
        }}
        className={classNames(variantStyles.topLevel, props.className)}
      >
        {}
        <StyledButton variant={variant} disabled={disabled} {...props} />
      </Link>
    ) : (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        onClick={onClick}
        ref={ref as Ref<HTMLButtonElement>}
        className={classNames(variantStyles.topLevel, props.className)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      >
        {}
        <StyledButton variant={variant} disabled={disabled} {...props} />
      </button>
    );
  },
);

type StyleVariant = {
  active: string;
  base: string;
  disabled: string;
  enabled: string;
  hover: string;
  icon: string;
  label: string;
  topLevel: string;
};

const styleVariants = {
  'dropdown-link': {
    active: '',
    base: 'text-start px-4 py-2 text-sm font-medium text-gray-800 flex justify-start',
    disabled: 'opacity-60',
    enabled: '',
    hover: 'hover:bg-cyan-700 hover:text-white',
    icon: '',
    label: '',
    topLevel: 'w-full',
  },
  'header-link': {
    active: '',
    base: 'bg-transparent text-white text-xs uppercase flex items-center justify-center',
    disabled: '',
    enabled: '',
    hover: 'hover:text-cyan-700',
    icon: '',
    label: '',
    topLevel: '',
  },
  link: {
    active: '',
    base: 'bg-transparent text-primary text-sm font-semibold justify-center',
    disabled: 'text-gray-400',
    enabled: '',
    hover: 'hover:text-cyan-700',
    icon: '',
    label: '',
    topLevel: '',
  },
  primary: {
    active: '',
    base: 'bg-primary text-sm text-white rounded-lg flex items-center px-3 py-1 justify-center',
    disabled: 'text-gray-500 border-gray-300 bg-gray-300',
    enabled: '',
    hover: 'hover:bg-cyan-700',
    icon: '',
    label: '',
    topLevel: 'h-10',
  },
  secondary: {
    active: '',
    base: 'bg-white h-10 text-sm text-primary rounded-lg flex items-center px-3 py-1 border border-gray-200 justify-center',
    disabled: '',
    enabled: '',
    hover: 'hover:bg-sky-50',
    icon: '',
    label: '',
    topLevel: 'h-10',
  },
  transparent: {
    // You need to set opacity otherwise it does not work with bg-transparent
    active: 'bg-primary/100',
    base: 'bg-transparent text-sm rounded-lg flex items-center px-3 py-1 justify-center',
    disabled: 'opacity-60',
    enabled: '',
    hover: 'hover:bg-cyan-700 hover:text-white',
    icon: ' hover:text-white',
    label: '',
    topLevel: 'h-10',
  },
  'transparent-outline': {
    active: 'bg-primary/100',
    base: 'bg-transparent text-sm text-white rounded-lg flex items-center px-3 py-1 border border-primary justify-center',
    disabled: 'text-gray-500 border-gray-300 bg-gray-300',
    enabled: '',
    hover: 'hover:bg-cyan-700',
    icon: '',
    label: '',
    topLevel: 'h-10',
  },
  'primary-transparent-outline': {
    active: '',
    base: 'bg-transparent h-10 text-sm text-primary rounded-lg flex items-center px-3 py-1 border border-gray-200 justify-center',
    disabled: 'text-gray-500 border-gray-300 bg-gray-300',
    enabled: '',
    hover: 'hover:bg-sky-50',
    icon: '',
    label: '',
    topLevel: 'h-10',
  },
  'primary-transparent': {
    // You need to set opacity otherwise it does not work with bg-transparent
    active: '',
    base: 'bg-transparent text-sm text-primary flex items-center py-1 font-bold justify-center',
    disabled: 'opacity-60',
    enabled: '',
    hover: 'hover:text-cyan-700',
    icon: '',
    label: '',
    topLevel: 'h-10',
  },
  table: {
    active: '',
    base: 'bg-white h-10 text-sm text-primary rounded-lg flex items-center px-3 py-1 border border-gray-200 justify-center',
    disabled: '',
    enabled: '',
    hover: 'hover:bg-sky-50',
    icon: '',
    label: '',
    topLevel: 'h-8',
  },
} as const satisfies Record<string, StyleVariant>;

function StyledButton({
  label,
  icon,
  leftIcon,
  rightIcon,
  topRightIcon,
  disabled,
  variant = 'primary',
  active,
  unreadNotification,
}: ButtonProps): JSX.Element {
  const variantStyles = styleVariants[variant];

  return (
    <div
      className={classNames(
        variantStyles.base,
        disabled ? variantStyles.disabled : [variantStyles.enabled, variantStyles.hover],
        {
          [variantStyles.active]: active,
          'cursor-not-allowed': disabled,
        },
        'h-full',
        'relative',
      )}
    >
      {leftIcon && (
        <div className={classNames('relative inline-block pr-2', variantStyles.icon)}>
          <Icon icon={leftIcon} />

          {unreadNotification && (
            <div className="border-1 absolute bottom-3 right-1/4 h-2 w-2 rounded-full border-white bg-red-600" />
          )}
        </div>
      )}
      <span className={classNames(variantStyles.label, 'whitespace-nowrap')}>{label ?? <Icon icon={icon} />}</span>
      {rightIcon && (
        <div className={classNames('inline-block pl-2', variantStyles.icon)}>
          <Icon icon={rightIcon} />

          {unreadNotification && (
            <div className="border-1 absolute bottom-3 right-1/4 h-2 w-2 rounded-full border-white bg-red-600" />
          )}
        </div>
      )}
      {topRightIcon && (
        <div className="absolute -top-2 -right-1">
          {typeof topRightIcon === 'string' ? <Icon icon={topRightIcon as IconType} /> : topRightIcon}
        </div>
      )}
    </div>
  );
}
