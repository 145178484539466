import { isNotNull } from '@ch-apptitude-icc/common/shared/utils';
import { OrderOrReference } from '@ch-apptitude-icc/lablink/shared/entities';
import { Button, Dropdown } from '@features/ui/components';
import { useApi } from '@services/api';
import { useTranslation } from 'next-i18next';

type OrderActionsDropdownProps = {
  orderOrReference: OrderOrReference;
};

export const OrderActionsDropdown = ({ orderOrReference }: OrderActionsDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const { referencesApi, orderApi } = useApi();

  const reportUrl = (referenceId: number) => referencesApi.getReportUrl(referenceId);
  const labelsUrl = (orderId: number) => orderApi.getBarcodePdfUrl(orderId, 1);
  const orderSheetUrl = (orderId: number) => orderApi.getOrderSheetPdfUrl(orderId);

  return (
    <Dropdown
      label={<Button icon="ellipsisVertical" variant="table" onClick={e => e.stopPropagation()} />}
      items={[
        orderOrReference.reportStatus != null && orderOrReference.referenceId && orderOrReference.reportFilePath
          ? {
              label: t('pages.orders.report.report'),
              url: reportUrl(orderOrReference.referenceId),
              newTab: true,
              stopPropagation: true,
            }
          : null,
        ...(orderOrReference.orderId
          ? [
              {
                label: t('pages.orders.stickers'),
                stopPropagation: true,
                newTab: true,
                url: labelsUrl(orderOrReference.orderId),
              },
              {
                label: t('pages.orders.orderSheet'),
                newTab: true,
                stopPropagation: true,
                url: orderSheetUrl(orderOrReference.orderId),
              },
            ]
          : []),
      ].filter(isNotNull)}
    />
  );
};
