/**
 * Convert an instance of a class to a plain JS object recursively
 * @param object the object to transform
 */
export function plainify<T>(object: T): T {
  if (typeof object !== 'object' || object instanceof Date) {
    return object;
  }

  if (Array.isArray(object)) {
    type ElemType = T extends Array<infer ET> ? ET : never;
    return object.map((item: ElemType) => plainify<ElemType>(item)) as T;
  }

  return object
    ? (Object.fromEntries(Object.entries(object as object).map(([key, value]) => [key, plainify(value)])) as T)
    : object;
}
