import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { InputHTMLAttributes, ReactNode, useState } from 'react';

export type CheckboxProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'checked' | 'className' | 'disabled' | 'name' | 'onChange' | 'onBlur' | 'required' | 'onClick' | 'defaultChecked'
> & {
  children?: string | ReactNode;
  // The only reason we have this because Formik override the value props
  ckValue?: string;
  /**
   * Disable the default style applied to the label
   */
  disableLabelStyle?: boolean;
  rtl?: boolean;
  /**
   * Do the same thing as 'checked' (priority to 'checked').
   * To be used with Formik for instance.
   * @see checked
   */
  value?: boolean;
};

export const Checkbox = ({ children, className, disableLabelStyle, rtl, ...props }: CheckboxProps): JSX.Element => {
  const [id] = useState(uniqueId('ckb-'));

  return (
    <div
      className={classNames(
        {
          'cursor-not-allowed': props.disabled,
          'flex-row-reverse': rtl,
        },
        'flex min-h-[2.5rem] items-center',
        className,
      )}
    >
      <div className="flex h-5 items-center">
        <input
          className="h-4 w-4 rounded"
          checked={props.checked ?? props.value}
          defaultChecked={props.defaultChecked}
          disabled={props.disabled}
          name={props.name}
          onChange={props.onChange}
          onClick={props.onClick}
          onBlur={props.onBlur}
          required={props.required}
          value={props.ckValue}
          type="checkbox"
          id={id}
        />
      </div>

      {children && (
        <div
          className={classNames({
            'ml-3': !rtl,
            'mr-3': rtl,
            'pointer-events-none': props.disabled,
            'text-gray-400': props.disabled && !disableLabelStyle,
          })}
        >
          <label
            className={classNames({
              'text-sm font-medium': !disableLabelStyle,
            })}
            htmlFor={id}
          >
            {children}
          </label>
        </div>
      )}
    </div>
  );
};
