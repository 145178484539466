/* eslint-disable react-hooks/rules-of-hooks */
import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import {
  ChangePasswordDto,
  ConfirmPasswordResetDto,
  ResetPasswordRequestDto,
  User,
} from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory, UseMutationOptions, APIDetailResponse } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient, useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

const api = clients.users();

export class UsersApi extends ApiFactory<User, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }

  public useChangePassword(
    options?: UseMutationOptions<APIDetailResponse<void>>,
  ): UseMutationResult<AxiosResponse<void>, AxiosError, ChangePasswordDto> {
    return useMutation({
      mutationFn: dto => this.api.changePassword(dto),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: () => options?.onSuccess && options.onSuccess(undefined, async () => {}),
      retry: false,
    });
  }

  public useResetPassword(
    options?: UseMutationOptions<APIDetailResponse<void>>,
  ): UseMutationResult<AxiosResponse<void>, AxiosError, ConfirmPasswordResetDto> {
    return useMutation({
      mutationFn: dto => this.api.resetPassword(dto),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: () => options?.onSuccess && options.onSuccess(undefined, async () => {}),
      retry: false,
    });
  }

  public useRequestPasswordReset(
    options?: UseMutationOptions<APIDetailResponse<void>>,
  ): UseMutationResult<AxiosResponse<void>, AxiosError, ResetPasswordRequestDto> {
    return useMutation({
      mutationFn: dto => this.api.requestPasswordReset(dto),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: () => options?.onSuccess && options.onSuccess(undefined, async () => {}),
      retry: false,
    });
  }
}
