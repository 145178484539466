import { Gender } from '@ch-apptitude-icc/common/shared/entities';
import { OmitType, PickType, staticImplements } from '@ch-apptitude-icc/common/shared/type-utils';
import { IsOptionalIf, ValidateNested } from '@ch-apptitude-icc/common/shared/validation';
import { embeded, Insurer, Invoicing, OrderCreateDto } from '@ch-apptitude-icc/lablink/shared/entities';
import { CreateOrderFront } from '@features/orders/types/CreateOrderFront';
import { CreateOrderFrontStep } from '@features/orders/types/CreateOrderFrontStep';
import { plainToInstance, Transform, Type } from 'class-transformer';

export class CreateOrderStepOrderSecondDoctor extends OmitType(embeded.DoctorBase, ['gender']) {
  constructor() {
    super();

    this.firstname = null;
    this.lastname = null;
    this.address = {
      address1: null,
      city: null,
      postcode: null,
      countryCode: 'CH',
    };
  }

  // The database requires this information for the second doctor (`SexeMed1`)
  gender?: Gender = Gender.UNKNOWN;
}

export class CreateOrderStepInvoicing extends Invoicing {
  constructor() {
    super();

    this.countryCode = 'CH';
    this.address1 = null;
    this.lastname = null;
    this.firstname = null;
    this.postcode = null;
    this.city = null;
  }
}

@staticImplements<CreateOrderFrontStep<CreateOrderStepOrder>>()
export class CreateOrderStepOrder extends PickType(CreateOrderFront, [
  'urgent',
  'copyToPatient',
  // 'secondDoctorData',
  'remark',
  'billedTo',
  'invoicing',
  'patient',
  'insuranceBillingNoEmail',
  'isAddition',
  'summonClient',
]) {
  public static validate(v: CreateOrderStepOrder, insurer?: Insurer): string[] {
    return OrderCreateDto.validateOrderStep(v, insurer);
  }

  sendCopyToSecondDoctor?: boolean;

  public static from(dto: CreateOrderFront): CreateOrderStepOrder {
    return {
      urgent: dto.urgent,
      copyToPatient: dto.copyToPatient,
      secondDoctorData: dto.secondDoctorData,
      remark: dto.remark,
      billedTo: dto.billedTo,
      invoicing: dto.invoicing,
      patient: dto.patient,
      sendCopyToSecondDoctor: !!dto.secondDoctorData,
      insuranceBillingNoEmail: dto.insuranceBillingNoEmail ?? false,
      isAddition: dto.isAddition,
      summonClient: dto.summonClient,
    };
  }

  public static mergeTo(source: CreateOrderStepOrder, dto: CreateOrderFront): CreateOrderFront {
    return {
      ...dto,
      urgent: source.urgent,
      copyToPatient: source.copyToPatient,
      secondDoctorData:
        source.secondDoctorData ?? (source.sendCopyToSecondDoctor ? new CreateOrderStepOrderSecondDoctor() : undefined),
      remark: source.remark,
      billedTo: source.billedTo,
      invoicing: source.invoicing,
      patient: source.patient,
      insuranceBillingNoEmail: source.insuranceBillingNoEmail ?? false,
      isAddition: source.isAddition,
      summonClient: source.summonClient,
    };
  }

  @Type(() => CreateOrderStepOrderSecondDoctor)
  @ValidateNested()
  @IsOptionalIf<CreateOrderStepOrder>(obj => !obj.sendCopyToSecondDoctor)
  @Transform(({ value, obj }: { value: unknown; obj: CreateOrderStepOrder }) =>
    obj.sendCopyToSecondDoctor
      ? value // even if the value is entirely null, we want to keep it as is so that the errors are displayed correctly
      : plainToInstance(
          CreateOrderStepOrderSecondDoctor,
          embeded.DoctorBase.nullifyIfEmpty(value as embeded.DoctorBase),
        ),
  )
  secondDoctorData?: CreateOrderStepOrderSecondDoctor;
}
