import { PickType, staticImplements } from '@ch-apptitude-icc/common/shared/type-utils';
import { CreateOrderFront, CreateOrderPatient } from '@features/orders/types/CreateOrderFront';
import { CreateOrderFrontStep } from '@features/orders/types/CreateOrderFrontStep';
import { type DeepPartial } from 'typeorm';

@staticImplements<CreateOrderFrontStep<CreateOrderStepPatient>>()
export class CreateOrderStepPatient extends PickType(CreateOrderFront, ['patient', 'reference', 'orderingDepartment']) {
  constructor(patient?: DeepPartial<CreateOrderPatient>) {
    super();
    this.patient = patient as CreateOrderPatient;
  }

  public static from(dto: CreateOrderFront): CreateOrderStepPatient {
    return { patient: dto.patient, reference: dto.reference, orderingDepartment: dto.orderingDepartment };
  }

  public static mergeTo(source: CreateOrderStepPatient, dto: CreateOrderFront): CreateOrderFront {
    return {
      ...dto,
      patient: source.patient,
      reference: source.reference,
      orderingDepartment: source.orderingDepartment,
    };
  }
}
