import { AbstractApiCallHelper, ApiFactoryBase } from '@common/frontend/services/api-client';
import { ToastProps } from '@features/ui/components';
import { showToast } from '@features/ui/services/showToast';
import { getRoute } from '@services/routes';
import { AxiosError } from 'axios';

export class ApiCallHelper extends AbstractApiCallHelper {
  /**
   * The default 'onError' handler, also passed as the `next` parameter when the handler is override
   * @param error received
   */
  protected onErrorNext(error: AxiosError): void {
    const toast: ToastProps = {
      title: this.translation('errors.error-occurred'),
      variant: 'error',
    };

    if (error.response?.status) {
      if (error.response.status === 401) {
        const { location } = window;
        const redirectUrl = location.toString().substring(location.origin.length);

        this.routerPush(
          // RedirectUrl not added on home page
          getRoute.login({ query: redirectUrl.length > 1 ? { redirectUrl } : undefined }),
        ).catch(() => undefined);

        return;
      }

      toast.title = this.translation(error.response.status >= 500 ? 'errors.http.>=500' : 'errors.http.>=400');
      // < 400 should never happen
    } else {
      toast.message = this.translation('errors.network.occurred');

      if (error.code === AxiosError.ECONNABORTED || error.code === AxiosError.ETIMEDOUT) {
        toast.message = this.translation('errors.network.timeout', {
          timeout: (error.config?.timeout || ApiFactoryBase.timeout) / 1000,
        });
      } else if (error.code === AxiosError.ERR_NETWORK) {
        toast.message = this.translation('errors.network.unreachable');
      }
    }

    showToast(toast);
  }
}
