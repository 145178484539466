import { ClassConstructor, ClassTransformOptions, plainToInstance } from 'class-transformer';
import { validateSync, ValidationError } from 'class-validator';
import { ValidatorOptions } from 'class-validator/types/validation/ValidatorOptions';
import { Formik, FormikHelpers } from 'formik';
import { FormikConfig, FormikValues } from 'formik/dist/types';
import { values } from 'lodash';
import { TFunction, useTranslation } from 'next-i18next';
import { createContext, useMemo } from 'react';
import { Schema } from 'yup';

export function convertErrorsToFormik(errors: Array<ValidationError | string>, t: TFunction): Record<string, unknown> {
  const output: Record<string, unknown> = {};
  errors.forEach(err => {
    if (typeof err !== 'string' && (err.children?.length ?? 0) > 0 && err.children != null) {
      output[err.property] = convertErrorsToFormik(err.children, t);
      return;
    }

    const s = typeof err === 'string' ? err : values(err.constraints)[0];
    const [property, message] = s.split(':');

    if (!message) {
      return;
    }

    if (typeof err !== 'string' && err.property) {
      output[err.property] = s;
    } else {
      let tempKey = property.split('.');
      let tempOutput: Record<string, unknown> = output;

      while (tempKey.length > 1) {
        const [head, ...rest] = tempKey;
        const child = tempOutput[head] ?? {};
        tempOutput[head] = child;
        // @ts-expect-error empty object is not directly a record
        tempOutput = child;
        tempKey = rest;
      }
      tempOutput[tempKey[0]] = s;
    }
  });

  return output;
}

/**
 * A context that is used to define that the form should be loading. Can be used to replace inputs with skeletons.
 */
export const FormContext = createContext<
  { formIsLoading: boolean; formIsDisabled: boolean } & (
    | { type: ClassConstructor<object> | null }
    | { schema: Schema<object> }
  )
>({
  formIsLoading: false,
  formIsDisabled: false,
  type: null,
});

export const stringToNull = <T extends object>(obj: T): T =>
  Object.fromEntries(
    Object.entries(obj).map<[string, unknown]>(([key, value]) => {
      if (value === '') {
        return [key, null];
      }
      if (typeof value === 'object' && !(value instanceof Date) && value != null && !Array.isArray(value)) {
        return [key, stringToNull(value)];
      }
      return [key, value];
    }),
    // We unfortunately need to cast it to T because of the unknown above
  ) as T;

export function FormikEntityWrapper<Values extends FormikValues = FormikValues, ExtraProps = unknown>({
  type,
  options,
  onSubmit,
  validate,
  transformOptions,
  transformPost = v => v,
  isLoading,
  disabled,
  ...props
}: Omit<FormikConfig<Values>, 'validate' | 'onSubmit'> &
  ExtraProps & {
    options?: ValidatorOptions;
    transformOptions?: ClassTransformOptions;
    /**
     * Additional Transformation after the `class-transformer` one.
     */
    transformPost?: (v: Values) => Values;
    type: ClassConstructor<Values>;
    validate?: (v: Values) => string[] | Promise<string[]>;
    onSubmit?: (v: Values, fh: FormikHelpers<Values>) => void | Promise<unknown>;

    isLoading?: boolean;
    disabled?: boolean;
  }): JSX.Element {
  const { t } = useTranslation('common');
  const context = useMemo(
    () => ({ formIsLoading: isLoading ?? false, formIsDisabled: disabled ?? false, type }),
    [isLoading, disabled, type],
  );

  return (
    <FormContext.Provider value={context}>
      <Formik<Values>
        {...props}
        validate={async (v: Values): Promise<object> => {
          const object: Values = transformPost(plainToInstance(type, stringToNull(v), transformOptions));
          const errors: Array<ValidationError | string> = validateSync(object, { ...options });

          if (validate) {
            const validateResult = await validate(object);
            errors.push(...validateResult);
          }

          return convertErrorsToFormik(errors, t);
        }}
        onSubmit={(v: Values, fh: FormikHelpers<Values>): void | Promise<unknown> => {
          if (onSubmit) {
            const object: Values = transformPost(plainToInstance(type, stringToNull(v), transformOptions));

            return onSubmit(object, fh);
          }

          return undefined;
        }}
      />
    </FormContext.Provider>
  );
}

export function FormikYupWrapper<Values extends FormikValues = FormikValues, ExtraProps = unknown>({
  isLoading,
  disabled,
  disableValidation = false,
  validationSchema,
  onSubmit,
  ...props
}: Omit<FormikConfig<Values>, 'validationSchema' | 'onSubmit'> &
  ExtraProps & {
    validationSchema: Schema<Values>;
    isLoading?: boolean;
    disabled?: boolean;
    /**
     * When true, the schema is only used to cast the values, but no validation on it
     */
    disableValidation?: boolean;
    onSubmit?: (v: Values, fh: FormikHelpers<Values>) => void | Promise<unknown>;
  }): JSX.Element {
  const context = useMemo(
    () => ({ formIsLoading: isLoading ?? false, formIsDisabled: disabled ?? false, schema: validationSchema }),
    [isLoading, disabled, validationSchema],
  );

  return (
    <FormContext.Provider value={context}>
      <Formik<Values>
        {...props}
        validationSchema={disableValidation ? undefined : validationSchema}
        onSubmit={(v: Values, fh: FormikHelpers<Values>): void | Promise<unknown> => {
          if (onSubmit) {
            // Omit unknown values
            const object: Values = validationSchema.cast(stringToNull(v), {
              assert: !disableValidation,
              stripUnknown: true,
            });
            return onSubmit(object, fh);
          }

          return undefined;
        }}
      />
    </FormContext.Provider>
  );
}
