import { ProfaFieldsInterface, booleanTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { IsBoolean, IsIn, IsInt, IsOptional, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Column } from 'typeorm';

export class OrderProfaFields implements ProfaFieldsInterface {
  /**
   * Set on creation if a quick-test made in Profa before the request was reactive to Syphilis
   */
  @Column('bit', { default: () => "'b'0''", name: 'TestProfaSYPHpos', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  quickTestPositiveForSyphilis?: boolean;

  /**
   * Set on creation if a quick-test made in Profa before the request was reactive to HIV
   */
  @Column('bit', { default: () => "'b'0''", name: 'TestProfaHIVpos', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  quickTestPositiveForHIV?: boolean;

  @Column('int', {
    name: 'TarifProfa',
    nullable: true,
  })
  @IsIn([-5, -4, -3, -2, 2, 3, 4, 5])
  @IsOptional()
  profaPricing?: number | null;

  /**
   * Flag set if the order is present in the Achilles software.
   *
   * Likely only set when updating. Likely only used by PROFA/Planning Familial.
   */
  @Column('bit', { default: () => "'b'0''", name: 'IsInAchilles', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  isInAchilles?: boolean;

  /**
   * NOTE: this field also appears (read only) in the source code for Lavigny orders, but is likely always null in that
   * case.
   */
  @Column('varchar', { length: 255, name: 'DonnéesCliniques', nullable: true })
  @MaxLength(255)
  @IsOptional()
  clinicalData?: string | null;

  @Column('int', { name: 'fkPatientExt', nullable: true })
  @IsInt()
  @IsOptional()
  profaPatientId?: number | null;
}
