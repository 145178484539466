import { envConfig } from '@root/envConfig';
import { useAppDispatch } from '@services/store/hooks';
import { setSearchString } from '@services/store/pageStateSlice';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Input } from './form';

export const HeaderSearchBar = ({ autoFocus, className }: { autoFocus?: boolean; className?: string }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState('');

  const debounceFn = useCallback((x: string) => dispatch(setSearchString(x)), [dispatch]);

  const clearInput = () => {
    setValue('');
    dispatch(setSearchString(undefined));
  };

  useEffect(
    () => {
      clearInput();
      return clearInput;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Input
      autoFocus={autoFocus}
      type="text"
      iconLeft={{ className: 'text-primary', icon: 'magnifyingGlass' }}
      className={classNames('h-10 md:min-w-[300px]', className)}
      placeholder={t('pages.orders.contentHeader.searchPlaceholder')}
      onDebounce={debounceFn}
      value={value}
      onChange={e => setValue(e.target.value)}
      debounceTime={envConfig.constants.searchDelay}
      iconRight={value ? { className: 'text-gray-400', icon: 'xmarkCircle', onClick: clearInput } : undefined}
    />
  );
};
