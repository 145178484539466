import { Column, PrimaryColumn } from 'typeorm';

/**
 * Represents a pricing for an analysis, only valid starting a certain date
 */
export class Pricing {
  @PrimaryColumn('int', { name: 'analysisId' })
  analysisId?: number;

  @PrimaryColumn('int', { name: 'pricingCategoryId' })
  pricingCategoryId?: number;

  @Column({ length: '100', nullable: true, type: 'varchar' })
  code?: string | null;

  @Column({
    type: 'decimal',
    precision: 7,
    scale: 2,
    nullable: true,
    transformer: {
      from: (value: string | number) => +value,
      to: (value: number) => value,
    },
  })
  value?: number;
}
