import { IsNotEmpty, MinLength } from '@ch-apptitude-icc/common/shared/validation';

/**
 * A DTO for password change requests.
 *
 * A password change request can only be processed while the user is already logged in to their account.
 */
export class ChangePasswordDto {
  @IsNotEmpty()
  currentPassword!: string;

  @MinLength(8)
  newPassword!: string;
}
