import { Icon } from '@features/ui/components';
import { AnalysisFront } from '@services/api';
import classNames from 'classnames';

export type OrderAnalysisChipProps = {
  analysis: AnalysisFront;
  className?: string;
  clickable?: boolean;
  onClick?(analysis: AnalysisFront, selected: boolean): void;
  selected?: boolean;
};

export const OrderAnalysisChip = (props: OrderAnalysisChipProps): JSX.Element => (
  <button
    type="button"
    className={classNames(
      props.className,
      'rounded-lg px-2 py-[1px] text-left text-gray-500',
      'flex flex-row items-center gap-1',
      {
        'cursor-default': !props.clickable,
        group: props.clickable, // "Enable" the icon change when it is clickable

        'bg-emerald-100 text-emerald-800': props.selected,
        'hover:bg-sky-100': !props.selected,
      },
    )}
    onClick={() => props.clickable && props.onClick && props.onClick(props.analysis, props.selected || false)}
  >
    {props.analysis.publicName}

    {props.selected && props.clickable && (
      <div className="ml-1">
        <span className="group-hover:hidden">
          <Icon icon="check" />
        </span>
        <span className="hidden group-hover:inline-block">
          <Icon icon="xmarkCircle" />
        </span>
      </div>
    )}
  </button>
);
