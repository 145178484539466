import { SamplingMaterialInterface, numberArrayTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { Column, PrimaryGeneratedColumn } from 'typeorm';

export class SamplingMaterial implements SamplingMaterialInterface {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id' })
  id!: number;

  @Column({ name: 'name', nullable: true, type: 'varchar', length: 50 })
  name!: string;

  @Column({ name: 'tubeColor', nullable: true, type: 'varchar', length: 50 })
  tubeColor?: string | null;

  @Column({ name: 'maxAnalysesPerUnit', nullable: true, type: 'int' })
  maxAnalysesPerUnit?: number | null;

  @Column({ name: 'materialUnitName', nullable: true, type: 'varchar', length: 50 })
  materialUnitName?: string | null;

  @Column({ name: 'materialUnitQuantityIncrement', nullable: true, type: 'int' })
  materialUnitQuantityIncrement?: number | null;

  @Column({ name: 'materialLabelsPerUnit', nullable: true, type: 'int' })
  materialLabelsPerUnit?: number | null;

  @Column({
    name: 'subMaterials',
    nullable: true,
    type: 'varchar',
    length: 50,
    transformer: numberArrayTransformer,
  })
  subMaterials?: number[];

  @Column({ name: 'requiredComment', nullable: false, type: 'boolean', default: () => false })
  requiredComment?: boolean;

  @Column({ name: 'analysisMaterial', type: 'varchar', length: 20, nullable: true })
  analysisMaterialId?: string;
}
