import { Button } from '@features/ui/components';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';

type PaginationProps = {
  currentPage: number;
  maxPage: number;
  onNextPageClick: () => void;
  onPreviousPageClick: () => void;
  onSetPageIndex: (index: number) => void;
  pageSize: number;
  totalElements: number;
};

const baseButtonStyle = 'relative inline-flex items-center border border-gray-200 px-4 py-2 text-sm font-medium';
const basePreviousNextStyle =
  'relative inline-flex items-center border border-gray-200 px-2 py-2 text-sm font-medium text-white hover:bg-sky-50 focus:z-20';

export const Pagination = ({
  onNextPageClick,
  onPreviousPageClick,
  onSetPageIndex,
  currentPage,
  ...props
}: PaginationProps): JSX.Element => {
  const { t } = useTranslation('common');

  const pagesToShowUnfiltered = [0, currentPage - 1, currentPage, currentPage + 1, props.maxPage - 1];
  const pagesToShow = pagesToShowUnfiltered.filter(
    (x, i) => x >= 0 && x < props.maxPage && pagesToShowUnfiltered.indexOf(x) === i,
  );

  const firstElement = currentPage * props.pageSize + 1;
  const lastElement =
    currentPage * props.pageSize +
    (props.maxPage === 0 ||
    ((currentPage === props.maxPage - 1 || props.maxPage === 1) && props.totalElements % props.pageSize !== 0)
      ? props.totalElements % props.pageSize
      : props.pageSize);

  const disablePreviousNav = props.totalElements === 0 || currentPage === 0;
  const disableNextNav = props.totalElements === 0 || currentPage === props.maxPage - 1;

  return (
    <div className="mt-auto flex items-center justify-between px-4 py-3 md:px-0">
      <div className="flex flex-1 justify-between md:hidden">
        <Button
          label={t('component.table.Previous')}
          onClick={onPreviousPageClick}
          type="button"
          disabled={disablePreviousNav}
        />
        <Button label={t('component.table.Next')} onClick={onNextPageClick} type="button" disabled={disableNextNav} />
      </div>
      <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
        <p className="text-xs text-gray-400">
          <span className="font-bold">{lastElement - firstElement + 1}</span> {t('component.table.resultsOf')}{' '}
          <span className="font-bold">{props.totalElements}</span>
        </p>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
            <Button
              onClick={onPreviousPageClick}
              type="button"
              className={classNames(basePreviousNextStyle, 'rounded-l-md ')}
              icon="chevronLeft"
              variant="link"
              disabled={disablePreviousNav}
            />
            {pagesToShow.map((x, i) => (
              <Fragment key={x}>
                {i > 0 && pagesToShow[i - 1] < x - 1 && (
                  <span className={classNames(baseButtonStyle, 'text-gray-800')}>...</span>
                )}
                <button
                  onClick={() => onSetPageIndex(x)}
                  type="button"
                  className={classNames(baseButtonStyle, 'focus:z-20', {
                    'text-primary-darker bg-gray-100': x === currentPage,
                    'text-gray-800 hover:bg-sky-50': x !== currentPage,
                  })}
                >
                  {x + 1}
                </button>
              </Fragment>
            ))}
            <Button
              onClick={onNextPageClick}
              type="button"
              className={classNames(basePreviousNextStyle, 'rounded-r-md ')}
              icon="chevronRight"
              variant="link"
              disabled={disableNextNav}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};
