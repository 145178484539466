import { OmitType } from '@ch-apptitude-icc/common/shared/type-utils';
import { IsDateString, IsNumber, IsOptionalIf } from '@ch-apptitude-icc/common/shared/validation';
import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { Patient } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';
import { Transform } from 'class-transformer';
import { isDateString } from 'class-validator';

export class PatientFront extends OmitType(Patient, ['birthDate', 'id']) {
  @IsNumber()
  id!: number;

  @IsDateString()
  @IsOptionalIf<PatientFront>(obj => !!obj.anonymous) // Anonymous patient do no have this value
  @Transform(({ value }: { value: string | null }) =>
    // Transform a potential ISO date+time into a simple ISO date
    // TODO: move this to backend type when possible
    value && isDateString(value) && value.includes('T') ? value.split('T')[0] : value,
  )
  birthDate?: string;
}

const api = clients.patients(PatientFront);

export class PatientApi extends ApiFactory<PatientFront, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}
