import { Icon, Input, InputProps } from '@features/ui/components';
import { countriesOption } from '@utils/country/countriesOption';
import classNames from 'classnames';
import { AsYouType, CountryCode, getCountryCallingCode } from 'libphonenumber-js';

import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import 'flag-icons/css/flag-icons.min.css';

type PhoneInputProps = { defaultCountryCode?: string } & Pick<
  InputProps,
  'error' | 'value' | 'onBlur' | 'onChange' | 'name' | 'required' | 'disabled'
>;

export const PhoneInput = ({
  value,
  onBlur,
  onChange,
  name,
  defaultCountryCode = '+41',
  ...props
}: PhoneInputProps): JSX.Element => {
  const [innerPhoneNumber, setPhoneNumber] = useState<string>('');
  // This is a hack because some numbers won't retrieve the country code until some digits are added, for example: +358 1 and +358 2 are different countries
  const [lastSelectedCountryCode, setLastSelectedCountryCode] = useState<CountryCode | undefined>(undefined);

  const asYouType = new AsYouType();
  const phoneNumber = value != null && value !== '' ? value : innerPhoneNumber;
  asYouType.input(phoneNumber);

  const countryCode = asYouType.getCountry() ?? lastSelectedCountryCode;
  const callingCodeWithPlus = countryCode ? `+${getCountryCallingCode(countryCode)}` : '';

  const setInitialCountryCode = () => {
    if (phoneNumber === '') {
      setPhoneNumber('+41');
    }
  };

  const onChangeWrapper = (
    e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
    newCountryCode = false,
    callback = onChange,
  ) => {
    const noSpaceNumber = e.target.value.replace(/\s/g, '');

    // Remove the selected country code if the user erase the current one
    if (!newCountryCode && !noSpaceNumber.startsWith(callingCodeWithPlus)) {
      setLastSelectedCountryCode(undefined);
    }

    // We ease the task for default country numbers
    const newNumber = noSpaceNumber === '0' ? defaultCountryCode : noSpaceNumber;

    setPhoneNumber(newNumber);
    if (callback) {
      callback({ target: { name: e.target.name, value: newNumber } } as typeof e);
    }
  };
  const { i18n } = useTranslation('common');

  const setSelectedCountryCode = (code: CountryCode) => {
    setLastSelectedCountryCode(code);
    // Replace the current country code or add it if it's not present
    const newPhone = countryCode
      ? `+${getCountryCallingCode(code)}${phoneNumber.slice(callingCodeWithPlus.length, phoneNumber.length)}`
      : `+${getCountryCallingCode(code)}${
          phoneNumber.startsWith('0') || phoneNumber.startsWith('+') ? phoneNumber.slice(1) : phoneNumber
        }`;

    onChangeWrapper({ target: { name, value: newPhone } } as unknown as React.ChangeEvent<HTMLInputElement>, true);
  };

  return (
    <Input
      type="text"
      selectLeft={{
        buttonLabel: (
          <div className="flex w-7 flex-row items-center justify-center">
            <div
              className={classNames(
                countryCode ? `fib fi-${countryCode.toLowerCase()} fis rounded-full` : '',
                'mr-2 h-5 w-5',
              )}
            >
              {!countryCode && <Icon icon="phone" />}
            </div>
          </div>
        ),
        onChange: e => setSelectedCountryCode(e.target.value as CountryCode),
        options: countriesOption(i18n.language),
        value: countryCode,
      }}
      // You need a new AsYouType otherwise it will not work
      value={new AsYouType().input(phoneNumber)}
      onBlur={e => onChangeWrapper(e, false, onBlur)}
      onChange={e => onChangeWrapper(e)}
      onFocus={() => setInitialCountryCode()}
      name={name}
      {...props}
    />
  );
};
