import { LavignyFieldsInterface, booleanTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { IsBoolean, IsOptional, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Column } from 'typeorm';

/**
 * Fields only set for lavigny orders
 */
export class OrderLavignyFields implements LavignyFieldsInterface {
  /**
   * Flag set if the order was passed by the Lavigny institution
   */
  @Column('bit', { default: () => "'b'0''", name: 'Lavigny', transformer: booleanTransformer })
  @IsBoolean()
  @IsOptional()
  isLavigny!: boolean;

  @Column('tinytext', { name: 'fkPatientLavigny', nullable: true })
  @MaxLength(25)
  @IsOptional()
  lavignyPatientId!: string | null;
}
