import { OrderAnalysisGrouped } from '@features/analyses/utils';
import { Checkbox, Icon } from '@features/ui/components';
import { Spinner } from '@features/ui/components/Spinner';
import { useModal } from '@features/ui/modal';
import { AnalysisFront, useApi } from '@services/api';
import { useFormatters } from '@services/formatters';
import { useMemo } from 'react';
import { OrderAnalysisChip, OrderAnalysisChipProps } from './OrderAnalysisChip';

export type OrderAnalysesGridGroupActions = {
  onAdd?: (analyses: AnalysisFront[]) => void;
  onRemove?: (analyses: AnalysisFront[]) => void;
};

export type OrderAnalysesGridProps = {
  analyses: (AnalysisFront & { orderInCatalogue?: number | null })[];
  chip: Pick<OrderAnalysisChipProps, 'clickable' | 'onClick'> & {
    /**
     * @default Function that returns true
     */
    isInteractive?: (analysis: AnalysisFront) => boolean;
    selected?: (analysis: AnalysisFront) => boolean;
  };
  groupActions?: OrderAnalysesGridGroupActions;

  groupBy: (analyses: AnalysisFront[]) => OrderAnalysisGrouped;
  canSelectAll?: boolean;
};

export const OrderAnalysesGrid = ({ analyses, groupBy, ...props }: OrderAnalysesGridProps): JSX.Element => {
  const { fNCurrency } = useFormatters();
  const { openModal } = useModal();
  const { authApi } = useApi();
  const me = authApi.useGetMe();

  const analysisGrouped = useMemo(
    // `group` complexity can easily be O(n^2), so try to reduce re-calculations
    () =>
      groupBy(
        analyses
          .slice()
          .sort(
            (a, b) =>
              (a.orderInCatalogue ?? Number.POSITIVE_INFINITY) - (b.orderInCatalogue ?? Number.POSITIVE_INFINITY),
          ),
      ),
    [analyses, groupBy],
  );

  if (me.isLoading || me.isFetching || !me.data) {
    return <Spinner />; // Should never occur
  }

  const isAnalysisSelected = props.chip.selected;
  const isChipInteractive = props.chip.isInteractive ?? (() => true);

  const hasGroupActions = !!(
    props.canSelectAll &&
    isAnalysisSelected &&
    props.groupActions?.onAdd &&
    props.groupActions?.onRemove
  );

  const areAllSelected = (list: AnalysisFront[]) => {
    if (isAnalysisSelected) {
      return list.reduce((selected, analysis) => selected && isAnalysisSelected(analysis), true);
    }

    return false;
  };

  const toggleGroup = (groupList: AnalysisFront[], checked: boolean) => {
    const { onAdd, onRemove } = props.groupActions ?? {};
    if (!onAdd || !onRemove || !isAnalysisSelected) {
      // Could use `hasGroupActions` but kept the conditions locally, so TS can determine that the values are defined
      return;
    }

    const analysesGroup = groupList.filter(isChipInteractive);
    if (checked) {
      onAdd(analysesGroup.filter(analysis => !isAnalysisSelected(analysis)));
    } else {
      onRemove(analysesGroup);
    }
  };

  return (
    <div className="columns-2xs gap-8">
      {Object.entries(analysisGrouped).map(([group, groupList]) => {
        const groupTitle = <h3 className="text-primary py-2 text-lg font-medium uppercase">{group}</h3>;

        return (
          <div key={groupList.id} className="mb-4 break-inside-avoid-column">
            <div className="flex items-center">
              <div className="flex-grow">
                {hasGroupActions ? (
                  <Checkbox
                    onChange={({ target: { checked } }) => toggleGroup(groupList.analysis, checked)}
                    checked={areAllSelected(groupList.analysis)}
                  >
                    {groupTitle}
                  </Checkbox>
                ) : (
                  groupTitle
                )}
              </div>

              {hasGroupActions && (
                <Icon
                  icon="penToSquare"
                  className="text-gray-400"
                  onClick={() => openModal('editFavorites', { updateId: groupList.id })}
                />
              )}
            </div>

            <ul className="border-primary border-l-2 border-solid pl-4">
              {groupList.analysis.map(analysis => {
                const price = analysis.pricing?.value ?? -1;

                return (
                  <li key={analysis.id} className="flex flex-row">
                    <div className="flex-1">
                      <OrderAnalysisChip
                        analysis={analysis}
                        clickable={(isChipInteractive(analysis) && props.chip.clickable) ?? true}
                        onClick={props.chip.onClick}
                        selected={isAnalysisSelected && isAnalysisSelected(analysis)}
                      />
                    </div>

                    {me.data.doctor?.displayPrincingInLablink === true && price >= 0 && (
                      <span className="text-sm text-gray-400">{fNCurrency(price)}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
