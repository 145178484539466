import twTheme from '@root/src/tailwind.theme';
import { Config } from 'tailwindcss';
import resolveConfig from 'tailwindcss/resolveConfig';

export const twConfig: Config = resolveConfig({
  content: [],
  theme: twTheme,
});

/**
 * The existing breakpoints
 */
export type TWScreenBreakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface GetTWBreakpointOptionsPx {
  /**
   * Convert the value given as pixel (number)
   */
  asPx: true;
}
interface GetTWBreakpointOptionsStr {
  asPx?: false;
}

/**
 * Options when getting Tailwind Breakpoints
 */
export type GetTWBreakpointOptions = GetTWBreakpointOptionsPx | GetTWBreakpointOptionsStr;

/**
 * @return the tailwind breakpoint value as pixel (number)
 */
export function getTWBreakpoint(breakpoint: TWScreenBreakpoint, options: GetTWBreakpointOptionsPx): number;
/**
 * @return the tailwind breakpoint value as stored (like `<value>px`)
 */
export function getTWBreakpoint(breakpoint: TWScreenBreakpoint, options?: GetTWBreakpointOptionsStr): string;
export function getTWBreakpoint(breakpoint: TWScreenBreakpoint, options: GetTWBreakpointOptions = {}): number | string {
  // Supposedly, the screen is correct and an unknown breakpoint is the developer's fault
  const screen = (twConfig.theme!.screens! as Record<TWScreenBreakpoint, string>)[breakpoint];

  if (options.asPx) {
    return Number(screen.slice(0, screen.indexOf('px')));
  }

  return screen;
}
