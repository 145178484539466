import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ICONS, IconType } from './icons';

export type IconProps = Pick<FontAwesomeIconProps, 'onClick'> & {
  className?: string;
  icon: IconType;
  spin?: boolean;
};

export const Icon = ({ icon, className, onClick, spin }: IconProps): JSX.Element => {
  if (icon === 'loading') {
    return <Icon icon="spinner" spin className={className} onClick={onClick} />;
  }

  if (onClick) {
    // eslint-disable-next-line no-param-reassign
    className = classNames('cursor-pointer pointer-events-auto', className);
  }

  if (spin) {
    // eslint-disable-next-line no-param-reassign
    className = classNames('animate-spin', className);
  }

  return <FontAwesomeIcon className={className} icon={ICONS[icon]} onClick={onClick} />;
};
