import { getRoute, routeRegex } from '@services/routes';
import { useTranslation } from 'next-i18next';
import { Header } from './Header';
import { HeaderMenu } from './HeaderMenu';
import { HeaderUser } from './HeaderUser';

const menuItems = [
  { activeRegex: routeRegex.order, label: 'header.menu.Orders', url: getRoute.home().pathname },
  { activeRegex: routeRegex.patient, label: 'header.menu.Patients', url: getRoute.patients().pathname },
  { activeRegex: routeRegex.resources, label: 'header.menu.Resources', url: getRoute.resources().pathname },
  { activeRegex: routeRegex.contacts, label: 'header.menu.Contacts', url: getRoute.contacts().pathname },
];

export const AppHeader = (): JSX.Element => {
  const { t } = useTranslation('common');

  const translatedMenuItems = menuItems.map(item => ({ ...item, label: t(item.label) }));

  return <Header left={<HeaderMenu items={translatedMenuItems} />} right={<HeaderUser />} />;
};
