/*
Some booleans in the database are stored as `bit` which is retrieved as a Buffer by the database...
 */

import { ValueTransformer } from 'typeorm';

export const booleanTransformer: ValueTransformer = {
  from(value: Buffer | boolean | null | number): boolean {
    if (typeof value === 'boolean') {
      return value;
    }

    const readValue = typeof value === 'number' ? value : value?.readInt8() ?? 0;
    return !(readValue === 0 || readValue === '0'.charCodeAt(0));
  },
  to(value: unknown) {
    return value;
  },
};

/**
 * Some booleans in the database are stored as 'Y'/'N' instead of a boolean column
 */
export const yesNoTransformer: ValueTransformer = {
  from: (value: string | null): boolean => value === 'Y',
  to: (value: boolean | null): string => (value ? 'Y' : 'N'),
};
