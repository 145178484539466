import { MandatorInterface, booleanTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { IsBoolean, IsNumber, IsOptional, MaxLength } from '@ch-apptitude-icc/common/shared/validation';
import { Exclude } from 'class-transformer';
import { Column, Index, PrimaryColumn } from 'typeorm';
import { DoctorBase } from './embeded';

export class Doctor extends DoctorBase implements MandatorInterface {
  @PrimaryColumn('int', { name: 'pkMedecin' })
  @IsNumber()
  id!: number;

  /** Disabled doctor are not listed by default */
  @Column({ default: false, name: 'disabled', nullable: false })
  @IsBoolean()
  @IsOptional()
  disabled?: boolean;

  @Column('varchar', { length: 25, name: 'Login', nullable: true })
  @MaxLength(25)
  username?: string | null;

  /**
   * @deprecated this column is empty and not used
   */
  @Column('varchar', {
    length: 255,
    name: 'HashPassword',
    nullable: true,
    select: false,
  })
  @Exclude()
  private passwordHash?: string;

  @Column('bit', { default: () => "'b'0''", name: 'DejaCli', transformer: booleanTransformer })
  @IsBoolean()
  alreadyCustomer?: boolean;

  @Column('boolean', { default: () => 'true' })
  @IsBoolean()
  enableLablink?: boolean;

  @Column('boolean', { name: 'allowMandatorBilling', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  allowMandatorBilling?: boolean;

  @Column('boolean', { name: 'displayPrincingInLablink', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  displayPrincingInLablink?: boolean;

  @Column('boolean', { name: 'forceMandatorBilling', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  forceMandatorBilling?: boolean;

  @Column('boolean', { name: 'allowAnonymousPatients', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  allowAnonymousPatients?: boolean;

  @Column('boolean', { name: 'allowPatientSummoning', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  allowPatientSummoning?: boolean;

  @Column('boolean', { name: 'enableDepartment', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  enableDepartment?: boolean;

  @Column('boolean', { name: 'enableOrderListLimit', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  enableOrderListLimit?: boolean;

  @Column('boolean', { name: 'markReportsAsReadAutomatically', default: () => 'false' })
  @IsBoolean()
  @IsOptional()
  markReportsAsReadAutomatically?: boolean;

  /**
   * Computed column
   */
  @Column({
    asExpression: "CONCAT(IFNULL(`Nom`, ''), ' ', IFNULL(`Prénom`, ''), ' ', IFNULL(`Nom`, ''))",
    generatedType: 'STORED',
    insert: false,
    select: false,
    update: false,
  })
  @Index('idx_searchColumn')
  @Exclude()
  searchColumn?: string;
}
