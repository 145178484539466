import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { SamplingMaterial } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.samplingMaterials();

export class SamplingMaterialApi extends ApiFactory<SamplingMaterial, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}
