import { PatientInterface } from '@ch-apptitude-icc/common/shared/entities';
import { IsBoolean, IsNumber, IsOptional } from '@ch-apptitude-icc/common/shared/validation';
import { Exclude } from 'class-transformer';
import { Column, Index, PrimaryColumn } from 'typeorm';
import { PatientBase } from './embeded';

export class Patient extends PatientBase implements PatientInterface {
  @PrimaryColumn({ name: 'pkPatient' })
  @IsNumber()
  @IsOptional()
  id?: number;

  // TODO ID-1459: on `PatientBase` (not set yet => error with other entities) ?
  /** Disabled patients are not listed by default */
  @Column({ default: false, name: 'disabled', nullable: false })
  @IsBoolean()
  @IsOptional()
  disabled?: boolean;

  /**
   * Computed column, computed in the MSSQL database.
   */
  @Column({
    asExpression:
      "CONCAT(IFNULL(`Nom`, ''), ' ', IFNULL(`Prénom`, ''), ' ', IFNULL(`Nom`, ''), ' ', IFNULL(`NoPolice`, ''), ' ', IFNULL(`Tel`, ''), ' ', IFNULL(`DateNaissance`, ''))",
    generatedType: 'STORED',
    insert: false,
    select: false,
    update: false,
  })
  @Index('idx_searchColumn')
  @Exclude()
  searchColumn?: string;
}
