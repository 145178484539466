import { APIClient, ClientOptions, APIClientInternals } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { Type } from '@ch-apptitude-icc/common/shared/type-utils';
import { User } from '@ch-apptitude-icc/lablink/shared/entities';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface AuthClient<EntityType extends Entity<unknown>> extends APIClient<EntityType, never> {
  login(
    username: string,
    password: string,
    rememberMe: boolean,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EntityType>>;

  nonInteractiveLogin(username: string, auth: string, options?: AxiosRequestConfig): Promise<AxiosResponse<EntityType>>;

  logout(options?: AxiosRequestConfig): Promise<AxiosResponse<void>>;

  me(options?: AxiosRequestConfig): Promise<AxiosResponse<EntityType>>;
}

class AuthClientImpl<EntityType extends Entity<unknown>>
  extends APIClientInternals.BaseClient<EntityType>
  implements AuthClient<EntityType>
{
  constructor({ entityType, resourcePath, options }: APIClientInternals.APIClientSchemaBasedExtensions<EntityType>) {
    super(resourcePath, entityType, options);
  }

  async login(
    username: string,
    password: string,
    rememberMe: boolean,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EntityType>> {
    return axios
      .post<EntityType>(
        `${this.resourcePath}/login`,
        { username, password },
        {
          ...this.options,
          ...options,

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          params: {
            ...this.options?.params,
            ...options?.params,
            rememberMe,
          },
        },
      )
      .then(response => ({ ...response, data: this.decodeFromBackend(response.data) }));
  }

  async nonInteractiveLogin(
    username: string,
    auth: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EntityType>> {
    return axios
      .post<EntityType>(
        `${this.resourcePath}/non-interactive`,
        { username, auth },
        {
          ...this.options,
          ...options,
        },
      )
      .then(response => ({ ...response, data: this.decodeFromBackend(response.data) }));
  }

  async me(options?: AxiosRequestConfig): Promise<AxiosResponse<EntityType>> {
    const path = `${this.resourcePath}/me`;
    return axios
      .get<EntityType>(path, {
        ...this.options,
        ...options,
      })
      .then(response => ({ ...response, data: this.decodeFromBackend(response.data) }));
  }

  async logout(options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
    const path = `${this.resourcePath}/logout`;
    return axios.post<void>(path, undefined, {
      ...this.options,
      ...options,
    });
  }
}

const baseAuthClient = APIClientInternals.emptyClient(User, 'auth');

export const authClient =
  <RealEntityType extends Entity<unknown> = User>(overrideEntity?: Type<RealEntityType>) =>
  (optionsIn?: ClientOptions<RealEntityType>): AuthClient<RealEntityType> =>
    new AuthClientImpl<RealEntityType>(baseAuthClient(overrideEntity)(optionsIn));
