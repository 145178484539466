import { selectAnalysisSavedToFavorite } from '@features/orders/store/OrderState/selectors';
import { Button, Icon } from '@features/ui/components';
import { useModal } from '@features/ui/modal';
import { useAppSelector } from '@services/store/hooks';
import { useTranslation } from 'next-i18next';

export type OrderAnalysesCreateTemplateProps = {
  selectedAnalyses: { analysisId: number }[];
};

export const OrderAnalysesCreateTemplate = ({ selectedAnalyses }: OrderAnalysesCreateTemplateProps): JSX.Element => {
  const { t } = useTranslation('common');
  const { openModal } = useModal();
  const isSavedToFavorite = useAppSelector(selectAnalysisSavedToFavorite);

  const saveToFavBtn = (
    <Button
      leftIcon="star"
      label={t('pages.order.analysis.saveToFavorites')}
      variant="link"
      onClick={() => openModal('editFavorites', { selectedAnalyses })}
    />
  );
  const savedToFavInfo = (
    <div className="font-medium text-emerald-800">
      <div className="inline-block pr-2">
        <Icon icon="check" />
      </div>

      {t('pages.order.analysis.savedToFavorites')}
    </div>
  );

  return (
    <div className="align align-start flex flex-col gap-y-5 lg:w-1/3">
      <div className="grow-0">{isSavedToFavorite ? savedToFavInfo : saveToFavBtn}</div>
    </div>
  );
};
