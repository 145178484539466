import { type OrderCreateDto } from '@ch-apptitude-icc/lablink/shared/entities';
import { FrontEntityRead } from '@common/frontend/services/api-client';
import { CreateOrderFront } from '@features/orders/types/CreateOrderFront';
import { DeepPartial } from '@reduxjs/toolkit';
import { InsurerFront, OrderFront } from '@services/api';
import { Serialized } from '@utils/serialize';

// The "DTO" that could be stored and managed in redux
type OrderCreate = DeepPartial<Serialized<OrderCreateDto>>;

// The patient fields managed in the creation
export type SerializedPatientFields = Partial<
  Pick<
    NonNullable<OrderCreate['patient']>,
    | 'address'
    | 'anonymous'
    | 'birthDate'
    | 'birthYear'
    | 'emailAddress'
    // This is used to determine when an existing user is used
    | 'existingPatientId'
    | 'externalReference'
    | 'firstname'
    | 'insurerId'
    | 'gender'
    | 'lastname'
    | 'socialSecurityNumber'
    | 'telephoneNumber'
    | 'weight'
  >
>;

export interface OrderOrderData
  extends Pick<
    OrderCreate,
    | 'billedTo'
    | 'invoicing'
    | 'remark'
    | 'urgent'
    | 'copyToPatient'
    | 'orderingDepartment'
    | 'isAddition'
    | 'summonClient'
  > {
  patient?: Pick<
    SerializedPatientFields,
    'address' | 'emailAddress' | 'firstname' | 'insurerId' | 'lastname' | 'socialSecurityNumber' | 'telephoneNumber'
  >;
  secondDoctorData?: Partial<OrderCreateDto['secondDoctorData']>;
  /** Not present in backend */
  sendCopyToSecondDoctor?: boolean;
}

export type OrderMaterialData = Pick<OrderCreate, 'otherMaterial' | 'sampleDate' | 'samplingStatus' | 'summonClient'>;

/**
 * Add extra data to orderState.
 * These values are used to facilitate validation or ease the user experience
 */
export interface OrderStateExtra {
  insurer?: InsurerFront;
}

// TODO: add more status? (opened, skipped?)
export type OrderStepStatus = 'untouched' | 'dirty' | 'done';

export interface OrderStepState {
  status: OrderStepStatus;
  // Can add more info for specific step
}

export interface OrderState {
  /**
   * Only contains data for create/update
   */
  data: CreateOrderFront;

  existingOrderData?: { id: number; requestDate?: string; order: FrontEntityRead<OrderFront> };
  /**
   * The current step being edited (||= fully open)
   */
  editing: OrderStepKeys | false;

  /**
   * The ID of an order that was just created (to display the "created success" header)
   */
  orderJustCreatedId?: number;

  serverErrors: string[];

  extra: OrderStateExtra;

  insurersList?: Array<InsurerFront> | null;

  steps: {
    analysis: OrderStepState & { savedToFavorite?: boolean };
    material: OrderStepState;
    order: OrderStepState & { sendCopyToSecondDoctor?: boolean };
    patient: OrderStepState;
  };
}

export type OrderStepKeys = keyof OrderState['steps'];

export const OrderStepKeysOrdering: OrderStepKeys[] = ['patient', 'analysis', 'order', 'material'];
