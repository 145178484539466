import { IsBoolean, IsIn, IsNotEmpty, IsOptional } from '@ch-apptitude-icc/common/shared/validation';
import { type ContactType, ContactTypes } from '../enums';

export class ContactDto {
  @IsIn(ContactTypes)
  type!: ContactType;

  @IsNotEmpty()
  message!: string;

  /**
   * If true, the user will receive a copy of their email
   */
  @IsBoolean()
  @IsOptional()
  requestCopy?: boolean;
}
