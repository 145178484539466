import { Skeleton } from '@features/ui/components/Skeleton';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable, PaginationState } from '@tanstack/react-table';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { NoResults } from './NoResults';
import { Pagination } from './Pagination';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableColumn<TData> = (ColumnDef<TData, any> & { withClassName?: string })[];

type TableProps<TData> = {
  columns: TableColumn<TData>;
  data?: { results?: TData[]; total?: number };
  isError: boolean;
  isLoading: boolean;
  noResultsItems: string[];
  onRowClick?: (row: TData) => void;
  pagination?: [PaginationState, (pagination: PaginationState) => void];
};

export function Table<TData>({ data, columns, isError, onRowClick, ...props }: TableProps<TData>): JSX.Element {
  const [pagination, setPagination] = props.pagination ?? [undefined, undefined];
  const { t } = useTranslation('common');

  const withClassNameMap = useMemo(
    () =>
      columns.reduce((acc, col) => {
        if (col.id) {
          // eslint-disable-next-line no-param-reassign
          acc[col.id] = col.withClassName;
        }
        return acc;
      }, {} as Record<string, string | undefined>),
    [columns],
  );

  const pageCount =
    data?.results != null && data?.total != null && pagination ? Math.ceil(data.total / pagination.pageSize) : -1;

  const table = useReactTable({
    columns,
    data: data?.results ?? [],
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    // @ts-expect-error -- The type is right but TS does not see it, it comes from the doc directly
    onPaginationChange: setPagination,
    pageCount,
    state: {
      pagination,
    },
  });

  return (
    <div className="flex h-full flex-col">
      <table className="mb-auto w-full divide-y divide-gray-200 border-b border-gray-200">
        <thead className="h-11">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className={classNames('text-2xs text-left font-normal text-gray-500', withClassNameMap[header.id])}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {!isError ? (
            table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                onClick={() => (onRowClick ? onRowClick(row.original) : null)}
                className={classNames('h-12 even:bg-gray-50', {
                  'cursor-pointer hover:bg-sky-50': onRowClick != null,
                })}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={classNames(
                      'pr-2 text-left text-sm text-gray-500 first:pl-3 last:pr-3',
                      withClassNameMap[cell.column.id],
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className="p-3 text-red-400">{t('component.table.errorLoadingData')}</td>
            </tr>
          )}
          {props.isLoading &&
            [...Array(pagination?.pageSize ?? 10).keys()].map((row, rowIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr className="h-12 even:bg-gray-50" key={rowIndex}>
                {table.getAllColumns().map(column => (
                  <td key={column.id} className={withClassNameMap[column.id]} aria-label={t('common.terms.loading')}>
                    <Skeleton width="w-4/5" height="h-2.5" />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {!props.isLoading && data?.results?.length === 0 && <NoResults items={props.noResultsItems} />}
      {!props.isLoading && pagination && (
        <Pagination
          onNextPageClick={() => table.nextPage()}
          onPreviousPageClick={() => table.previousPage()}
          onSetPageIndex={index => table.setPageIndex(index)}
          totalElements={data?.total ?? 0}
          currentPage={pagination.pageIndex}
          maxPage={pageCount}
          pageSize={pagination.pageSize}
        />
      )}
    </div>
  );
}
