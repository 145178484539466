import { ValueTransformer } from 'typeorm';

export const numberArrayTransformer: ValueTransformer = {
  from: (value: string | null): number[] =>
    value
      ? value
          .split(',')
          .map(_ => _.trim())
          .filter(_ => _.match(/^\d+$/))
          .map(v => +v)
      : [],
  to: (value?: number[]): string | null => (value && value.length ? value.join(',') : null),
};
