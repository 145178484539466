import { plainify } from '@ch-apptitude-icc/common/shared/utils';
import { CreateOrderFront } from '@features/orders/types/CreateOrderFront';
import { CreateOrderStepAnalysis } from '@features/orders/types/CreateOrderStepAnalysis';
import { CreateOrderStepMaterials } from '@features/orders/types/CreateOrderStepMaterials';
import { CreateOrderStepOrder } from '@features/orders/types/CreateOrderStepOrder';
import { CreateOrderStepPatient } from '@features/orders/types/CreateOrderStepPatient';
import { OrderFront } from '@features/orders/types/OrderFront';

export function createOrderFromOrder({ id: _1, requestDate: _2, ...orderFront }: OrderFront): CreateOrderFront {
  // Parse steps
  // @ts-expect-error We are building this order step by step
  let data: CreateOrderFront = {};
  const order = orderFront as CreateOrderFront;

  data = CreateOrderStepPatient.mergeTo(CreateOrderStepPatient.from(order), data);
  data = CreateOrderStepAnalysis.mergeTo(new CreateOrderStepAnalysis(order.requestedAnalysis), data);
  data = CreateOrderStepOrder.mergeTo(CreateOrderStepOrder.from(order), data);
  data = CreateOrderStepMaterials.mergeTo(CreateOrderStepMaterials.from(order), data);
  data.status = order.status;

  return plainify(data);
}
