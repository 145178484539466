import { Icon } from '@features/ui/components';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ChangeEvent, FocusEvent, Fragment, ReactNode, useMemo } from 'react';

export type ValueType = string | number;

export type SelectElement<K extends ValueType> = {
  label: string;
  /**
   * Unique value
   */
  value: K;
};

// We do an illegal cast because we don't want value to be undefined in the SelectElement, this is acceptable for this special usecase
const unselectElement = <K extends ValueType>() => ({ label: '--', value: null } as unknown as SelectElement<K>);

type Selection<K, Multiple> = Multiple extends false ? K : Multiple extends undefined ? K : K[];

export type SelectProps<K extends ValueType, Multiple extends boolean | undefined = undefined> = {
  customButton?: ReactNode;
  defaultValue?: Selection<K, Multiple>;
  multiple?: Multiple;
  options: SelectElement<K>[];
  required?: boolean;
  value?: Selection<K, Multiple>;
  error?: boolean;
} & Pick<JSX.IntrinsicElements['input'], 'name' | 'onBlur' | 'onChange' | 'disabled'>;

export const Select = <K extends ValueType, Multiple extends boolean | undefined = undefined>({
  options,
  onBlur,
  onChange,
  multiple,
  defaultValue,
  value,
  customButton,
  disabled,
  ...props
}: SelectProps<K, Multiple>): JSX.Element => {
  const selectedValue = value != null && (value !== '' || !props.required) ? value : defaultValue;

  const extendedOptions = useMemo(
    () => (props.required ? options : [unselectElement<K>(), ...options]),
    [options, props.required],
  );

  return (
    <Listbox
      value={selectedValue}
      onBlur={() => {
        if (onBlur) {
          // This is a hack because Formik fails to work with strings for unknown reasons so we need to forge a fake event
          onBlur({ target: { name: props.name, value } } as unknown as FocusEvent<HTMLInputElement>);
        }
      }}
      onChange={newValue => {
        if (onChange) {
          // This is a hack because Formik fails to work with strings for unknown reasons so we need to forge a fake event
          onChange({ target: { name: props.name, value: newValue } } as unknown as ChangeEvent<HTMLInputElement>);
        }
      }}
      as="div"
      className={classNames({ 'w-full': !customButton })}
      multiple={multiple}
      name={props.name}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={classNames('relative', { 'h-full w-full': customButton })}>
          <Listbox.Button
            className={classNames({
              'hover:border-primary': !customButton,
              'outline-primary border-transparent outline outline-2 outline-offset-0 ring-0': !customButton && open,
              'relative w-full cursor-default rounded-md border border-gray-200 py-2 pl-3 pr-10 text-left text-gray-800 sm:text-sm':
                !customButton,
              'h-full w-full': customButton,
              'border-red-400': props.error,
            })}
          >
            {customButton || (
              <>
                <span className="block truncate">{selectedString(selectedValue, extendedOptions)}</span>
                <span
                  className={classNames('pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2', {
                    'text-red-400': props.error,
                  })}
                >
                  <Icon icon="chevronDown" className="h-2" />
                </span>
              </>
            )}
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full min-w-min overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {extendedOptions.map(option => (
                <Listbox.Option
                  key={option.value ?? 'none'}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-cyan-700 text-white' : 'text-gray-800',
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                    )
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          {
                            'text-primary': !active && selected,
                            'text-white': active && selected,
                          },
                          'block truncate',
                        )}
                      >
                        {option.label}
                      </span>

                      {selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-1.5">
                          <Icon icon="check" className="text-green-400" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

function selectedString<K extends ValueType>(selectedValue: K | K[] | undefined, options: SelectElement<K>[]): string {
  const emptyString = '--';
  if (Array.isArray(selectedValue)) {
    return selectedValue.length === 0
      ? emptyString
      : selectedValue.map(x => options.find(o => o.value === x)?.label).join(', ');
  }
  return options.find(o => o.value === selectedValue)?.label ?? emptyString;
}
