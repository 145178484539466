import { IsNumber, IsObject, IsString, ValidateNested } from '@ch-apptitude-icc/common/shared/validation';
import { Type } from 'class-transformer';

export class AuthImpersonator {
  /** Display name of the impersonate */
  @IsString()
  public name!: string;

  // In an object in case more data is required (e.g.: local userId of the impersonator)
}

/**
 * When someone want to impersonate an user
 *
 * From AM -> LL
 */
export class AuthImpersonateDto {
  /** (Remote) Mandator to impersonate */
  @IsNumber()
  public mandatorId!: number;

  @IsObject()
  @ValidateNested()
  @Type(() => AuthImpersonator)
  public impersonator!: AuthImpersonator;

  /**
   * Can the user makes changes while impersonating
   * => !readonly
   */ // TODO ID-1454
  // @IsBoolean()
  // public editable? = false;
}

export interface AuthImpersonateResponse {
  /** Host where the impersonation is valid (can be used for redirect) */
  host: string;
  /** ~= API KEY */
  sessionId: string;
}
