import { orderStateSlice } from '@features/orders/store/OrderState/slice';
import { configureStore } from '@reduxjs/toolkit';
import { envConfig } from '@root/envConfig';
import { pageStateSlice } from '@services/store/pageStateSlice';
import { createWrapper } from 'next-redux-wrapper';

const makeStore = () =>
  configureStore({
    devTools: envConfig.devTool,
    reducer: { [pageStateSlice.name]: pageStateSlice.reducer, [orderStateSlice.name]: orderStateSlice.reducer },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['orderState/setOrderStepData'],
        },
      }),
  });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const storeWrapper = createWrapper<AppStore>(makeStore);
