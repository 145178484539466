import path from 'path';
import { Configuration as RollbarConfiguration } from 'rollbar';
import urlConfig, { UrlConfig } from './url.config';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SERVER_ENVIRONMENTS {
  local = 'local',
  dev = 'dev',
  preprod = 'preprod',
  prod = 'prod',

  fulllocal = 'fulllocal',
}

const SERVER_ENV: SERVER_ENVIRONMENTS =
  SERVER_ENVIRONMENTS[process.env.NEXT_PUBLIC_SERVER_ENVIRONMENT as SERVER_ENVIRONMENTS] || SERVER_ENVIRONMENTS.local;

type Environment = {
  constants: {
    maxAnalyses: number;
    api: {
      unboundedPageSize: number;
    };
    searchDelay: number;
  };
  devTool: boolean;
  environment: SERVER_ENVIRONMENTS;
  info: {
    mail: string;
    phone: string;
    site: string;
  };
  localePath: string;
  pdfUrls: {
    aromatogramme: string;
    consentementAnalyse: string;
    demandeMedicaments: string;
  };
  rollbar: RollbarConfiguration & { serverAccessToken?: string };
  urls: {
    cryptoInstall: string;
    vademecum: string;
  };
  /**
   * If we detected that the backend is down, how often should we check if it is back up? (in milliseconds)
   */
  downRetryTimeout: number;
};

const defaultEnv: Environment = {
  devTool: true,
  environment: SERVER_ENVIRONMENTS.local,
  info: {
    mail: 'contact@laboicc.ch',
    phone: '0213113131',
    site: 'https://www.laboicc.ch/',
  },
  localePath: path.resolve('./public/locales'),
  constants: {
    api: {
      unboundedPageSize: 2 ** 32,
    },
    searchDelay: 500,
    maxAnalyses: 38,
  },
  rollbar: {
    enabled: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_KEY,
    scrubTelemetryInputs: true,
    payload: {
      environment: `lablink-frontend-${SERVER_ENV}`,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.NEXT_BUILD_ID,
          guess_uncaught_frames: true,
        },
      },
    },
  } satisfies RollbarConfiguration,
  urls: {
    vademecum: 'https://www.laboicc.ch/Vademecum/Vademecum1.php',
    cryptoInstall: 'https://www.laboicc.ch/accueil-securite/cryptage-des-rapports',
  },
  pdfUrls: {
    aromatogramme: '/pdfs/Formulaire AROMATOGRAMME.pdf',
    demandeMedicaments: '/pdfs/FichedemandeMedicaments_v26.pdf',
    consentementAnalyse: '/pdfs/ConsentementAnalyseGen1.pdf',
  },
  downRetryTimeout: 10000,
};

const configs: {
  [K in SERVER_ENVIRONMENTS]: Partial<Environment>;
} = {
  local: {
    localePath: path.resolve('apps/lablink-front/public/locales'),
    rollbar: { enabled: false },
  },
  [SERVER_ENVIRONMENTS.fulllocal]: {
    localePath: path.resolve('apps/lablink-front/public/locales'),
    rollbar: { enabled: false },
  },
  prod: {
    devTool: false,
  },
  dev: {},
  preprod: {},
};

export const envConfig = {
  ...defaultEnv,
  ...configs[SERVER_ENV],
  environment: SERVER_ENV,
  url: (urlConfig as UrlConfig)[SERVER_ENV],
} as const;
