import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { CatalogueAnalysis } from './catalogue-analysis';
import { PricingCategory } from './pricing-category';

/**
 * A group of analysis that a doctor can be added to.
 */
export class Catalogue {
  @PrimaryGeneratedColumn()
  id!: number;

  @Column('varchar')
  name!: string;

  @Column('int', { default: 0 })
  order!: number;

  pricingCategory?: PricingCategory | null;

  analysis?: CatalogueAnalysis[];

  @Column('boolean', { default: false })
  small!: boolean;
}
