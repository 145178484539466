import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { OrderOrReference } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.ordersAndReferences(OrderOrReference);

export class OrderAndRefApi extends ApiFactory<OrderOrReference, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}

export { OrderOrReference as OrderOrReferenceFront };
