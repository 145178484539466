import { plainify } from '@ch-apptitude-icc/common/shared/utils';
import { OrderSamplingStatus, OrderStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { UrlOrder } from '@features/orders/services/useUrlOrder';
import { CreateOrderFront } from '@features/orders/types/CreateOrderFront';
import { CreateOrderFrontStep } from '@features/orders/types/CreateOrderFrontStep';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { InsurerFront } from '@services/api';
import { Serialized } from '@utils/serialize';
import { plainToInstance } from 'class-transformer';
import { DeepPartial } from 'typeorm';
import { OrderState, OrderStepKeys, OrderStepStatus } from './types';

function generateState(): OrderState {
  const data: CreateOrderFront = {
    requestedAnalysis: [],
    billedTo: null,
    remark: '',
    // This need to be null or redux will not like
    sampleDate: null,
    samplingStatus: OrderSamplingStatus.SAMPLING_DONE,
    summonClient: false,
    status: OrderStatus.TO_ORDER,
    copyToPatient: false,
    orderingDepartment: null,
  };

  return {
    data,
    editing: 'patient',
    serverErrors: [],
    extra: {},
    insurersList: null,
    steps: {
      analysis: {
        status: 'untouched',
      },
      material: {
        status: 'untouched',
      },
      order: {
        status: 'untouched',
      },
      patient: {
        status: 'untouched',
      },
    },
  };
}

type State = Draft<OrderState>;

export interface OrderSetStepStatus {
  key: OrderStepKeys;
  status: OrderStepStatus;
}

interface SetExtraInsurerPayload {
  insurer?: Serialized<InsurerFront>;
}

export const orderStateSlice = createSlice({
  initialState: generateState(),
  name: 'orderState',

  reducers: {
    resetOrder: generateState,

    setAnalysisSavedToFavorite(state: State, action: PayloadAction<boolean>) {
      state.steps.analysis.savedToFavorite = action.payload;
    },

    /**
     * Set the given step as the one being edited
     */
    setOrderEditingStep: (state: State, action: PayloadAction<OrderStepKeys>) => {
      state.editing = action.payload;
    },

    setOrderJustCreated: (state: State, action: PayloadAction<number>) => {
      state.orderJustCreatedId = action.payload;
    },

    resetOrderJustCreated: (state: State) => {
      state.orderJustCreatedId = undefined;
    },

    /**
     * Set the data for the given step. Please provide either a class instance, or a plain JS object with the
     * matching class type (for the merge function)
     */
    setOrderStepData: <T extends object, U extends CreateOrderFrontStep<T> = CreateOrderFrontStep<T>>(
      state: State,
      action: PayloadAction<InstanceType<U>>,
      type?: U,
    ) => {
      const { payload } = action;
      const { data } = state;

      const constructor = type ?? (payload.constructor as U);
      state.data = plainify(constructor.mergeTo(payload, data));

      // Clear server errors (maybe a bit too nicely, but worst case scenario the server sends them back)
      state.serverErrors = state.serverErrors.filter(error => {
        const field = error.split(':')[0];

        return !constructor.pickedFields.find(
          pickedField => field === pickedField || field?.startsWith(`${String(pickedField)}.`),
        );
      });
    },

    /**
     * Set the status for the given step
     */
    setOrderStepStatus: (state: State, action: PayloadAction<OrderSetStepStatus>) => {
      state.steps[action.payload.key].status = action.payload.status;
    },

    /**
     * This also "reset" the state
     */
    setOrderData: (
      previous: State,
      action: PayloadAction<CreateOrderFront & { existing?: OrderState['existingOrderData'] }>,
    ) => {
      const state = generateState();
      const { existing, ...order } = action.payload;

      state.data = order;
      state.serverErrors = [];
      state.orderJustCreatedId = previous.orderJustCreatedId === existing?.id ? previous.orderJustCreatedId : undefined;
      state.existingOrderData = existing;

      if (existing?.id) {
        for (const step of Object.values(state.steps)) {
          step.status = 'done';
        }

        state.editing = false;
      }

      return state;
    },

    /**
     * This also "reset" the state
     */
    setOrderDataFromUrl: (previous: State, action: PayloadAction<DeepPartial<UrlOrder>>) => {
      const state = generateState();
      const order = action.payload;

      state.serverErrors = [];
      state.data = plainToInstance(CreateOrderFront, {
        ...generateState().data,
        ...order,
      } satisfies DeepPartial<CreateOrderFront>);

      return state;
    },

    setServerErrors: (state: State, action: PayloadAction<string[]>) => {
      state.serverErrors = action.payload;
      return state;
    },

    /**
     * Add or replace the extra data
     */
    setOrderExtraInsurer: (state: State, action: PayloadAction<SetExtraInsurerPayload>) => {
      const { payload } = action;
      state.extra.insurer = payload.insurer;
    },

    setInsurersList: (state: State, action: PayloadAction<{ insurersList: Array<Serialized<InsurerFront>> }>) => {
      const { payload } = action;
      state.insurersList = payload.insurersList;
    },
  },
});

export const {
  resetOrder,
  setOrderDataFromUrl,
  setOrderData,
  setOrderEditingStep,
  setOrderStepStatus,
  setAnalysisSavedToFavorite,
  setOrderExtraInsurer,
  setInsurersList,
  setServerErrors,
  setOrderStepData,
  setOrderJustCreated,
  resetOrderJustCreated,
} = orderStateSlice.actions;
