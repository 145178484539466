import { OrderStatus } from '@ch-apptitude-icc/lablink/shared/entities';
import { statusToChipsVariant } from '@features/orders/utils/';
import { Button } from '@features/ui/components';
import { Modal, ModalProps } from '@features/ui/modal';
import { TFunction, useTranslation } from 'next-i18next';

const ExplanationLine = ({ status, desc, t }: { desc: string; status: OrderStatus; t: TFunction }) => (
  <div className="flex flex-col pb-7 sm:flex-row">
    <div className="pr-12 pb-2 sm:pb-0">
      <div className="w-28">{statusToChipsVariant[status](t, { selected: true })}</div>
    </div>
    <div className="text-sm">{desc}</div>
  </div>
);

export const StatusModal = ({ onClose }: { onClose?: ModalProps['onClose'] }): JSX.Element => {
  const { t } = useTranslation();

  const body: ModalProps['body'] = bodyOnClose => (
    <div className="px-24 lg:w-[840px]">
      <ExplanationLine status={OrderStatus.TO_ORDER} desc={t('pages.orders.statusModal.toOrder')} t={t} />
      <ExplanationLine status={OrderStatus.ORDERED} desc={t('pages.orders.statusModal.ordered')} t={t} />
      <ExplanationLine status={OrderStatus.IN_PROGRESS} desc={t('pages.orders.statusModal.inProgress')} t={t} />
      <ExplanationLine status={OrderStatus.DONE} desc={t('pages.orders.statusModal.done')} t={t} />
      <div className="flex justify-end py-10">
        <Button variant="primary" label={t('common.close')} leftIcon="check" onClick={bodyOnClose} />
      </div>
    </div>
  );

  return <Modal body={body} title={t('pages.orders.statusModal.title')} onClose={onClose} />;
};
