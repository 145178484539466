import countries from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import frCountries from 'i18n-iso-countries/langs/fr.json';

countries.registerLocale(enCountries);
countries.registerLocale(frCountries);

/** Countries not available in the i18n country lib */
const unknownCountries = { AC: 'Ascension Island', TA: 'Tristan da Cunha' };

export type CountriesList = { [key in keyof typeof enCountries.countries]: string | string[] };

function reorder(c: CountriesList) {
  const { CH, FR, DE, IT, ...rest } = c;

  return { CH, FR, DE, IT, ...rest, ...unknownCountries };
}

export const locales = {
  [enCountries.locale]: reorder(enCountries.countries),
  [frCountries.locale]: reorder(frCountries.countries),
};

export default {
  ...locales,

  getName: (key: string | null | undefined, locale: string): string | undefined => {
    const localeCountries = locales[locale];

    if (!localeCountries) {
      return undefined;
    }

    const country = localeCountries[(key ?? '') as keyof CountriesList];

    if (!country) {
      return undefined;
    }

    if (Array.isArray(country)) {
      return country[0];
    }
    return country;
  },
};
