import { AbstractReference, booleanTransformer } from '@ch-apptitude-icc/common/shared/entities';
import { IsDate, IsEnum, IsInt, IsOptional, IsString } from '@ch-apptitude-icc/common/shared/validation';
import { Type } from 'class-transformer';
import { Column, PrimaryGeneratedColumn } from 'typeorm';
import { Doctor } from './doctor';
import { ReportStatus } from './enums';
import { type Patient as PatientType } from './patient';

export class Reference extends AbstractReference {
  @PrimaryGeneratedColumn({ type: 'int', name: 'pkReference' })
  @IsInt()
  id!: number;

  @Column('int', { name: 'NoDossier', primary: true })
  @IsInt()
  iccFileId!: number;

  @Column('char', { length: 1, name: 'StatusRpt', primary: true })
  @IsEnum(ReportStatus)
  reportStatus!: ReportStatus;

  @Column('int', {
    default: () => "'0'",
    name: 'Duplique',
    primary: true,
  })
  @IsInt()
  @IsOptional()
  copyId!: number;

  @Column('int', { name: 'fkMedecin', nullable: true })
  @IsInt()
  doctorId!: number | null;

  doctor?: Doctor;

  @Column('int', { name: 'fkPatient', nullable: true })
  @IsInt()
  patientId!: number | null;

  patient?: PatientType;

  @Column('int', { name: 'fkCommande', nullable: true })
  @IsInt()
  @IsOptional()
  orderId!: number | null;

  @Column('datetime', { name: 'DateRapport', nullable: true })
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  reportDate!: Date | null;

  @Column('datetime', { name: 'DatePrélèvement', nullable: true })
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  sampleDate!: Date | null;

  @Column('datetime', { name: 'DateRéception', nullable: true })
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  receptionDate!: Date | null;

  @Column('varchar', { length: 255, name: 'Fichier', nullable: true })
  @IsString()
  @IsOptional()
  file!: string | null;

  @Column('varchar', { length: 32, name: 'MD5', nullable: true })
  @IsString()
  @IsOptional()
  md5!: string | null;

  @Column('bit', { default: () => "'b'0''", name: 'Lu', transformer: booleanTransformer })
  @IsString()
  @IsOptional()
  read!: boolean;

  @Column('varchar', { length: 32, name: 'materialId', nullable: true })
  @IsString()
  @IsOptional()
  materialId?: string | null;
}
