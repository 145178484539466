import { clients } from '@ch-apptitude-icc/lablink/shared/client';
import { Analysis } from '@ch-apptitude-icc/lablink/shared/entities';
import { ApiFactory } from '@common/frontend/services/api-client';
import { ApiCallHelper } from '@services/api/ApiCallHelper';
import { QueryClient } from '@tanstack/react-query';

const api = clients.analysis(Analysis);

export class AnalysisApi extends ApiFactory<Analysis, typeof api>() {
  constructor(queryClient: QueryClient, helper: ApiCallHelper) {
    super(queryClient, helper, api);
  }
}

/**
 * The `name` is present but should not be used, as the `publicName` is the one we want to display to the end user.
 *
 * To avoid any confusion or mistake, we hide it from the type.
 */
export type AnalysisFront = Omit<Analysis, 'name'>;
