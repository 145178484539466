import { PickType } from '@ch-apptitude-icc/common/shared/type-utils';
import { CreateOrderTemplateDto } from '@ch-apptitude-icc/lablink/shared/entities';
import { FormikEntityWrapper } from '@common/frontend/components/ui-forms/FormikEntityWrapper';
import { setAnalysisSavedToFavorite } from '@features/orders/store/OrderState/slice';
import { FormikElementWrapper, Button, Input } from '@features/ui/components';
import { Modal, ModalProps } from '@features/ui/modal';
import { useApi } from '@services/api';
import { useAppDispatch } from '@services/store/hooks';
import { Form } from 'formik';
import { useTranslation } from 'next-i18next';

export class OrderAnalysesCreateTemplateForm extends PickType(CreateOrderTemplateDto, ['templateName']) {}

export type EditFavoritesModalProps = {
  onClose?: ModalProps['onClose'];
  selectedAnalyses?: { analysisId: number }[];
  updateId?: number;
};

export const EditFavoritesModal = ({ onClose, selectedAnalyses, updateId }: EditFavoritesModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { orderTemplateApi } = useApi();
  const createOne = orderTemplateApi.useAddOne({
    onSuccess: () => {
      dispatch(setAnalysisSavedToFavorite(true));
    },
  });
  const deleteOne = orderTemplateApi.useDeleteOne();
  const updateOne = orderTemplateApi.useUpdateOne();

  const onCloseWrapper = () => (onClose ? onClose() : null);

  const body: ModalProps['body'] = () => (
    <FormikEntityWrapper<OrderAnalysesCreateTemplateForm>
      type={OrderAnalysesCreateTemplateForm}
      initialValues={{ templateName: '' }}
      onSubmit={values => {
        if (updateId) {
          updateOne.mutate({ id: updateId, templateName: values.templateName });
        } else if (selectedAnalyses) {
          createOne.mutate({
            templateName: values.templateName,
            analysis: selectedAnalyses.map(ana => ({ id: ana.analysisId })),
          });
        }
        onCloseWrapper();
      }}
    >
      <Form>
        <div className="flex min-w-[350px] flex-col gap-y-5 p-5">
          <FormikElementWrapper<OrderAnalysesCreateTemplateForm>
            label={t('pages.order.analysis.templateName')}
            name="templateName"
          >
            <Input type="text" />
          </FormikElementWrapper>

          <div className="flex flex-row items-center justify-end gap-x-4">
            {updateId != null && (
              <div className="flex-grow">
                <Button
                  label={t('common.delete')}
                  variant="primary-transparent-outline"
                  leftIcon="trash"
                  onClick={() => {
                    deleteOne.mutate(updateId);
                    onCloseWrapper();
                  }}
                  disabled={createOne.isLoading}
                />
              </div>
            )}
            <Button
              label={t('common.cancel')}
              variant="secondary"
              leftIcon="arrowTurnDownLeft"
              onClick={onCloseWrapper}
              disabled={createOne.isLoading}
            />
            <Button
              label={t('common.validate')}
              leftIcon="check"
              loading={createOne.isLoading}
              type="submit"
              disabled={createOne.isLoading}
            />
          </div>
        </div>
      </Form>
    </FormikEntityWrapper>
  );

  return <Modal body={body} onClose={onClose} noCloseButton />;
};
