import { IsObject, ValidateNested } from '@ch-apptitude-icc/common/shared/validation';
import { errors, OrderCreateDto } from '@ch-apptitude-icc/lablink/shared/entities';
import { PatientFieldsFront } from '@features/orders/types/OrderFront';
import { PatientFront } from '@services/api';
import { Type } from 'class-transformer';

export class CreateOrderFront extends OrderCreateDto {
  @Type(() => CreateOrderPatient)
  @ValidateNested({ errorName: errors.orders.validation.requirePatient })
  @IsObject()
  override patient?: CreateOrderPatientType;
}

type CreateOrderPatientType = CreateOrderPatient;

export class CreateOrderPatient extends PatientFieldsFront {
  public static fromPatient(patient: PatientFront): CreateOrderPatient {
    const { id, ...rest } = patient;

    return { ...rest, existingPatientId: id };
  }

  constructor(anonymous?: boolean) {
    super();

    // Define the mandatory fields so that their errors are displayed
    this.firstname = null;
    this.lastname = null;
    // @ts-expect-error gender is officially not nullable
    this.gender = null;
    this.birthDate = null;
    this.socialSecurityNumber = null;
    this.insurerId = null;
    this.emailAddress = null;
    this.address = { address1: null, city: null, countryCode: 'CH', postcode: null };
    this.anonymous = anonymous;
  }
}
