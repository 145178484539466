import { ErrorMessage as FormikErrorMessage } from 'formik';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

type ErrorMessageProps = {
  name: string;
};

const untranslatedErrorFormat = /^[a-zA-Z0-9_.]*:[a-zA-Z0-9_.];?/;

export const ErrorMessage = ({ name }: ErrorMessageProps): JSX.Element => {
  const { t } = useTranslation('common');
  const translate = useCallback(
    (s: string) => {
      // Does it look like a translation key?
      if (untranslatedErrorFormat.test(s)) {
        const [, message] = s.split(':');
        const [key, ...extras] = message.split(';');

        return t(
          key.startsWith('errors') ? key : `errors.validation.${key}`,
          Object.fromEntries(extras.map((value, index) => [`p${index}`, value])),
        );
      }
      return s;
    },
    [t],
  );

  return (
    <FormikErrorMessage
      name={name}
      render={errorMessage => <p className="mt-2 text-sm text-red-400">{translate(errorMessage)}</p>}
    />
  );
};
