import {
  AccessorFn,
  createColumnHelper,
  DeepKeys,
  DeepValue,
  DisplayColumnDef,
  IdentifiedColumnDef,
} from '@tanstack/react-table';
import { TableColumn } from './Table';

/**
 * A helper function to create a column definition for a table with maximum type safety and possibility to extend the column definition.
 * This makes createColumnHelper from React Table obsolete.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function tableColumnHelper<T>() {
  const columnHelper = createColumnHelper<T>();

  let localId = 0;

  return {
    // The type is derived from the type of the accessor function directly, DO NOT adapt it to something else
    accessor: <
      TAccessor extends AccessorFn<T> | DeepKeys<T>,
      TValue extends TAccessor extends AccessorFn<T, infer TReturn>
        ? TReturn
        : TAccessor extends DeepKeys<T>
        ? DeepValue<T, TAccessor>
        : never,
    >(
      // Major benefit of this wrapper is to force the usage of ids in column to allow adding classNames to the cells
      // Not having ids would break the map lookup of classNames
      config: {
        accessor: TAccessor;
        column: TAccessor extends AccessorFn<T>
          ? Omit<DisplayColumnDef<T, TValue>, 'id'> & Partial<Pick<DisplayColumnDef<T, TValue>, 'id'>>
          : IdentifiedColumnDef<T, TValue>;
        id?: string;
        withClassName?: string;
      },
    ): TableColumn<T>[number] => ({
      ...columnHelper.accessor(config.accessor, { ...config.column, id: config.id ?? `column-${localId++}` }),
      withClassName: config.withClassName,
    }),
    display: (config: { column: DisplayColumnDef<T, unknown>; withClassName?: string }) => ({
      ...columnHelper.display(config.column),
      id: config.column.id ?? `column-${localId++}`,
      withClassName: config.withClassName,
    }),
    group: columnHelper.group,
    /**
     * For some reasons, the type of the header is not inferred correctly when translated, so we have to force it
     */
    trAsString: (str: string) => str,
  };
}
