export * from './class-transformer.error';

export const errors = {
  generic: {
    validation: {
      /**
       * The entity referenced by the foreign key/id was not found.
       *
       * Example:
       *  - The material field contains a material that does not exist
       *  - The analysisId field in an ordered analysis contains an ID that does not exist in DB or that is not visible
       *      to the user
       */
      notFound: 'errors.generic.validation.NotFound',

      /**
       * The field is invalid, for an unknown reason
       */
      invalid: 'errors.generic.validation.Invalid',
    },
    database: {
      duplicatedKey: 'errors.generic.database.DuplicatedKey',
      constraintViolation: 'errors.generic.database.ConstraintViolation',
      unknownError: 'errors.generic.database.UnknownDatabaseError',
      unknownField: 'errors.generic.database.UnknownDatabaseColumn',
    },

    /**
     * Returned when the current user does not have enough permissions to modify the incriminated field
     */
    insufficientPermission: 'errors.generic.InsufficientPermission',
  },
} as const;
