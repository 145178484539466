import { Exclude } from 'class-transformer';
import { OrderSamplingStatus, OrderStatus, OrderBilledTo } from '../enums';
import { HasAddress } from '../has-address.interface';
import { HasIdentityData } from '../has-identity-data.interface';
import { HasName } from '../has-name.interface';
import { MandatorInterface } from '../mandator.interface';
import { PatientInterface } from '../patient.interface';

export abstract class AbstractLablinkOrder {
  id!: number;

  doctorId!: number;

  doctor?: MandatorInterface;

  billedTo?: OrderBilledTo | null;

  samplingStatus?: OrderSamplingStatus | null;

  invoicing?: InvoicingInterface | null;

  thirdPartyPayer?: boolean;

  status!: OrderStatus;

  urgent?: boolean;

  hasBacteriologicalAnalysis?: boolean;

  hasHematologicalAnalysis?: boolean;

  isAddition?: boolean;

  summonClient?: boolean;

  /**
   * The infamous VRef... Can contain anything, and is NOT unique.
   */
  reference?: string | null;

  /**
   * Appears unused since August 2020.
   */
  limitDate?: Date | null;

  /**
   * Signals that the invoice should be sent directly to the patient.
   *
   * In this case, insurance data will not be required.
   */
  invoicePatient?: boolean;

  /**
   * Value only set by the green program when a STD is found
   */
  readonly foundStd?: boolean | null;

  remark?: string | null;

  /**
   * Comments set by the laboratory on the order.
   *
   * This field should not be modified by the website, only by the lab.
   */
  readonly iccComments?: string | null;

  otherMaterial?: string | null;

  otherAnalysis?: string | null;

  requestDate?: string | null;

  sampleDate?: string | null;

  patient?: PatientFieldsInterface;

  /**
   * Data of the doctor to whom a copy of the report should be sent
   */
  secondDoctorData?: HasIdentityData;

  /**
   * Specifies that a copy of the results should be sent to the patient
   */
  copyToPatient!: boolean;

  requestedAnalysis!: OrderedAnalysisInterface[];

  lavigny?: LavignyFieldsInterface;

  profa?: ProfaFieldsInterface;

  orderingDepartment?: string | null;

  /**
   * Computed column
   */
  @Exclude()
  searchColumn?: string;
}

export interface OrderedAnalysisInterface {
  analysisId: number;
  pricingCategoryId?: number;
  samplingMaterialId?: number | null;
  displayOrderForFiles?: string | null;

  samplingMaterial?: {
    analysisMaterialId?: string;
  } | null;
}

export interface PatientFieldsInterface extends PatientInterface {
  existingPatientId?: number | null;

  /**
   * External patient reference, new field, not used by ICC yet
   */
  externalReference?: string | null;
}

export interface LavignyFieldsInterface {
  isLavigny: boolean;
  lavignyPatientId: string | null;
}

export interface ProfaFieldsInterface {
  profaPatientId?: number | null;
  clinicalData?: string | null;
}

export type InvoicingInterface = HasName & HasAddress;
