import { OrderCreateDto } from '@ch-apptitude-icc/lablink/shared/entities';
import { convertErrorsToFormik, FormikEntityWrapper } from '@common/frontend/components/ui-forms/FormikEntityWrapper';
import { MAX_INSURANCE_LOAD_PARAMS } from '@features/insurers/components/input';
import { OrderCard } from '@features/orders/components';
import { OrderBarCode } from '@features/orders/components/detail/OrderBarCode';
import { selectFromStep, selectHasBeenOrdered, selectOrderId } from '@features/orders/store/OrderState/selectors';
import {
  setInsurersList,
  setOrderEditingStep,
  setOrderStepData,
  setOrderStepStatus,
} from '@features/orders/store/OrderState/slice';
import { CreateOrderPatient } from '@features/orders/types/CreateOrderFront';
import { CreateOrderStepPatient } from '@features/orders/types/CreateOrderStepPatient';
import { Button, Icon, LineCard } from '@features/ui/components';
import { ErrorMessage } from '@features/ui/components/form/ErrorMessage';
import { Spinner } from '@features/ui/components/Spinner';
import { useApi } from '@services/api';
import { useAppDispatch, useAppSelector } from '@services/store/hooks';
import { Form, useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';
import { PatientForm, PatientLineCard, PatientSearchBar } from './patient';

export const OrderPatient = (): JSX.Element => {
  const { t } = useTranslation('common');

  const orderId = useAppSelector(selectOrderId);
  const hasBeenOrdered = useAppSelector(selectHasBeenOrdered);
  const isEditing = useAppSelector(selectFromStep.patient.selectIsEditing);

  const dispatch = useAppDispatch();

  const { insurerApi, authApi } = useApi();
  const me = authApi.useGetMe();
  const insurersList = insurerApi.useGetList(MAX_INSURANCE_LOAD_PARAMS);
  useEffect(() => {
    if (insurersList.data) {
      dispatch(setInsurersList({ insurersList: insurersList.data.results }));
    }
  }, [dispatch, insurersList.data]);

  const barcodeDisplay = hasBeenOrdered && !!orderId && (
    <div className="absolute top-0 right-1 hidden md:block">
      <OrderBarCode orderId={+orderId} />
    </div>
  );

  const patientData = useAppSelector(selectFromStep.patient.selectData);
  const serverErrors = useAppSelector(selectFromStep.patient.selectErrors);

  const serverErrorsAsFormik = convertErrorsToFormik(serverErrors, t);

  const initialValues: CreateOrderStepPatient =
    Object.values(patientData.patient ?? {}).length !== 0 ? patientData : new CreateOrderStepPatient();

  return (
    <FormikEntityWrapper<CreateOrderStepPatient>
      type={CreateOrderStepPatient}
      initialValues={initialValues}
      initialErrors={serverErrorsAsFormik}
      initialTouched={serverErrorsAsFormik}
      validate={e => OrderCreateDto.validatePatientStep(e, me.data?.doctor?.allowAnonymousPatients ?? false)}
      onSubmit={e => {
        dispatch(setOrderStepData(e));
        dispatch(setOrderEditingStep('analysis'));
        dispatch(setOrderStepStatus({ key: 'patient', status: 'done' }));
      }}
      enableReinitialize
    >
      <Form id="patient">
        <OrderCard
          icon="user"
          stepKey="patient"
          title={
            <div className="relative">
              {barcodeDisplay}
              {t('common.patient')}
            </div>
          }
        >
          {isEditing ? (
            <OrderPatientContent />
          ) : (
            patientData && (
              <div className="flex flex-col gap-y-4 text-sm text-gray-900">
                <PatientLineCard patient={patientData.patient} className="lg:w-1/2" />
                {patientData.reference && (
                  <span>
                    {t('entities.patient.orderReference')}: {patientData.reference}
                  </span>
                )}
                {patientData.orderingDepartment && (
                  <span>
                    {t('entities.patient.department')}: {patientData.orderingDepartment}
                  </span>
                )}
              </div>
            )
          )}
        </OrderCard>
      </Form>
    </FormikEntityWrapper>
  );
};

function OrderPatientContent(): JSX.Element {
  const { t } = useTranslation();
  const { patientApi } = useApi();
  const formik = useFormikContext<CreateOrderStepPatient>();
  const { submitCount, touched, setFieldTouched } = formik;
  const router = useRouter();

  const patientData: CreateOrderStepPatient = formik.values;

  const resetPatient = useCallback(() => formik.setValues({ ...formik.values, patient: undefined }), [formik]);

  const setPatient = useCallback(
    (patient: CreateOrderPatient) => formik.setValues({ ...formik.values, patient }),
    [formik],
  );

  const urlPatientId = 'patientId' in router.query && router.query.patientId ? +router.query.patientId : undefined;

  const loadPatientQuery = patientApi.useGetOne(urlPatientId ?? 0, {
    enabled: !!urlPatientId,
    onSuccess: data => {
      if (urlPatientId && data && !patientData.patient) {
        void setPatient(CreateOrderPatient.fromPatient(data));
      }
    },
    disableRefetching: true,
  });

  useEffect(() => {
    // This effect marks the `patient.patient` "virtual" field as touched, so that Formik displays its errors.
    const patient = touched.patient as unknown as { patient?: boolean } | undefined;
    if (submitCount > 0 && !patient?.patient) {
      void setFieldTouched('patient.patient', true);
    }
  }, [submitCount, touched.patient, setFieldTouched]);

  if (!!urlPatientId && loadPatientQuery.isLoading) {
    return <Spinner />;
  }

  return !patientData.patient ? (
    <>
      <div className="grid grid-cols-1 py-2 sm:grid-cols-2">
        <PatientSearchBar
          onChange={patient => {
            if (patient) {
              void setPatient(CreateOrderPatient.fromPatient(patient));
            }
          }}
          autoFocus
        />

        <Button
          label={t('pages.order.patient.makePatient')}
          leftIcon="userPlus"
          className="ml-4 max-w-min"
          variant="primary-transparent"
          onClick={() => void setPatient(new CreateOrderPatient())}
        />
      </div>

      <ErrorMessage name="patient.patient" />
    </>
  ) : (
    <>
      <div className="mb-7 lg:w-1/2">
        {patientData ? (
          <PatientLineCard
            patient={patientData.patient}
            onRemove={urlPatientId ? undefined : () => void resetPatient()}
          />
        ) : (
          <LineCard
            left={<Icon icon="userPlus" />}
            main={t('pages.order.patient.makePatient')}
            onRemove={() => void resetPatient()}
          />
        )}
      </div>

      <PatientForm allowAnonymous={!patientData.patient.existingPatientId} />
    </>
  );
}
